import { IMsalContext, useMsal } from '@azure/msal-react';
import React, { useState, useEffect, FormEvent } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { ApiService } from '../../Services/ApiService'
import {
    Dialog,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
    CommandBarButton,
    Link,
    TooltipHost,
    Label,
    DetailsList,
    SelectionMode,
    IColumn,
    IconButton,
    SearchBox,
    TextField,
    useTheme,
    Stack,
    Text,
    Spinner,
    SpinnerSize,
    Dropdown,
    IDropdownOption
} from '@fluentui/react'
import './admin.scss';
import { IGlobalDemand } from '../../models/IGlobalDemand';
import { StringService } from '../../Services/StringService'
import { IDemandExtra } from '../../models/ILocalDemand';
import { DemandAction } from '../../models/Enums';
import { IExtra } from '../../models/IExtra';
import { ExtrasModal } from '../../components/shared/ExtrasModal';
import { ISettings } from '../../models/ISettings';
import { getBusinessUnit } from '../../Services/Global';

export const Admin: React.FC<{}> = () => {
    const ctx: IMsalContext = useMsal()
    const apiService = new ApiService(ctx)
    const theme = useTheme();
    const [isProcessing, setIsProcessing] = useState(false);
    const [result, setResult] = useState('');
    const [demands, setDemands] = useState<IGlobalDemand[]>([]);
    const [filteredDemands, setFilteredDemands] = useState<IGlobalDemand[]>([]);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [selectedDemand, setSelectedDemand] = useState<IGlobalDemand>();
    const [defaultClarification, setDefaultClarification] = useState<string>();
    const [defaultInternalComment, setDefaultInternalComment] = useState<string>();
    const [demandText, setDemandText] = useState<string>();
    const [defaultAction, setDefaultAction] = useState<DemandAction>();
    const [defaultExtras, setDefaultExtras] = useState<string[]>();
    const [showExtrasModal, setShowExtrasModal] = useState(false);
    const [allExtras, setAllExtras] = useState<IExtra[]>();
    const [settings, setSettings] = useState<ISettings>();

    const [isLoading, setIsLoading] = useState(true);
    const [isSavingThresholds, setIsSavingThresholds] = useState(false);

    const [dthresh, setDthresh] = useState<string>();
    const [rthresh, setRthresh] = useState<string>();

    const actionOptions: IDropdownOption[] = [
        { key: 'Accept', text: 'Accept' },
        { key: 'Information', text: 'Information' },
        { key: 'Clarification', text: 'Clarification' },
        { key: 'Deviate', text: 'Deviation' },
        { key: 'NotApplicable', text: 'N/A' }]

    useEffect(() => {
        async function getData() {
            setIsLoading(true);
            const response = await apiService.get('extras')
            const extraResult: IExtra[] = await response.json();
            setAllExtras(extraResult);
            const globalDemandResponse = await apiService.get(`globaldemands`);
            const demands: IGlobalDemand[] = await globalDemandResponse.json();
            setDemands(demands);
            setFilteredDemands(demands)

            if (getBusinessUnit() === 'WHE') {
                const settingsResponse = await apiService.get('settings');
                if (settingsResponse.status === 200) {
                    const sets = await settingsResponse.json();
                    setSettings(sets);
                }
            }

            setIsLoading(false);
        }
        getData();
    }, [])

    const getResult = async (response: Response) => {
        const ret = await response.json()
        return `No of extras: ${ret.total}. Added: ${ret.added}, Updated: ${ret.updated}, Removed: ${ret.removed}`
    }
    const handleFileChange = async (e: React.FormEvent<HTMLInputElement>) => {
        try {
            setResult('')
            setIsProcessing(true);
            const files: FileList = e.currentTarget.files!
            let f = files[0]
            let formData: FormData = new FormData()
            formData.append("file", f, f.name)
            const response = await apiService.post(`extras/priceList?analyzeOnly=true`, formData)
            if (window.confirm(`The uploaded file will result in the following actions, apply changes?\n\n${await getResult(response)}`)) {
                const response = await apiService.post(`extras/priceList?analyzeOnly=false`, formData)
                setResult(await getResult(response))
            }
        } finally {
            setIsProcessing(false);
        }
    }

    const handleEditDemand = (item: IGlobalDemand) => {
        setSelectedDemand(item);
        setDefaultClarification(item.defaultClarification);
        setDefaultInternalComment(item.defaultInternalComment);
        setDefaultAction(item.defaultAction);
        setDefaultExtras(item.extraIds);
        setDemandText(item.text);
        toggleHideDialog()
    }

    const saveDemand = async () => {
        const demand = selectedDemand;
        if (demand) {
            demand!.text = demandText ?? selectedDemand.text;
            demand!.defaultClarification = defaultClarification;
            demand!.defaultInternalComment = defaultInternalComment;
            demand!.defaultAction = defaultAction;
            demand!.extraIds = defaultExtras ?? [];
            await apiService.put(`globalDemands/${demand.id}`, demand);
        }
        setSelectedDemand(undefined)
        setDefaultClarification(undefined)
        setDefaultInternalComment(undefined)
        setDefaultAction(undefined)
        setDefaultExtras(undefined)
        setDemandText(undefined)
        toggleHideDialog()
    }

    const downloadTemplate = async () => {
        await apiService.downloadFile(`extras/priceListTemplate`)
    }

    const exportDemands = async () => {
        await apiService.downloadFile(`globalDemands/export`)
    }

    const handleSettingUpdate = (property: string, value: any) => {
        if (property === "demandsThreshold") {
            setDthresh(value);
        }
        else {
            setRthresh(value);
        }
        setSettings({ ...settings, [property]: value });
    }

    const columns: IColumn[] = [
        {
            key: '1',
            name: 'Demand',
            fieldName: 'text',
            minWidth: 300,
            maxWidth: 300,
            isRowHeader: true,
            isPadded: true
        },
        {
            key: '2',
            name: 'Default Action',
            fieldName: 'defaultAction',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isPadded: true,
            onRender: (item: IGlobalDemand) => {
                return StringService.getDocumentCategory(item.defaultAction)
            }
        },
        {
            key: '3',
            name: 'Default Clarification',
            minWidth: 300,
            maxWidth: 300,
            isRowHeader: true,
            isPadded: true,
            onRender: (item: IGlobalDemand) => {
                return (
                    <TooltipHost content={item.defaultClarification}>
                        <span>{item.defaultClarification}</span>
                    </TooltipHost>
                )
            }
        },
        {
            key: '4',
            name: 'Default Internal Comment',
            minWidth: 300,
            maxWidth: 300,
            isRowHeader: true,
            isPadded: true,
            onRender: (item: IGlobalDemand) => {
                return (
                    <TooltipHost content={item.defaultInternalComment}>
                        <span>{item.defaultInternalComment}</span>
                    </TooltipHost>
                )
            }
        },
        {
            key: '5',
            name: 'Default Extras',
            minWidth: 75,
            maxWidth: 75,
            isRowHeader: true,
            isPadded: true,
            onRender: (item: IGlobalDemand) => {
                return item.extraIds.length
            }
        },
        {
            key: '6',
            name: '',
            isRowHeader: false,
            minWidth: 20,
            maxWidth: 20,
            isPadded: true,
            onRender: (item: IGlobalDemand) => {
                return <IconButton className='list-button' iconProps={{ iconName: 'Edit' }} onClick={(e) => handleEditDemand(item)} onFocus={(ev) => ev.stopPropagation()} />
            }
        }
    ]

    const handleSearch = (text: string | undefined) => {
        console.log(text)
        if (text !== undefined && text !== '') {
            setFilteredDemands(demands.filter(p => p.text.toLowerCase().includes(text.toLowerCase())))
        } else {
            setFilteredDemands(demands)
        }
    }

    const onClarificationChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setDefaultClarification(newValue)
        },
        [],
    );

    const onInternalCommentChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setDefaultInternalComment(newValue)
        },
        [],
    );

    const onDemandTextChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setDemandText(newValue)
        },
        [],
    );

    const onActionChange = (event: any, item: any) => {
        setDefaultAction(item.key)
    }

    const stackStyle = {
        root: {
            background: theme.palette.white, boxShadow: theme.effects.elevation8, height: 'auto', width: 'auto', padding: 50, margin: '20px', borderRadius: 5
        }
    }

    function removeExtra(id: string) {
        const exists = defaultExtras?.find(x => x === id);
        if (exists) {
            setDefaultExtras(defaultExtras?.filter(x => x != id));
        }
    }

    const handleDismissExtras = (incomingExtra: IDemandExtra) => {
        const exists = defaultExtras?.find(x => x === incomingExtra.id);
        if (!exists) {
            setDefaultExtras([...defaultExtras!, ...[incomingExtra.id]]);
        }
    }

    const renderExtras = () => {
        return (
            defaultExtras?.map((x, key) => {
                const targetExtra = allExtras?.find(y => y.id === x);
                return (
                    <Stack horizontal>
                        <Stack.Item align='end'>
                            <IconButton className='list-button' iconProps={{ iconName: 'Delete' }} onClick={() => removeExtra(x)} onFocus={(ev) => ev.stopPropagation()} />
                        </Stack.Item>
                        <Stack.Item style={{ paddingLeft: 5 }} align='center'>
                            <b>{targetExtra?.zBlock}</b> - {targetExtra?.description}
                        </Stack.Item>
                    </Stack>
                )
            })
        )
    }

    const handleSaveSettings = async () => {
        setIsSavingThresholds(true);
        console.log(settings);
        await apiService.put(`settings/${settings?.id}`, settings);
        setIsSavingThresholds(false);
    }

    return (
        <div style={{ marginTop: 10 }}>
            {
                isLoading ?
                    <Spinner size={SpinnerSize.large} label='Loading...' />
                    :
                    <>
                        <Stack>

                            <Stack.Item>
                                <h2 style={{ margin: '10px 40px' }}>Prices</h2>
                            </Stack.Item>
                            <Stack styles={stackStyle}>
                                <h3 style={{ marginLeft: 10 }}>Update prices</h3>
                                <Stack.Item>
                                    <div>
                                        <div style={{ marginLeft: 10, marginTop: 10 }}>
                                            Ensure the full list of extras is present in the uploaded file. <b>Any extras not present in the file will be removed in Albot!</b>
                                        </div>
                                        <Label
                                            htmlFor="upload-price-list"
                                            className="upload-price-list-label"
                                            style={{ backgroundColor: theme.palette.themePrimary, borderRadius: 5 }}
                                        >
                                            Upload Price List
                                        </Label>
                                        {isProcessing ? <span>Uploading...</span> : <div style={{ display: 'inline-block' }}><input
                                            name="files"
                                            id="upload-price-list"
                                            hidden
                                            type={'file'}
                                            onChange={handleFileChange}
                                            accept=".xlsx"
                                        /><span>{result}</span></div>}
                                    </div>
                                </Stack.Item>
                                <Stack.Item>
                                    <Link onClick={() => downloadTemplate()} style={{ marginLeft: 10 }}>
                                        Download template
                                    </Link>
                                </Stack.Item>
                            </Stack>
                            <Stack.Item>
                                <h2 style={{ margin: '10px 40px' }}>Demands</h2>
                            </Stack.Item>
                            <Stack styles={stackStyle}>
                                <Stack.Item>
                                    <div style={{ marginLeft: 10, marginBottom: 10, width: 300 }}>
                                        <PrimaryButton onClick={exportDemands} text="Export Demands" />
                                    </div>
                                </Stack.Item>
                                <h3 style={{ marginLeft: 10 }}>Edit demand defaults</h3>
                                <Stack.Item>
                                    <div style={{ marginLeft: 10, marginTop: 10, width: 300 }}>
                                        <SearchBox placeholder='Search...' onChange={(e, i) => { handleSearch(i) }} />
                                    </div>
                                </Stack.Item>
                                <Stack.Item align='stretch'>
                                    <div style={{ width: 'auto' }}>
                                        <DetailsList
                                            columns={columns}
                                            items={filteredDemands}
                                            compact
                                            selectionMode={SelectionMode.none}
                                            onShouldVirtualize={() => false}
                                            styles={{ root: { maxHeight: 500, overflowX: 'hidden' } }}
                                        />
                                    </div>
                                </Stack.Item>

                            </Stack>
                        </Stack>
                        <Dialog
                            hidden={hideDialog}
                            onDismiss={toggleHideDialog}
                            minWidth={600}
                        >
                            <h2><TextField label="Demand Name" defaultValue={selectedDemand?.text} onChange={onDemandTextChange} /></h2>
                            <TextField label="Default Clarification" multiline rows={5} defaultValue={selectedDemand?.defaultClarification} onChange={onClarificationChange} />
                            <TextField label="Default Internal Comment" multiline rows={5} defaultValue={selectedDemand?.defaultInternalComment} onChange={onInternalCommentChange} />
                            <Dropdown label="Default Action" onChange={onActionChange}  options={actionOptions} selectedKey={defaultAction} />
                            <Label>Default Extras</Label>
                            <Stack>
                                {
                                    <>{renderExtras()}</>
                                }
                            </Stack>
                            {
                                showExtrasModal === true ?
                                    <ExtrasModal
                                        dismissModal={() => setShowExtrasModal(false)}
                                        isEditing={false}
                                        show={showExtrasModal}
                                        handleDismissExtras={handleDismissExtras}
                                        existingExtras={[]}
                                        allExtras={allExtras || []}
                                        currentPheType={null}
                                        currentPosition={null}
                                    />
                                    :
                                    <></>
                            }
                            <CommandBarButton style={{ height: 30 }} iconProps={{ iconName: 'Add' }} text='Add extra' onClick={() => setShowExtrasModal(true)} />
                            <DialogFooter>
                                <PrimaryButton onClick={saveDemand} text="Save" />
                                <DefaultButton onClick={toggleHideDialog} text="Cancel" />
                            </DialogFooter>
                        </Dialog>
                        {
                            getBusinessUnit() === 'WHE' &&
                            <>
                                <h2 style={{ margin: '10px 40px' }}>Model thresholds</h2>
                                <Stack>

                                    <Stack styles={stackStyle}>
                                        {
                                            settings && settings.relevanceThreshold && settings.demandThreshold ?
                                                <>
                                                    <Stack.Item style={{ margin: 5, width: 500 }}>
                                                        <h3>Sentence relevance threshold</h3>

                                                        <TextField
                                                            type='number'
                                                            onChange={(e) => handleSettingUpdate('relevanceThreshold', e.currentTarget.value)}
                                                            value={settings.relevanceThreshold.toString()}
                                                            step={0.01}
                                                            max={1}
                                                            min={0}
                                                        />

                                                    </Stack.Item>
                                                    <Stack.Item style={{ margin: 5, width: 500 }}>
                                                        <h3>Demand threshold</h3>
                                                        {/* <input type="number" onChange={(e) => handleSettingUpdate("demandThreshold", e.currentTarget.value)} /> */}
                                                        <TextField
                                                            type='number'
                                                            onChange={(e) => handleSettingUpdate('demandThreshold', e.currentTarget.value)}
                                                            value={settings.demandThreshold.toString()}
                                                            step={0.01}
                                                            max={1}
                                                            min={0}
                                                        />

                                                    </Stack.Item>
                                                    <Stack.Item style={{ margin: 5 }}>
                                                        <PrimaryButton disabled={isSavingThresholds} iconProps={{ iconName: "Save" }} onClick={() => handleSaveSettings()} text='Save thresholds' />
                                                    </Stack.Item>
                                                </>
                                                :
                                                <Text>No settings for thresholds found.</Text>
                                        }

                                    </Stack>
                                </Stack>
                            </>
                        }


                    </>
            }

        </div >
    )
}