export const AuthConfig = {
  auth: {
    clientId:
      process.env.NODE_ENV === 'production'
        ? `${process.env.REACT_APP_CLIENT_ID}`
        : '5eb9456b-ddcd-4fa7-b78b-3b2a7d215e5b',
    authority:
      'https://login.microsoftonline.com/ed5d5f47-52dd-48af-90ca-f7bd83624eb9', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [
    process.env.NODE_ENV === 'production'
      ? `${process.env.REACT_APP_API_SCOPE}`
      : 'api://4b8b57e5-9ea4-4f1c-beec-7e9e49f337d6/API.Access',
  ],
}
