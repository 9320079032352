import React, { useEffect, useState } from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks'
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  ContextualMenu,
  Modal,
  IDragOptions,
  IIconProps,
  Label,
  TextField,
  PrimaryButton,
  Spinner,
  MessageBar,
  MessageBarType,
  FontIcon,
  useTheme,
} from '@fluentui/react'
import {
  IconButton,
  IButtonStyles,
  DefaultButton,
} from '@fluentui/react/lib/Button'
import { IProject } from '../../models/IProject'
import { IMsalContext, useMsal } from '@azure/msal-react'
import { ApiService } from '../../Services/ApiService'
import { NewPositions } from './NewPositions'
import { IPosition } from '../../models/IPosition'
import { IPheType } from '../../models/IPheType'
import { getTrainingMode } from '../../Services/Global';

export const ProjectModal: React.FC<{
  show: boolean
  dismissModal: any,
  isEditing: boolean,
  project?: IProject
}> = ({ show, dismissModal, isEditing, project }) => {
  const ctx: IMsalContext = useMsal()
  const apiService = new ApiService(ctx)
  const [showPositions, setShowPositions] = useState(false)
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false)
  const [keepInBounds, { toggle: toggleKeepInBounds }] = useBoolean(false)
  const [positions, setPositions] = useState<IPosition[]>()
  const [pheTypes, setPheTypes] = useState<IPheType[]>([]);

  const [isLoading, setIsLoading] = useState({ isActive: false, loadingMessage: '' });
  const [projectCode, setProjectCode] = useState({ value: '', message: '' });
  const [projectName, setProjectName] = useState({ value: '', message: '' });
  const [projectDescription, setProjectDescription] = useState({ value: '', message: '' });
  const [customerName, setCustomerName] = useState({ value: '', message: '' });
  const [formErrorMessage, setFormErrorMessage] = useState({ isActive: false, message: '' });
  const [apiError, setApiError] = useState({ isActive: false, value: '' });
  const [files, setFiles] = useState<File[]>([])
  const [positionsToDelete, setPositionsToDelete] = useState<IPosition[]>([]);

  const theme = useTheme();

  useEffect(() => {
    async function getPheTypes() {
      // Get values for dropdown
      const response = await apiService.get('phetypes')
      const result = await response.json();
      setPheTypes(result);
    }

    async function loadProjectProperties() {
      if (project !== undefined) {
        if (project.code !== undefined) {
          setProjectCode({ value: project.code, message: '' });
        }
        if (project.name !== undefined) {
          setProjectName({ value: project.name, message: '' });
        }
        if (project.description !== undefined) {
          setProjectDescription({ value: project.description, message: '' });
        }
        if (project.customer !== undefined) {
          setCustomerName({ value: project.customer, message: '' });
        }
      }

    }

    async function getPositionsForProject() {
      if (project !== undefined) {
        const response = await apiService.get(`projects/${project.id}/positions`);
        let existingPositions: IPosition[] = await response.json();
        if (existingPositions !== undefined) {
          existingPositions = existingPositions.map((x) => {
            x.isReadOnly = true;
            return x;
          }).filter(x => x.type === 'Position');
        }
        setPositions(existingPositions);
      }
    }

    // reset any potential documents added
    loadProjectProperties();
    getPheTypes();
    getPositionsForProject();
  }, []);

  // Normally the drag options would be in a constant, but here the toggle can modify keepInBounds
  const dragOptions = React.useMemo(
    (): IDragOptions => ({
      moveMenuItemText: 'Move',
      closeMenuItemText: 'Close',
      menu: ContextualMenu,
      keepInBounds,
    }),
    [keepInBounds],
  )

  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)
  const titleId = useId('title')

  const handleDismiss = (e: boolean) => {
    if (!isLoading.isActive) {
      setApiError({ isActive: false, value: '' })
      setFormErrorMessage({ isActive: false, message: '' })
      setShowPositions(false)
      setProjectDescription({ value: '', message: '' });
      setProjectCode({ value: '', message: '' });
      setProjectName({ value: '', message: '' });
      setCustomerName({ value: '', message: '' });
      setFiles([]);
      dismissModal(e)
    }
  }

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let errorMessage = ''
    let newValue = e.currentTarget.value

    switch (e.currentTarget.name) {
      case 'projectCode':
        errorMessage = newValue === '' ? 'Project ID is required.' : ''
        setProjectCode({ value: newValue, message: errorMessage })
        break
      case 'projectName':
        errorMessage = newValue === '' ? 'Project name is required.' : ''
        setProjectName({ value: newValue, message: errorMessage })
        break
      case 'customerName':
        errorMessage = newValue === '' ? 'Customer name is required.' : ''
        setCustomerName({ value: newValue, message: errorMessage })
        break
      case 'projectDescription':
        errorMessage = newValue === '' ? 'Customer name is required.' : ''
        setProjectDescription({ value: newValue, message: errorMessage })
        break
      default:
        break
    }
  }

  const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
    const uploadedFiles: FileList = e.currentTarget.files!
    const filesToAdd: File[] = []
    Array.from(uploadedFiles).map((file) => {
      filesToAdd.push(file)
    })
    setFiles([...files, ...filesToAdd])
  }

  async function handleProjectSubmit(e: any) {
    setIsLoading({ isActive: true, loadingMessage: 'Creating Project...' })
    if (validateForm()) {
      let currentProject: IProject = {
        code: projectCode.value,
        name: projectName.value,
        description: projectDescription.value,
        customer: customerName.value,
        isTraining: getTrainingMode()
      }

      if (isEditing === true) {
        currentProject = {
          code: projectCode.value,
          name: projectName.value,
          description: projectDescription.value,
          customer: customerName.value,
          clarificationUrl: project?.clarificationUrl === null ? '' : project?.clarificationUrl,
          created: project?.created,
          id: project?.id,
          isTraining: getTrainingMode()
        }
      }

      try {
        // ======
        // === POST Projects ===
        // ======

        if (isEditing === true) {
          const response = await apiService.put(`projects/${project?.id!}`, currentProject, false)
          if (!response.ok) {
            throw new Error('Error in API response.')
          }
          const result = await response.json()
          project?.id!;

          await uploadDocuments(project?.id!);
          await uploadPositions(project?.id!);
          await deletePositions(project?.id!);
        }
        else {
          const response = await apiService.post(
            'projects',
            currentProject,
            false
          )
          if (!response.ok) {
            throw new Error('Error in API response.')
          }

          const result = await response.json()
          const projectId = result.id;

          await uploadDocuments(projectId);
          await uploadPositions(projectId);

        }

        setIsLoading({ isActive: false, loadingMessage: '' })
        handleDismiss(true);
      } catch (error) {
        if (error instanceof Error) {
          setApiError({ isActive: true, value: error.message })
        } else {
          setApiError({
            isActive: true,
            value: 'An error occurred when calling the API.',
          })
        }
        setIsLoading({ isActive: false, loadingMessage: '' })
      }
    } else {
      setFormErrorMessage({
        isActive: true,
        message: 'Please enter all required fields.',
      })
      setIsLoading({ isActive: false, loadingMessage: '' })
    }
  }

  async function deletePositions(projectId: string) {
    if (positionsToDelete !== undefined) {
      positionsToDelete.forEach(async (item) => {
        await apiService.delete(`projects/${projectId}/positions/${item.id}`, true);
      });
    }
  }

  async function uploadDocuments(projectId: string) {
    if (files) {
      var failures = []
      for (let index = 0; index < files.length; index++) {
        setIsLoading({
          isActive: true,
          loadingMessage: `Uploading documents (${index + 1}/${files.length
            })`,
        })
        const f = files[index]
        let formData: FormData = new FormData()
        formData.append("file", f, f.name)

        // ======
        // === POST Documents ===
        // ======
        const response = await apiService.post(
          `projects/${projectId}/documents`,
          formData,
          false
        )
        if (!response.ok) {
          failures.push(f);
        }
      }
      if (failures.length > 0) {
        setFiles(failures);
        throw new Error(`Some documents failed to upload`);
      }
    }
  }

  async function uploadPositions(projectId: string) {
    if (positions !== undefined) {
      // ======
      // === POST Positions ===
      // ======         
      for (const position of positions) {
        position.projectId = projectId;
        setIsLoading({
          isActive: true,
          loadingMessage: 'Uploading positions...'
        })
        if (position.isReadOnly === false) {
          const response = await apiService.post(`projects/${projectId}/positions`, position, false);
          if (!response.ok) {
            setApiError({ isActive: true, value: response.statusText })
            throw new Error(`There was an issue adding positions. Status code: ${response.status}`);
          }
        } else {
          const response = await apiService.put(`projects/${projectId}/positions/${position.id}`, position);
          if (!response.ok) {
            setApiError({ isActive: true, value: response.statusText })
            throw new Error(`There was an issue updating positions. Status code: ${response.status}`);
          }
        }
      }
    }
  }

  function validateForm(): boolean {
    let isValid = true
    isValid =
      projectName.value !== '' &&
      customerName.value !== ''
    return isValid
  }

  function handleShowPositions() {
    setShowPositions(true)
  }

  const handleHidePositions = (incomingPositions: IPosition[], positionsToDelete: IPosition[] | undefined) => {
    if (isEditing === true) {
      let newPositions: IPosition[] = incomingPositions.map((pos) => {
        pos.projectId = project?.id;
        return pos;
      })

      setPositions(newPositions);
      setShowPositions(false)
    }
    else {
      setPositions(incomingPositions);
      setShowPositions(false)
    }

    setPositionsToDelete(positionsToDelete ?? []);
  }

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={show}
        onDismiss={() => handleDismiss(false)}
        isBlocking={false}
        containerClassName={contentStyles.container}
        dragOptions={isDraggable ? dragOptions : undefined}
      >
        {
          showPositions ? (
            <NewPositions
              positions={positions}
              handlePositionDismiss={handleHidePositions}
              apiService={apiService}
              pheTypes={pheTypes}
            />
          ) : (
            <>
              <div className={contentStyles.header}>
                <span id={titleId}>{isEditing === true ? 'Edit project' : 'Create project'}</span>
                <IconButton
                  styles={iconButtonStyles}
                  iconProps={cancelIcon}
                  ariaLabel="Close popup modal"
                  onClick={() => handleDismiss(false)}
                />
              </div>
              <div className={contentStyles.body}>
                <Label htmlFor="projectCode">
                  Project ID
                </Label>
                <TextField
                  id="projectCode"
                  name="projectCode"
                  errorMessage={projectCode.message}
                  onChange={handleChange}
                  disabled={isLoading.isActive}
                  value={projectCode.value}
                />

                <Label required htmlFor="projectName">
                  Project name
                </Label>
                <TextField
                  id="projectName"
                  name="projectName"
                  errorMessage={projectName.message}
                  onChange={handleChange}
                  disabled={isLoading.isActive}
                  value={projectName.value}
                />

                <Label required htmlFor="customerName">
                  Customer name
                </Label>
                <TextField
                  id="customerName"
                  name="customerName"
                  errorMessage={customerName.message}
                  onChange={handleChange}
                  disabled={isLoading.isActive}
                  value={customerName.value}
                />

                <Label htmlFor="projectDescription">
                  Project description
                </Label>
                <TextField
                  id="projectDescription"
                  name="projectDescription"
                  errorMessage={projectDescription.message}
                  multiline
                  maxLength={1024}
                  onChange={handleChange}
                  disabled={isLoading.isActive}
                  value={projectDescription.value}
                />
                <br />
                <div>
                  <Label
                    htmlFor="upload-documents"
                    className="upload-documents-label"
                    style={{ backgroundColor: theme.palette.themePrimary, borderRadius: 5 }}
                  >
                    Add documents
                  </Label>
                  <input
                    name="files"
                    id="upload-documents"
                    hidden
                    type={'file'}
                    onChange={handleFileChange}
                    accept=".pdf"
                    multiple
                    disabled={isLoading.isActive}

                  />
                  {!getTrainingMode() &&
                    <DefaultButton
                      disabled={isLoading.isActive}
                      style={{ margin: 10 }}
                      text={positions !== undefined ? `Add Positions (${positions.length})` : 'Add Positions'}
                      onClick={handleShowPositions}
                    />
                  }
                </div>
                <div>
                  {files?.map((f, key) => {
                    return (
                      <div key={key}>
                        <FontIcon
                          aria-label="Compass"
                          iconName="Pdf"
                          title={f.name}
                          style={{ padding: '5px' }}
                        />
                        {f.name}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div>
                {isLoading.isActive ? (
                  <div>
                    <Spinner label={isLoading.loadingMessage} />
                    <br />
                  </div>
                ) : (
                  <>
                    <PrimaryButton
                      style={{ float: 'right', margin: 10 }}
                      text={isEditing === true ? 'Save' : 'Create'}
                      iconProps={{ iconName: isEditing === false ? 'Accept' : 'Save' }}
                      onClick={handleProjectSubmit}
                      disabled={isLoading.isActive}
                    />
                    <DefaultButton
                      style={{ float: 'right', margin: 10 }}
                      text="Cancel"
                      onClick={() => handleDismiss(false)}
                      disabled={isLoading.isActive}
                    />
                    {formErrorMessage.isActive ? (
                      <MessageBar
                        messageBarType={MessageBarType.error}
                        dismissButtonAriaLabel="Close"
                        dismissIconProps={{ iconName: 'Clear', styles: { root: { color: `${theme.palette.white} !important` } } }}
                        styles={{
                          root: {
                            background: theme.palette.red
                          },
                          icon: {
                            color: theme.palette.white
                          }
                        }}
                      >
                        {formErrorMessage.message}
                      </MessageBar>
                    ) : (
                      <></>
                    )}
                    {apiError.isActive ? (
                      <MessageBar
                        messageBarType={MessageBarType.error}
                        dismissButtonAriaLabel="Close"
                        dismissIconProps={{ iconName: 'Clear', styles: { root: { color: `${theme.palette.white} !important` } } }}
                        styles={{
                          root: {
                            background: theme.palette.red
                          },
                          icon: {
                            color: theme.palette.white
                          }
                        }}
                      >
                        {apiError.value}
                      </MessageBar>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </>
          )}
      </Modal>
    </div>
  )
}

const cancelIcon: IIconProps = { iconName: 'Cancel' }

const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
    minWidth: '400px',
  },
})
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}
