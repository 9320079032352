import React, { FC, useEffect, useState, FormEvent } from 'react';
import { IDocument } from '../../models/IDocument';
import { IPosition } from '../../models/IPosition';
import { IProject } from '../../models/IProject';
import Pdfjs, { PdfDocument } from 'pdfjs-dist/webpack'

import './documentsList.scss';
import PdfHighlighter from './PdfHighLighter';
import { DemandsPane } from '../../components/demands/DemandsPane';
import { IconButton, TextField, FontSizes, getTheme, IStackItemStyles, Checkbox, IStackTokens, mergeStyles, PrimaryButton, Shimmer, Spinner, SpinnerSize, Stack, ThemeProvider, DefaultButton, DefaultPalette, Icon, Text } from '@fluentui/react';
import { DocumentWrapper } from './documenthelpers/DocumentWrapper';
import PdfToolbar from './documenthelpers/PdfToolbar';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { AuthenticationService } from '../../Services/AuthenticationService';
import { ApiService } from '../../Services/ApiService';
import { SelectCategoryModal } from '../../components/shared/SelectCategoryModal';
import { useParams } from 'react-router-dom';
import { IGlobalDemand } from '../../models/IGlobalDemand';
import { IPheType } from '../../models/IPheType';
import { IContent } from '../../models/IContent';
import { IInteraction, ISentenceInfo } from '../../models/IInteraction';
import { IContext } from '../../models/IContext';
import { System } from '../../typings';
import { getBusinessUnit, getDocumentPosition, setDocumentPosition, setProjectId } from '../../Services/Global';
import { useNavigate } from 'react-router-dom'
import { ContextType } from '../../models/Enums';
import { Actions, hasPermission } from '../../Services/Permissions';
import { TrainingPane } from '../../components/demands/TrainingPane';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { elevationStackStyles } from '../../Services/Styles';
import { ISettings } from '../../models/ISettings';

export const DocumentView: FC<{}> = () => {
    const ctx: IMsalContext = useMsal()
    const authService = new AuthenticationService(ctx)
    const apiService = new ApiService(ctx)

    const theme = getTheme();

    const [isBusy, setIsBusy] = useState(false);
    const [saveMessage, setSaveMessage] = useState<string>('');
    const [pdfData, setPdfData] = useState<PdfDocument>();
    const [positions, setPositions] = useState<IPosition[]>();
    const [selectedHighlight, setSelectedHighlight] = useState<System.Highlight>();
    const [albotSuggestions, setAlbotSuggestions] = useState<Record<number, IContent[]>>();
    const [interactionsByPage, setInteractions] = useState<Record<number, any[]>>();
    const [currentPage, setCurrentPage] = useState(1);
    const [document, setDocument] = useState<IDocument>();
    const [currentSelection, setCurrentSelection] = useState<System.Highlight | undefined>();
    const [globalDemands, setGlobalDemands] = useState<IGlobalDemand[]>([]);
    const [filteredGlobalDemands, setFilteredGlobalDemands] = useState<IGlobalDemand[]>([]);
    const [position, setPosition] = useState('');
    const [contentById, setContentById] = useState<Record<string, IContent>>();
    const [contextById, setContextById] = useState<Record<string, IContext>>();
    const [pheTypes, setPheTypes] = useState<IPheType[]>();
    const [description, setDescription] = useState<string>();
    const [revision, setRevision] = useState<string>();
    const [isTraining, setIsTraining] = useState<boolean>();
    const [noMatch, setNoMatch] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState<string>();

    const [settings, setSettings] = useState<ISettings>();

    const [relevanceThreshold, setRelevanceThreshold] = useState<number>();
    const [demandThreshold, setDemandThreshold] = useState<number>();

    const [showDemandsModal, setShowDemandsModal] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [alwaysShowDemands, setAlwaysShowDemands] = useState(false);

    const params = useParams();
    const projectId = params.id === undefined ? '' : params.id;
    const documentId = params.docId === undefined ? '' : params.docId;

    let navigate = useNavigate();

    if (projectId) {
        setProjectId(projectId);
    }

    async function loadDocumentAndPositionDataAsync() {
        const project = await apiService.get(`Projects/${projectId}`);
        setIsTraining((await project.json() as IProject).isTraining ?? false);

        const doc = await apiService.get(`Projects/${projectId}/documents/${documentId}`);
        const fetchedDoc: IDocument = await doc.json();

        if (fetchedDoc.category === 'Position') {
            const docPosition = await apiService.get(`Projects/${projectId}/positions/${fetchedDoc.positionId}`);
            const pos: IPosition = await docPosition.json();
            setPositions([pos]);
            setPosition(pos?.name ?? '');
        }
        else if (fetchedDoc.category === 'Order') {
            const docPosition = await apiService.get(`Projects/${projectId}/positions/`);
            const pos: IPosition[] = await docPosition.json();
            setPositions(pos);
        }
        if (fetchedDoc.status === 'Unopened') {
            await apiService.put(`projects/${projectId}/documents/${documentId}/status/Opened`);
            fetchedDoc.status = 'Opened'
        }
        setDocument(fetchedDoc);
        setDescription(document?.description)
        const storedDocPage = getDocumentPosition(projectId, documentId);
        storedDocPage && setCurrentPage(storedDocPage.page)
    }

    async function getGlobalDemandsAsync() {
        const response = await apiService.get(`globaldemands`);
        setGlobalDemands(await response.json());
    }

    async function getSuggestionsAsync() {

        // initially - get settings to set thresholds for relevance and demand
        const currentSettings = await getThresholds();
        setLoadingMessage("Loading Albot suggestions...");

        // First, get all interactions so that we can filter out the incoming suggestions that will not be rendered.
        // Then get all suggestions
        const interactionResponse = await apiService.get(`Projects/${projectId}/interactions?documentId=${documentId}`);
        const interactionResult: IInteraction[] = await interactionResponse.json();
        const allInteractions: Record<number, System.Highlight[] | any[]> = {}

        let interactionContentIds: System.Highlight[] = [];

        for (let i = 1; i <= interactionResult.length; i++) {
            const item = interactionResult[i - 1];

            if (allInteractions[item?.meta?.pageNumber] === undefined) {
                allInteractions[item.meta.pageNumber] = [];
            }
            if (item.meta.match === null) {
                allInteractions[item.meta.pageNumber] = [...allInteractions[item.meta.pageNumber] as [], {
                    from: 'interaction',
                    id: item.id,
                    pageNumber: item.meta.pageNumber,
                    position: item.highlight.position,
                    type: item.highlight.type,
                    text: item.highlight.text
                }];

                interactionContentIds.push({
                    from: 'interaction',
                    id: item.id!,
                    pageNumber: item.meta.pageNumber,
                    position: item.highlight.position,
                    type: item.highlight.type,
                    text: item.highlight.text
                });
            }
            else {
                let match = item.meta.match! as ISentenceInfo;
                allInteractions[item.meta.pageNumber] = [...allInteractions[item.meta.pageNumber] as [], {
                    from: 'interaction',
                    id: item.id,
                    pageNumber: item.meta.pageNumber,
                    position: item.highlight.position,
                    type: match.contextType,
                    text: match.text
                }];

                interactionContentIds.push({
                    from: 'interaction',
                    id: item.id!,
                    pageNumber: item.meta.pageNumber,
                    position: item.highlight.position,
                    type: item.highlight.type,
                    text: match.text
                });
            }
        }

        setInteractions(allInteractions);

        const response = await apiService.get(`Projects/${projectId}/documents/${documentId}/json`);
        const result = await response.json();


        const contentById: Record<string, IContent> = result.contentDomain.byId;
        setContentById(contentById);
        const contextById: Record<string, IContext> = result.contextDomain.byId;
        setContextById(contextById);

        let allSuggestions: Record<number, IContent[]> = {}

        for (const [key, value] of Object.entries(contextById)) {

            for (let i = 0; i < value.contentIds.length; i++) {
                let currentArray = allSuggestions[value.page];
                if (currentArray === undefined) {
                    currentArray = []
                }
                let target = contentById[value.contentIds[i]];
                if (target.relevantProba !== undefined) {
                    if (isRelevantSuggestion(target, currentSettings)) {
                        // This has been updated compared to legacy code.
                        // For some reason, extra spaces are added to the text so it cannot be compared directly. The replace() below removes the all whitespace in the string.
                        // Now also checking if the demand's highlight text is included in the interaction highlight since the sentences aren't handled properly in the demand highlight.
                        if (!interactionResult.find(x => x.meta.pageNumber === value.page
                            && (x.highlight.text?.replace(/\s/g, '').includes(target.text.replace(/\s/g, ''))
                                || target.text.replace(/\s/g, '').includes(x.highlight.text?.replace(/\s/g, '')!)
                            )
                        )) {
                            currentArray.push(contentById[value.contentIds[i]]);
                            allSuggestions[value.page] = currentArray;
                        }
                    }

                }
            }
            value.contentIds.forEach((id) => {

            });
        }

        const pheResponse = await apiService.get(`phetypes`)
        const phes: IPheType[] = await pheResponse.json();
        setPheTypes(phes);
        setIsBusy(false);
        setAlbotSuggestions(allSuggestions);
    }

    async function getThresholds() {
        const settingsResponse = await apiService.get("settings");
        const settingsResult = await settingsResponse.json();

        setSettings(settingsResult);

        return settingsResult;
    }

    useEffect(() => {
        getThresholds();
        async function getDocument() {
            await loadDocumentAndPositionDataAsync();
        }

        async function getPdfDocumentAsync() {
            const accessToken = await authService.getAccessToken();
            setLoadingMessage("Loading PDF...");
            const url = apiService.getUrl(`Projects/${projectId}/documents/${documentId}/pdf`);
            const pdf = await Pdfjs.getDocument({ url: url, httpHeaders: { Authorization: `Bearer ${accessToken}` }, withCredentials: true });

            setPdfData(pdf);
            // get suggestions moved within pdf retrieval as it times the highlights for more reliable rendering
            await getSuggestionsAsync();
        }


        getDocument();
        getPdfDocumentAsync();
        getGlobalDemandsAsync();
    }, [documentId]);


    /**if the suggested highlight is relevant, add it to the array of relevant suggestions while returning true. */
    const isRelevantSuggestion = (input: IContent, currentSettings?: ISettings): boolean => {
        if (!input.relevantProba || !input.cdLogregPredictions || !input.cdTransformerPredictions) {
            return false;
        }

        // business unit workaround for WHE model.
        const businessUnit = getBusinessUnit();
        if (businessUnit === 'WHE') {
            const relevance = input.relevantProba > (currentSettings?.relevanceThreshold ?? 0.4) && input.cdLogregPredictions![0].proba > (currentSettings?.demandThreshold ?? 0.5);
            return relevance;
        }
        else {
            const relevance = input.relevantProba > 0.4 && input.cdLogregPredictions![0].proba > 0.1 && input.cdTransformerPredictions![0].proba! > 0.5;
            return relevance;
        }
    }
    // === STACK layout ===
    const stackItemStyles: IStackItemStyles = {
        root: {
            display: 'flex'
        },
    };
    const stackTokens: IStackTokens = {
        childrenGap: 5,
        padding: 10,
    };

    const handleUpdatePosition = (e: number) => {
        if (e !== undefined && !isNaN(e)) {
            setCurrentPage(e);
            setDocumentPosition(projectId, documentId, e);
        }
    }

    async function handleDismissDemandsModal() {
        await getGlobalDemandsAsync();
        setSelectedHighlight(undefined);
        setCurrentSelection(undefined);
        setShowDemandsModal(false);
    }

    function onShowCategoryModal() {
        setShowCategoryModal(true);
    }

    /**
     * Handles interactions from the document. Selection (blue), Suggestion (Yellow) and Interaction (Green) highlights.
     * @param documentHighlight {System.Highlight} highlight object when a user interacts with the document.
     */
    function handleHighlightFocus(documentHighlight: System.Highlight) {
        // If the 'area' type is used, then the user have right-click dragged a square in the document which will not contain any real text.
        // This is used for documents where the text could not be properly extracted.
        if (documentHighlight.type !== 'area') {
            if (documentHighlight.from === 'selection' || documentHighlight.from === 'suggestion') {
                const contentId = findSentenceInfo(documentHighlight.text!, documentHighlight.pageNumber, documentHighlight.type! as ContextType);
                // using matched content id to use for the highlight as custom made highlights never have an ID.
                // There is a risk where there will not be a match for the selection, in those cases, the stored value will not have any attached metadata.
                if (contentId !== null) {
                    documentHighlight.contentId = contentId;
                    setNoMatch(false);
                }
                else {
                    setNoMatch(true);
                }
            }
            // if the document is from an already created demand which has an interaction, populate the interaction id.
            if (documentHighlight.from === 'interaction') {
                documentHighlight.interactionId = documentHighlight.id;
            }
        }
        else {
            setFilteredGlobalDemands([]);
        }

        setSelectedHighlight(documentHighlight);
        setCurrentSelection(documentHighlight);
        setShowDemandsModal(true);
    }

    function handleRemoveSuggestion(documentHighlight: System.Highlight) {
        // remove suggestion from the list of suggestions
        let suggestions = albotSuggestions;
        if (suggestions !== undefined) {
            let pageSuggestions = suggestions[documentHighlight.pageNumber];
            if (pageSuggestions !== undefined) {
                let index = pageSuggestions.findIndex(x => x.id === documentHighlight.id);
                if (index !== -1) {
                    pageSuggestions.splice(index, 1);
                    suggestions[documentHighlight.pageNumber] = pageSuggestions;
                }
            }
        }

        setAlbotSuggestions({ ...suggestions });
        handleDismissDemandsModal();
    }

    function onDismissCategoryModal(e: boolean) {
        loadDocumentAndPositionDataAsync();
        setShowCategoryModal(false);
    }

    const contentOnPage = (pageNumber: number) => {
        let context: IContext[] = [];
        for (const [key, value] of Object.entries(contextById!)) {
            if (value.page === pageNumber) {
                context.push(value);
            }
        }

        let content: IContent[] = [];
        context.forEach((item) => {
            item.contentIds.forEach((id) => {
                content.push(contentById![id]);
            });
        })

        return content;
    }

    // TODO: If albot suggested more than one sentence, this will never work as the incoming JSON is always split into single sentences.
    /** Finds information about the highlighted sentence and adds any relevant global demands to the component's array. */
    function findSentenceInfo(highlightedText: string, pageNumber: number, contextType: ContextType) {

        const searchString = highlightedText.replace(/\s+/g, '');

        if (!searchString) return null;

        const matchingContent: IContent[] = (contentOnPage(pageNumber)).filter(
            ({ text }: IContent) => text.replace(/\s+/g, '').indexOf(searchString) !== -1,
        );

        const uniqueContentMatch = matchingContent.length === 1 ? matchingContent[0] : null;
        if (!uniqueContentMatch) {
            if (process.env.NODE_ENV === 'development') {
                if (matchingContent.length) {
                    console.error('Could not match content - multiple matches');
                } else {
                    console.error('Could not match content - no matches');
                }
            }

            setFilteredGlobalDemands([]);
            return null;
        }


        let availableGlobalDemands: IGlobalDemand[] = [];

        uniqueContentMatch?.cdTransformerPredictions?.forEach((x) => {
            const targetGlobalDemand = globalDemands.find(y => y.id === x.label);
            targetGlobalDemand && availableGlobalDemands.push(targetGlobalDemand);
        });

        uniqueContentMatch?.cdLogregPredictions?.forEach((x) => {
            const targetGlobalDemand = globalDemands.find(y => y.id === x.label);
            if (availableGlobalDemands.findIndex(y => y.id === x.label) === -1) {
                targetGlobalDemand && availableGlobalDemands.push(targetGlobalDemand);
            }
        });

        if (availableGlobalDemands.length !== 0) {
            setFilteredGlobalDemands(availableGlobalDemands);
        }

        return uniqueContentMatch.id;
    }

    const shimmerWrapperClass = mergeStyles({
        padding: 2,
        width: '50vw',
        selectors: {
            '& > .ms-Shimmer-container': {
                margin: '10px 0',
            },
        },
    });

    async function setDone() {
        await apiService.put(`projects/${projectId}/documents/${documentId}/status/Done`);
        close()
    }

    function close() {
        navigate(`/projects/${projectId}/documents`);
    }

    const onDescriptionChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setDescription(newValue);
        },
        [],
    );

    const onRevisionChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setRevision(newValue);
        },
        [],
    );

    const saveDescriptionAndRevision = async () => {
        if (document) {
            await apiService.put(`projects/${document.projectId}/documents/${document.id}/description`,
                {
                    description: description ?? document.description,
                    revision: revision ?? document.revision
                })
        }

        setSaveMessage('Saved!');
        setTimeout(() => {
            setSaveMessage('');
        }, 2000);
    }

    const handleIsTraining = async (ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
        await apiService.put(`projects/${document!.projectId}/documents/${document!.id}/includeInTraining/${checked}`);
    }

    return (
        <div style={{ padding: '20px 50px' }}>
            <Stack style={{ marginTop: -20 }} grow>
                {
                    document &&
                    <>
                        <Stack horizontal grow>
                            <Stack.Item grow={1}>
                                <TextField style={{ minWidth: 250 }} label='Document Description' defaultValue={document?.description} onChange={onDescriptionChange} />
                            </Stack.Item>
                            <Stack.Item grow={1} style={{ padding: '0px 5px' }}>
                                <TextField style={{ minWidth: 250 }} label='Revision' defaultValue={document?.revision} onChange={onRevisionChange} />
                            </Stack.Item>
                            <Stack.Item align='end' grow>
                                <Stack horizontal>
                                    <Stack.Item>
                                        <IconButton style={{ marginLeft: 5 }} iconProps={{ iconName: 'Save' }} title='Save' onClick={() => saveDescriptionAndRevision()} />
                                    </Stack.Item>
                                    {
                                        saveMessage &&

                                        <Stack.Item style={{ color: theme.palette.green, fontSize: 14, marginTop: 8 }}>
                                            {
                                                saveMessage &&
                                                <span>
                                                    <Icon iconName={'Completed'} />
                                                    <span style={{ marginLeft: 5 }}>{saveMessage}</span>
                                                </span>
                                            }
                                        </Stack.Item>

                                    }
                                </Stack>
                            </Stack.Item>
                            {!isTraining  && hasPermission(Actions.Training) &&
                                <Stack.Item style={{ padding: '8px 3px' }} align='end'>
                                    <Checkbox defaultChecked={document.isTraining} styles={{ root: { padding: 5 } }} label='Use for AI training' onChange={(e, c) => handleIsTraining(e, c)} />
                                </Stack.Item>
                            }
                            &nbsp;
                            {!isTraining &&
                                <>
                                    <Stack.Item style={{ padding: '8px 3px' }} align='end'>
                                        <span style={{ color: theme.palette.white, backgroundColor: theme.palette.blue, boxShadow: theme.effects.elevation8, height: 30, padding: 5, fontSize: FontSizes.size14, borderRadius: '5px' }}>
                                            Document Category: {document.category}
                                        </span>
                                    </Stack.Item>
                                    {
                                        document.category === 'Position' &&
                                        <Stack.Item style={{ padding: '8px 3px' }} align='end'>
                                            <span style={{ color: theme.palette.white, backgroundColor: theme.palette.blue, boxShadow: theme.effects.elevation8, height: 30, padding: 5, fontSize: FontSizes.size14, borderRadius: '5px' }}>
                                                Position: {position}
                                            </span>
                                        </Stack.Item>
                                    }
                                </>
                            }
                            <Stack.Item style={{ padding: 2 }} align='end'>                                
                                {!isTraining &&
                                    <PrimaryButton iconProps={{ iconName: 'Tag' }} text='Categorize Document' onClick={(e) => onShowCategoryModal()}
                                        styles={{
                                            root: {
                                                transition: '175ms'
                                            },
                                            rootHovered: {
                                                transition: '175ms'
                                            }
                                        }} />
                                }
                                &nbsp;
                                {hasPermission(Actions.DocumentSetDone) &&
                                    <DefaultButton iconProps={{ iconName: 'Checkmark' }} styles={{
                                        root: {
                                            backgroundColor: theme.palette.green,
                                            color: theme.palette.white,
                                            border: 'none',
                                            transition: '175ms'
                                        },
                                        rootHovered: {
                                            backgroundColor: theme.palette.greenDark,
                                            color: theme.palette.white,
                                            transition: '175ms'
                                        }
                                    }} text='Done' onClick={(e) => setDone()} />
                                }
                                &nbsp;
                                <DefaultButton iconProps={{ iconName: 'Cancel' }}
                                    styles={{
                                        root: {
                                            backgroundColor: theme.palette.red,
                                            color: theme.palette.white,
                                            border: 'none',
                                            transition: '175ms'
                                        },
                                        rootHovered: {
                                            backgroundColor: theme.palette.redDark,
                                            color: theme.palette.white,
                                            transition: '175ms'
                                        }
                                    }} text='Close' onClick={(e) => close()} />
                                <SelectCategoryModal show={showCategoryModal} dismissModal={onDismissCategoryModal} document={document} />
                            </Stack.Item>
                        </Stack>
                    </>
                }
            </Stack>
            <Stack horizontal wrap tokens={stackTokens}>
                <Stack.Item styles={stackItemStyles}>
                    <Stack>
                        {
                            (pdfData === undefined || albotSuggestions === undefined) ?
                                <Stack.Item>
                                    <ThemeProvider className={shimmerWrapperClass}>
                                        <Spinner label={loadingMessage ?? 'Loading...'} size={SpinnerSize.large} style={{ padding: 50 }} />
                                        <Shimmer />
                                        <Shimmer width='75%' height='75px' />
                                        <Shimmer width='50%' />
                                        <Shimmer />
                                        <Shimmer width='75%' height='75px' />
                                        <Shimmer width='50%' />
                                        <Shimmer />
                                        <Shimmer width='75%' height='75px' />
                                        <Shimmer width='50%' />
                                        <Shimmer />
                                        <Shimmer width='75%' height='75px' />
                                        <Shimmer width='50%' />
                                        <Shimmer />
                                        <Shimmer width='75%' height='75px' />
                                        <Shimmer width='50%' />
                                        <Shimmer />
                                        <Shimmer width='75%' height='75px' />
                                        <Shimmer width='50%' />
                                        <Shimmer />
                                        <Shimmer width='75%' height='75px' />
                                        <Shimmer width='50%' />
                                        <Shimmer />
                                        <Shimmer width='75%' height='75px' />
                                        <Shimmer width='50%' />
                                    </ThemeProvider>
                                </Stack.Item>
                                :
                                <>
                                    {
                                        isBusy && <Spinner label='Refreshing highlights..' />
                                    }
                                    {
                                        pdfData && albotSuggestions &&
                                        <>
                                            <Stack.Item style={{ width: '50vw' }}>
                                                <DocumentWrapper>
                                                    <PdfHighlighter
                                                        pdfDocument={pdfData}
                                                        albotSuggestionsByPage={albotSuggestions!}
                                                        currentPage={currentPage}
                                                        highlightsByPage={interactionsByPage}
                                                        isRephrasingActitve={true}
                                                        selectHighlight={handleHighlightFocus}
                                                        startHighlight={handleHighlightFocus}
                                                        updatePosition={handleUpdatePosition}
                                                        currentSelection={currentSelection}
                                                    />
                                                </DocumentWrapper>
                                                {
                                                    getBusinessUnit() === 'WHE' &&
                                                    <>
                                                        <Text>Relevance setting: {settings?.relevanceThreshold} | Demand setting: {settings?.demandThreshold}</Text>
                                                    </>
                                                }
                                            </Stack.Item>
                                            <Stack.Item grow align='center' styles={stackItemStyles}>
                                                <PdfToolbar
                                                    currentPage={currentPage}
                                                    totalPages={pdfData?.numPages || 0}
                                                    updateDocumentPosition={handleUpdatePosition}
                                                />
                                            </Stack.Item>
                                        </>
                                    }
                                </>
                        }
                    </Stack>
                    <Stack.Item styles={stackItemStyles}>
                        {
                            hasPermission(Actions.DocumentTextSelection) && !isTraining && showDemandsModal &&
                            <DemandsPane
                                removeHighlight={() => handleRemoveSuggestion(selectedHighlight!)}
                                highlight={selectedHighlight!}
                                dismissModal={() => handleDismissDemandsModal()}
                                globalDemands={globalDemands}
                                projectId={projectId}
                                document={document!}
                                matches={contentById!}
                                refreshInteractions={() => { getSuggestionsAsync(); setIsBusy(true) }}
                                positions={positions}
                                pheTypes={pheTypes || []}
                                filteredGlobalDemands={filteredGlobalDemands}
                                onceSelectedAlwaysTrue={alwaysShowDemands}
                                toggleSelected={() => { setAlwaysShowDemands(true) }}
                            />
                        }
                        {
                            hasPermission(Actions.Training) && isTraining && showDemandsModal &&
                            <Stack>
                                {
                                    noMatch &&

                                    <Stack.Item style={{ margin: 5 }}>
                                        <MessageBar messageBarType={MessageBarType.warning} messageBarIconProps={{ iconName: 'Warning' }}
                                            isMultiline={true}
                                            onDismiss={() => {
                                                setNoMatch(false);
                                                setSelectedHighlight(undefined);
                                                setShowDemandsModal(false);
                                            }}
                                            style={{ color: theme.palette.black, fontSize: FontSizes.medium }}
                                            styles={elevationStackStyles(theme, theme.effects.elevation4)}>
                                            <p>Cannot create training data from current highlight.</p>
                                            <p>Please <strong>retry</strong> current selection, or <strong>ignore</strong> it and continue with the rest of the document.</p>
                                        </MessageBar>

                                    </Stack.Item>

                                }
                                <Stack.Item grow>
                                    {
                                        !noMatch &&
                                        <TrainingPane
                                            highlight={selectedHighlight!}
                                            dismissModal={() => handleDismissDemandsModal()}
                                            globalDemands={globalDemands}
                                            projectId={projectId}
                                            document={document!}
                                            matches={contentById!}
                                            refreshInteractions={() => { getSuggestionsAsync(); setIsBusy(true); }}
                                            filteredGlobalDemands={filteredGlobalDemands}
                                            onceSelectedAlwaysTrue={alwaysShowDemands}
                                            handleDismissNewDemand={() => getGlobalDemandsAsync()}
                                        />
                                    }
                                </Stack.Item>
                            </Stack>
                        }
                    </Stack.Item>

                </Stack.Item>

            </Stack>
        </div >
    )
}