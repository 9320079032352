import { DefaultButton, FontSizes, getTheme, IconButton, PrimaryButton, Stack } from "@fluentui/react"
import { useEffect, useState } from "react"
import { IClarification, IDocumentClarification } from "../../models/IDocumentClarification"
import { IInteraction } from "../../models/IInteraction"
import { getProjectId } from "../../Services/Global"
import { System } from "../../typings"
import { PdfRenderingModal } from "../../components/shared/PdfRenderingModal"

export type IntByDoc = {
    docName: string,
    interactions: IInteraction[]
}

export const ClarificationDetails: React.FC<{ clarification?: IClarification, interactions?: IInteraction[], currentDocument?: IDocumentClarification, hide: () => void, showDemandsPane: (demandId: string, interaction: IInteraction) => void, interactionsByDoc?: Record<string, IInteraction[]>, selectedInteraction: IInteraction }> = (props) => {
    let theme = getTheme();
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState<number>();
    const [currentHighlight, setCurrentHighlight] = useState<System.Highlight>();
    const [currentDocumentId, setCurrentDocumentId] = useState<string>();
    const [interactionByDocumentName, setInteractionByDocumentName] = useState<IntByDoc[]>();

    useEffect(() => {

        if (props.clarification === undefined || props.interactions === undefined || props.currentDocument === undefined) {
            return;
        }

        if (props.interactionsByDoc === undefined) return;
        let intsByDoc: IntByDoc[] = [];
        for (const [key, value] of Object.entries(props.interactionsByDoc)) {
            intsByDoc.push({ docName: key, interactions: value });
        }

        setInteractionByDocumentName(intsByDoc);
    }, [props])

    const handlePdfClick = (i: IInteraction, documentId: string) => {
        const docInteractions: IInteraction[] | undefined = props.interactions?.filter(x => x.documentId === documentId);
        if (docInteractions === undefined) return;
        setShowPdfModal(true);
        setCurrentPageNumber(i.meta.pageNumber!);
        setCurrentHighlight(i.highlight)
        setCurrentDocumentId(documentId);
    }

    return (
        <div style={{ width: '45vw', height: '90vh', padding: 20, marginLeft: 10, marginTop: 20, boxShadow: theme.effects.elevation4 }}>
            {
                <div>
                    <p style={{ fontSize: FontSizes.size24 }}>Overview</p>
                    <Stack>
                        <Stack.Item>
                            Adjusted marking(s)

                            <Stack style={{ overflow: 'scroll' }}>
                                {
                                    interactionByDocumentName?.map((entry, index) => {
                                        return (
                                            <Stack key={index} style={{ margin: 10, padding: 20, boxShadow: theme.effects.elevation4 }}>
                                                <Stack.Item style={{ fontSize: FontSizes.medium, fontWeight: 'bold' }}>
                                                    {entry.docName}
                                                </Stack.Item>
                                                {
                                                    entry.interactions.map((ints, i) => {
                                                        return (
                                                            <>
                                                                <div style={{ boxShadow: theme.effects.elevation4, margin: 5, padding: 8 }}>
                                                                    <Stack horizontal style={{ padding: 0 }}>
                                                                        <Stack.Item>
                                                                            <IconButton iconProps={{ iconName: 'PDF' }} text='PDF' onClick={() => { handlePdfClick(ints, ints.documentId) }} />
                                                                        </Stack.Item>
                                                                        <Stack.Item style={{ padding: 5 }}>
                                                                            <p>Page: {ints.meta.pageNumber}</p>
                                                                        </Stack.Item>
                                                                    </Stack>
                                                                    {
                                                                        ints.comment !== '' ?
                                                                            <Stack.Item>
                                                                                <div>
                                                                                    <p style={{ fontWeight: 600 }}>Notes: {ints.comment}</p>
                                                                                </div>
                                                                            </Stack.Item>
                                                                            :
                                                                            <></>
                                                                    }
                                                                    <hr style={{ borderColor: 'rgba(0,0,0,0.15)' }} />
                                                                    <Stack.Item style={{ padding: 5 }}>
                                                                        <p>{ints.meta.rephrasing}</p>
                                                                    </Stack.Item>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }

                                            </Stack>
                                        )
                                    })
                                }
                            </Stack>
                        </Stack.Item>
                        <Stack.Item >
                            Customer demand
                        </Stack.Item>
                        <Stack.Item align='start'>
                            <PrimaryButton text={props.clarification?.demandName} onClick={() => props.showDemandsPane(props.clarification!.demandId, props.selectedInteraction)} />
                            <IconButton iconProps={{ iconName: 'Delete' }} />
                        </Stack.Item>

                    </Stack>
                    <Stack style={{ marginTop: 40 }}>
                        <Stack horizontal style={{ padding: 10 }}>
                            <Stack.Item>
                                <DefaultButton text='Close' onClick={() => props.hide()} />
                            </Stack.Item>
                        </Stack>
                    </Stack>

                </div>
            }
            <PdfRenderingModal
                dismissModal={() => setShowPdfModal(false)}
                show={showPdfModal}
                projectId={getProjectId()}
                documentId={currentDocumentId!}
                pageNumber={currentPageNumber!}
                highlight={[currentHighlight!]}
            />
        </div>
    )
}