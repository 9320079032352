import { BusinessUnit } from "../../models/Enums";
import { hasRole } from "../Global";
import { Actions, AdminRoles, BidEngineerRoles, GpheRoles, WheRoles, Roles } from "./constants";

const mappings = buildMappings();

function buildMappings(): Map<Actions, Roles[]> {
    return new Map<Actions, Roles[]>([
        [Actions.DocumentTextSelection, [...AdminRoles, ...BidEngineerRoles]],
        [Actions.ClarificationView, [...AdminRoles, ...BidEngineerRoles]],
        [Actions.SummaryExport, [...AdminRoles, ...BidEngineerRoles]],
        [Actions.SummaryData, [...AdminRoles, ...BidEngineerRoles]],
        [Actions.DemandsCreate, AdminRoles],
        [Actions.DemandsAddExtra, AdminRoles],
        [Actions.DemandsDeleteExtra, AdminRoles],
        [Actions.ProjectDelete, AdminRoles],
        [Actions.DocumentDelete, AdminRoles],
        [Actions.DocumentSetDone, [...AdminRoles, ...BidEngineerRoles]],
        [Actions.AdminView, AdminRoles],
        [Actions.Training, AdminRoles]
    ]);
}

export function hasPermission(action: Actions): boolean {
    if (!mappings.has(action)) {
        return false;
    }
    let roles = mappings.get(action)?.map(x => x.toString());
    let temp = roles == undefined ? [] : roles;
    return hasRole(...temp);
}

export function getBusinessUnitAccess(): BusinessUnit[] {
    let ret: BusinessUnit[] = []
    if (hasRole(...GpheRoles)) {
        ret.push('GPHE')
    }
    if (hasRole(...WheRoles)) {
        ret.push('WHE')
    }
    return ret
}