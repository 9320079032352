import { BusinessUnit } from "../models/Enums";

export function setProjectId(id: string) {
    projectId = id;
}

export function getProjectId() {
    return projectId;
}

export function setProjectName(name: string) {
    projectName = name;
}

export function getProjectName() {
    return projectName;
}

export function setDocumentId(id: string) {
    documentId = id;
}

export function getDocumentId() {
    return documentId;
}

export function setRoles(newRoles: string[]) {
    roles = newRoles;
}

export function hasRole(...anyOfRoles: string[]): boolean {
    for (let i = 0; i < anyOfRoles.length; i++) {
        if (roles?.includes(anyOfRoles[i])) {
            return true;
        }
    }
    return false;
}

export function setDocumentName(name: string) {
    documentName = name;
}

export function getDocumentName() {
    return documentName;
}

export function setLocalStorageItem(name: string, value: any) {
    if (value) {
        window.localStorage.setItem(name, value);
    }
}

export function getBusinessUnit(): BusinessUnit {
    let bu = window.localStorage.getItem('business_unit') as BusinessUnit;
    if (!bu) {
        bu = 'None'
    }
    return bu
}

export function setBusinessUnit(value: string | null) {
    if (value) {
        window.localStorage.setItem('business_unit', value);
    }
}

export function getTrainingMode(): boolean {
    return JSON.parse(window.sessionStorage.getItem('is_training') ?? 'false')
}

export function setTrainingMode(value: boolean) {
    window.sessionStorage.setItem('is_training', String(value))
}

export function setDocumentPosition(projectId: string, documentId: string, page: number) {
    let documentPositions: any[] = window.localStorage.getItem('document_positions') ? JSON.parse(window.localStorage.getItem('document_positions') as string) : [];
    if (documentPositions.length === 0) {
        documentPositions = [];
    }


    let documentPosition = documentPositions.find((x: any) => x.projectId === projectId && x.documentId === documentId);
    if (documentPosition) {
        documentPosition.page = page;
    } else {
        // cap out the documents to remember at 10
        if (documentPositions.length >= 10) {
            documentPositions = documentPositions.slice(1);
        }
        documentPositions.push({ projectId, documentId, page });
    }

    window.localStorage.setItem('document_positions', JSON.stringify(documentPositions));
}

export function getDocumentPosition(projectId: string, documentId: string) {
    return window.localStorage.getItem('document_positions') ? JSON.parse(window.localStorage.getItem('document_positions') as string).find((x: any) => x.projectId === projectId && x.documentId === documentId) : null;
}

declare global {
    var __PROJECT_ID__: string;
    var __PROJECT_NAME__: string;
    var __DOCUMENT_ID__: string;
    var __ROLES__: string[];
    var __DOCUMENT_NAME__: string;
}

let projectId = window.__PROJECT_ID__;
let projectName = window.__PROJECT_NAME__;
let documentId = window.__DOCUMENT_ID__;
let roles = window.__ROLES__;
let documentName = window.__DOCUMENT_NAME__;
