import { Component } from 'react';
import { Domain, System } from '../../../typings';
import { ColoredRectangle } from './styled';

type Props = {
    position: System.ViewportPosition;
    onClick?: () => void;
    from: Domain.HighlightSource;
};

class AreaHighlight extends Component<Props> {
    render() {
        const { position, from, onClick } = this.props;

        return <ColoredRectangle onClick={onClick} from={from} style={{ ...position.boundingRect }} />;
    }
}

export default AreaHighlight;