import * as CSS from 'csstype';
import { PageViewport, PDFPageView, PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import { Domain, System } from '../../../../typings';
import { color } from './styles';

/**
 * Default pdfjs viewer border width, used for offset calculations.
 * It should be included in getBoundingRect but isn't for some reason.
 */
export const VIEWER_BORDER_WIDTH = 9;

export const getHighlightFromRange = (viewer: PDFViewer, range: Range, contentText?: string) => {
    if (!range || !range.toString().trim()) {
        return;
    }

    const page = getPageFromRange(range);

    if (!page) {
        return;
    }

    const rects = getMinimalClientRects(range, page.node);

    if (rects.length === 0) {
        return;
    }

    const boundingRect = getBoundingRect(rects);
    const viewportPosition: System.ViewportPosition = { boundingRect, rects };
    const scaledPosition = viewportPositionToScaled(viewer, viewportPosition, page.number);

    const highlight: System.Highlight = {
        id: `${Date.now()}`,
        pageNumber: page.number,
        position: scaledPosition,
        type: 'text',
        text: contentText === undefined ? range.toString() : contentText,
        from: 'selection',
    };

    return highlight;
};

export const getHighlightFromAreaSelection = (
    viewer: PDFViewer,
    startTarget: HTMLElement,
    boundingRect: System.ViewportRectangle,
) => {
    const page = getPageFromElement(startTarget);

    if (!page) {
        return;
    }

    const pageBoundingRect = {
        ...boundingRect,
        top: boundingRect.top - page.node.offsetTop - VIEWER_BORDER_WIDTH,
        left: boundingRect.left - page.node.offsetLeft - VIEWER_BORDER_WIDTH,
    };

    const viewportPosition: System.ViewportPosition = {
        boundingRect: pageBoundingRect,
        rects: [],
    };

    const scaledPosition = viewportPositionToScaled(viewer, viewportPosition, page.number);

    const areaHighlight: System.Highlight = {
        id: `${Date.now()}`,
        pageNumber: page.number,
        position: scaledPosition,
        type: 'area',
        from: 'selection',
    };

    return areaHighlight;
};

export const findOrCreateHighlightLayer = (viewer: PDFViewer, page: number) => {
    const textLayer = viewer.getPageView(page - 1).textLayer;

    if (!textLayer) {
        return null;
    }

    return findOrCreateContainerLayer(textLayer.textLayerDiv, 'highlight_layer');
};
export const getHighlightColor = (from: Domain.HighlightSource): CSS.Property.Color => {
    switch (from) {
        case 'selection':
            return color.highlightSelection;
        case 'interaction':
            return color.highlightInteraction;
        case 'suggestion':
            return color.highlightSuggestion;
        default:
            return color.highlightSelection;
    }
};

export const scaledPositionToViewport = (
    viewer: PDFViewer,
    { boundingRect, rects }: System.ScaledPosition,
    pageNumber: number,
): System.ViewportPosition => {
    const vpTarget = viewer.getPageView(pageNumber - 1);
    const viewport = vpTarget.viewport;

    return {
        boundingRect: scaledRectangleToViewport(boundingRect, viewport),
        rects: (rects || []).map((rect) => scaledRectangleToViewport(rect, viewport)),
    };
};

export const scaledPositionToViewportByPageView = (
    viewer: PDFPageView,
    { boundingRect, rects }: System.ScaledPosition
): System.ViewportPosition => {
    const viewport = viewer.viewport;
    return {
        boundingRect: scaledRectangleToViewport(boundingRect, viewport),
        rects: (rects || []).map((rect) => scaledRectangleToViewport(rect, viewport)),
    };
};


const viewportPositionToScaled = (
    viewer: PDFViewer,
    { boundingRect, rects }: System.ViewportPosition,
    pageNumber: number,
): System.ScaledPosition => {
    const viewport = viewer.getPageView(pageNumber - 1).viewport;

    return {
        boundingRect: viewportRectangleToScaled(boundingRect, viewport),
        rects: (rects || []).map((rect) => viewportRectangleToScaled(rect, viewport)),
    };
};

const viewportRectangleToScaled = (
    rect: System.ViewportRectangle,
    { width, height }: PageViewport,
): System.ScaledRectangle => ({
    originalViewport: {
        width,
        height,
    },
    ...rect,
});

const scaledRectangleToViewport = (
    scaled: System.ScaledRectangle,
    currentViewport: PageViewport,
): System.ViewportRectangle => {
    const heightRatio = currentViewport.height / scaled.originalViewport.height;
    const widthRatio = currentViewport.width / scaled.originalViewport.width;

    return {
        left: scaled.left * widthRatio,
        top: scaled.top * heightRatio,
        width: scaled.width * widthRatio,
        height: scaled.height * heightRatio,
    };
};

const getBoundingRect = (clientRects: System.ViewportRectangle[]): System.ViewportRectangle => {
    const xMin = clientRects.reduce((min, { left }) => Math.min(left, min), Infinity);
    const xMax = clientRects.reduce((max, { left, width }) => Math.max(left + width, max), -Infinity);
    const yMin = clientRects.reduce((min, { top }) => Math.min(top, min), Infinity);
    const yMax = clientRects.reduce((max, { top, height }) => Math.max(top + height, max), -Infinity);

    return {
        left: xMin,
        top: yMin,
        width: xMax - xMin,
        height: yMax - yMin,
    };
};

const getMinimalClientRects = (range: Range, pageElement: HTMLElement): System.ViewportRectangle[] => {
    const clientRects = Array.from(range.getClientRects());
    const offset = pageElement.getBoundingClientRect();

    const rects = clientRects.map((rect) => {
        return {
            top: rect.top - offset.top - VIEWER_BORDER_WIDTH,
            left: rect.left + pageElement.scrollLeft - offset.left - VIEWER_BORDER_WIDTH,
            width: rect.width,
            height: rect.height,
        };
    });

    const toRemove = new Set();

    rects.forEach((r1) => {
        rects.forEach((r2) => {
            if (r1 === r2 || toRemove.has(r1) || toRemove.has(r2) || !sameLine(r1, r2)) {
                return;
            }
            r1.left = Math.min(r1.left, r2.left);
            r1.top = Math.min(r1.top, r2.top);
            r1.width = Math.max(r2.left + r2.width - r1.left, r1.width);
            r1.height = Math.max(r2.top + r2.height - r1.top, r1.height);

            toRemove.add(r2);
        });
    });

    return rects.filter((rect) => !toRemove.has(rect));
};

const sameLine = (r1: System.ViewportRectangle, r2: System.ViewportRectangle, yMargin = 5) =>
    Math.abs(r1.top - r2.top) < yMargin && Math.abs(r1.height - r2.height) < yMargin;

const getPageFromElement = (target: HTMLElement) => {
    const node = target.closest('.page');

    if (!(node instanceof HTMLElement)) {
        return null;
    }

    const number = Number(node.dataset.pageNumber);

    return { node, number };
};

const getPageFromRange = (range: Range) => {
    const parentElement = range.startContainer.parentElement;

    if (!(parentElement instanceof HTMLElement)) {
        return;
    }

    return getPageFromElement(parentElement);
};

const findOrCreateContainerLayer = (container: HTMLElement, className: string) => {
    let layer = container.querySelector(`.${className}`);

    if (!layer) {
        layer = document.createElement('div');
        layer.className = className;
        container.appendChild(layer);
    }

    return layer;
};

export default {
    VIEWER_BORDER_WIDTH,
    getHighlightFromRange,
    getHighlightFromAreaSelection,
    findOrCreateHighlightLayer,
    getHighlightColor,
    scaledPositionToViewport,
    scaledPositionToViewportByPageView
};
