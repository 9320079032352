import React, { useEffect, useState } from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks'
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    Modal,
    DetailsList,
    Label,
    IColumn,
    SearchBox,
    SelectionMode,
    TooltipHost
} from '@fluentui/react'
import { IDemandExtra } from '../../models/ILocalDemand';
import { IExtra } from '../../models/IExtra';
import { IPosition } from '../../models/IPosition';
import { IPheType } from '../../models/IPheType';
import { StringService } from '../../Services/StringService';
import * as extraHelpers from '../../helpers/extra';
import { getBusinessUnit } from '../../Services/Global';

export const ExtrasModal: React.FC<{
    show: boolean
    dismissModal: any,
    isEditing: boolean,
    handleDismissExtras: any,
    existingExtras: IDemandExtra[],
    allExtras: IExtra[],
    currentPheType: IPheType | null,
    currentPosition: IPosition | null,
}> = ({ show, dismissModal, handleDismissExtras, existingExtras, allExtras, currentPheType, currentPosition }) => {
    const [isLoading, setIsLoading] = useState({ isActive: false, loadingMessage: '' });
    const [extras, setExtras] = useState<IExtra[]>([]);
    const [filteredExtras, setFilteredExtras] = useState<IExtra[]>([]);

    useEffect(() => {

        let result: IExtra[] = allExtras.filter(p => !p.disabled);

        if (currentPosition?.type === 'Position') {
            result = result.filter(x => extraHelpers.default.isApplicableForPosition(x, currentPheType!));
        }
        else if (currentPosition?.type === 'Order') {
            result = result.filter(x => extraHelpers.default.isApplicableForOrder(x));
        }
        setExtras(result);
        const filtered: IExtra[] = [];
        if (existingExtras !== undefined) {
            result.forEach((x) => {
                if (!existingExtras.find(item => item.id === x.id)) {
                    filtered.push(x);
                }
            });

            setFilteredExtras(filtered);
        }
        else {
            setFilteredExtras(result);
        }

    }, [allExtras, existingExtras, currentPosition, currentPheType]);

    // Use useId() to ensure that the IDs are unique on the page.
    // (It's also okay to use plain strings and manually ensure uniqueness.)
    const titleId = useId('title')


    const handleDismiss = (e: boolean) => {
        if (!isLoading.isActive) {
            dismissModal(e)
        }
    }

    const handleSearch = (text: string | undefined) => {
        if (text !== undefined && text !== '') {
            let currentExtras: any[] = [];
            extras?.forEach((x) => {
                if (x.description.toLowerCase().includes(text.toLowerCase()) || x.zBlock.toLowerCase().includes(text.toLowerCase())) {
                    currentExtras.push(x);
                }
            })


            if (currentExtras !== undefined) {
                setFilteredExtras(currentExtras);
            }
        }
        else {
            // if the search term is empty, fallback to original array
            setFilteredExtras(extras);
        }
    }

    const handleAddExtra = (item: any) => {
        handleDismissExtras(item);
        dismissModal();
    }

    const getColumns = (): IColumn[] => {
        if (getBusinessUnit() == 'GPHE') {
            return gpheColumns;
        } else {
            return wheColumns;
        }
    }

    const gpheColumns: IColumn[] = [
        {
            key: '1',
            name: 'Z-block',
            fieldName: 'zBlock',
            minWidth: 120,
            maxWidth: 50,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true
        },
        {
            key: '2',
            name: 'Description',
            fieldName: 'description',
            minWidth: 150,
            maxWidth: 500,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: renderDescription
        },
        {
            key: '3',
            name: 'Category',
            fieldName: 'category',
            minWidth: 150,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: IExtra) => {
                return StringService.getExtraCategory(item.category)
            },
        },
        {
            key: '4',
            name: 'Per',
            fieldName: 'per',
            minWidth: 125,
            maxWidth: 125,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true
        },
        {
            key: '5',
            name: 'Designation',
            fieldName: 'designation',
            minWidth: 50,
            maxWidth: 50,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: IExtra) => {
                return item.designation.map(x => x as string).join(', ')
            },
        }
    ]

    const wheColumns: IColumn[] = [
        {
            key: '1',
            name: 'Z-block',
            fieldName: 'zBlock',
            minWidth: 120,
            maxWidth: 120,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true
        },
        {
            key: '2',
            name: 'Description',
            fieldName: 'description',
            minWidth: 150,
            maxWidth: 500,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: renderDescription
        },
        {
            key: '3',
            name: 'Category',
            fieldName: 'category',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: IExtra) => {
                return StringService.getExtraCategory(item.category)
            },
        },
        {
            key: '4',
            name: 'Per',
            fieldName: 'per',
            minWidth: 75,
            maxWidth: 75,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true
        },
        {
            key: '5',
            name: 'Type',
            fieldName: 'type',
            minWidth: 250,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: IExtra) => {
                return item.heTypes.map(x => x as string).join(', ')
            },
        }
    ]

    function renderDescription(item: any, index?: number, column?: IColumn) {
        return (
            <TooltipHost style={{ width: '100%' }} key={`${item.id}`} content={item?.description} id={item.id}>
                <p>{item.description}</p>
            </TooltipHost>
        )
    }

    return (
        <div>
            {
                extras !== undefined ?
                    <Modal
                        titleAriaId={titleId}
                        isOpen={show}
                        onDismiss={() => handleDismiss(false)}
                        isBlocking={false}
                        containerClassName={contentStyles.container}
                        styles={{ root: { width: '100%' } }}
                    >
                        <div style={{ padding: 20, width: '100%' }}>
                            <Label style={{ fontSize: 18 }}>
                                Extras ({filteredExtras.length})
                            </Label>
                            <SearchBox placeholder='Search for extra...' onChange={(e, i) => { handleSearch(i) }} />
                            <div>
                                <DetailsList
                                    items={filteredExtras}
                                    columns={getColumns()}
                                    selectionMode={SelectionMode.none}
                                    onActiveItemChanged={handleAddExtra} />
                            </div>
                        </div>
                    </Modal>
                    :
                    <></>
            }
        </div>
    )
}


const theme = getTheme()
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '60%'
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
        minWidth: '800px',
    },
})
