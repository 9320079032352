import { IStackItemStyles, Label, Stack, useTheme } from '@fluentui/react'
import { IDropdownOption } from 'office-ui-fabric-react'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setBusinessUnit } from '../../Services/Global';

import wheImage from '../../assets/png/whe_image.png';
import gpheImage from '../../assets/png/gphe_image.png';

export const BusinessUnitSelectionView: React.FC<{}> = () => {

    let theme = useTheme();
    let navigate = useNavigate();
    const [availableBusinessUnits, setAvailableBusinessUnits] = useState<IDropdownOption[]>([]);

    const stackStyle: IStackItemStyles = {
        root: [
            {
                background: theme.palette.white,
                boxShadow: theme.effects.elevation4,
                transition: '150ms',
                paddingBottom: 25,
                margin: '0px 20px',
                borderRadius: 5,
                width: 300,
                selectors: {
                    ':hover': {
                        boxShadow: theme.effects.elevation16,
                        transition: '50ms',
                        cursor: 'pointer'
                    }
                }
            }
        ]
    }

    const handleBusinessUnitSelection = (businessUnit: string) => {
        setBusinessUnit(businessUnit);
        navigate('/');
        window.location.reload();
    }

    useEffect(() => {
        const businessUnits: IDropdownOption[] = [
            { key: 'GPHE', text: 'GPHE', data: { imageSrc: gpheImage } },
            { key: 'WHE', text: 'WHE', data: { imageSrc: wheImage } }
        ];

        setAvailableBusinessUnits(businessUnits);
    }, [])

    return (
        <Stack horizontal horizontalAlign='center' verticalAlign='center'
            style={{ background: theme.palette.neutralLight, boxShadow: theme.effects.elevation8, height: 'auto', width: 'auto', padding: 50, margin: '100px 20vw', borderRadius: 5 }}>
            <Stack grow>
                <Stack.Item align='center'>
                    <h1>Select your business unit to access Albot</h1>
                </Stack.Item>
                <Stack.Item align='center' style={{ marginTop: 30 }}>
                    <Stack horizontal>
                        {
                            availableBusinessUnits.map((b) => {
                                return (
                                    <Stack key={b.key} styles={stackStyle} onClick={() => handleBusinessUnitSelection(b.key as string)}>
                                        <Stack.Item align='center'>
                                            <Label style={{ padding: 20, fontSize: '1.2em', cursor: 'pointer', textAlign: 'center' }}>{b.text}</Label>
                                            <img src={b.data.imageSrc} style={{ height: 125 }} alt='Heat exchanger' />
                                        </Stack.Item>
                                    </Stack>
                                )
                            })
                        }
                    </Stack>
                </Stack.Item>
            </Stack>
        </Stack>
    )
}