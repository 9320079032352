import { IStackItemStyles, ITheme } from "@fluentui/react";


export const elevationStackStyles = (theme: ITheme, elevation: string = theme.effects.elevation4): IStackItemStyles => {
    const divStyles: IStackItemStyles = {
        root: [
            {
                boxShadow: elevation,
                padding: 15,
                margin: 15,
                borderRadius: 5
            }
        ]
    }

    return divStyles;
}