import { highlightHelpers } from './helpers';
import styled from 'styled-components';
import { color } from './helpers/styles';
import { Domain } from '../../../typings';

export const HighlightWrapper = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  height: 100%;
  background: gray;
`;

export const Selection = styled.div`
  position: absolute;
  border: 1px dashed #333;
  background: ${color.highlightSelection};
  mix-blend-mode: multiply;
`;

export const TextHighlightPart = styled.div<{ from: Domain.HighlightSource }>`
  cursor: pointer;
  position: absolute;
  background: ${({ from }) => highlightHelpers.getHighlightColor(from)};
`;

export const ColoredRectangle = styled.div<{ from: Domain.HighlightSource }>`
  position: absolute;
  cursor: pointer;
  border: 1px solid #565758;
  opacity: 1;
  mix-blend-mode: multiply;
  z-index: 5;
  background: ${({ from }) => highlightHelpers.getHighlightColor(from)};
`;
