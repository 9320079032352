import { IMsalContext, useMsal } from '@azure/msal-react';
import { IChoiceGroupOption, IDropdownOption, getTheme } from '@fluentui/react';
import { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import { IDocument } from '../../models/IDocument';
import { IPosition } from '../../models/IPosition';
import { ApiService } from '../../Services/ApiService';
import * as extraHelpers from '../../helpers/extra';
import './Demands.scss';
import { IDemandExtra, ILocalDemand } from '../../models/ILocalDemand';
import { IInteraction, ISentenceInfo } from '../../models/IInteraction';
import { IExtra } from '../../models/IExtra';
import { IGlobalDemand } from '../../models/IGlobalDemand';
import { IContent } from '../../models/IContent';
import { IPheType } from '../../models/IPheType';
import { DemandAction, IExtraPer } from '../../models/Enums';
import { System } from '../../typings';
import { DemandLanding } from './DemandLanding';
import { CustomerDemandForm } from './CustomerDemandForm';

export const DemandsPane: FC<{
    highlight: System.Highlight,
    dismissModal: () => void,
    globalDemands: IGlobalDemand[],
    projectId: string,
    document: IDocument,
    matches: Record<string, IContent>,
    refreshInteractions: any,
    positions: IPosition[] | undefined,
    pheTypes: IPheType[],
    filteredGlobalDemands?: IGlobalDemand[],
    onceSelectedAlwaysTrue?: boolean,
    toggleSelected: () => void,
    removeHighlight?: () => void
}> = ({
    highlight,
    dismissModal,
    globalDemands,
    projectId,
    document,
    matches,
    refreshInteractions,
    positions,
    pheTypes,
    filteredGlobalDemands,
    onceSelectedAlwaysTrue,
    toggleSelected,
    removeHighlight
}) => {

        const ctx: IMsalContext = useMsal()
        const apiService = useMemo(() => new ApiService(ctx), [ctx]);

        const [isLoading, setIsLoading] = useState(true);
        const [isBusy, setIsBusy] = useState(false);
        const [isCategorized, setIsCategorized] = useState(false);
        const [demandExist, setDemandExist] = useState(false);
        const [showCustomerDemands, setShowCustomerDemands] = useState(onceSelectedAlwaysTrue);

        const [paragraph, setParagraph] = useState('');
        const [sentence, setSentence] = useState('');
        const [clarification, setClarification] = useState('');
        const [notes, setNotes] = useState('');
        const [internalComment, setInternalComment] = useState('');
        const [action, setAction] = useState('accept');
        const [clauseNumber, setClauseNumber] = useState('');
        const [clarificationOverride, setClarificationOverride] = useState('');


        const [globalDemandsOptions, setGlobalDemandsOptions] = useState<IDropdownOption[]>();
        const [searchableGlobalDemandOptions, setSearchableGlobalDemandOptions] = useState<IDropdownOption[]>();
        const [selectedCustomerDemand, setSelectedCustomerDemand] = useState<IGlobalDemand>();
        const [extras, setExtras] = useState<IExtra[]>();
        const [localDemand, setLocalDemand] = useState<ILocalDemand>();
        const [globalDemandId, setGlobalDemandId] = useState('');
        const [currentPosition, setCurrentPosition] = useState<IPosition>();
        const [positionExtras, setPositionExtras] = useState<Record<string, IDemandExtra[]>>();
        const [existingDemands, setExistingDemands] = useState<ILocalDemand[]>();
        const [shouldAddToInteractions, setShouldAddToInteractions] = useState(false);


        const theme = getTheme();

        useEffect(() => {
            if (selectedCustomerDemand !== undefined) {
                setSelectedCustomerDemand(undefined);
                setShowCustomerDemands(false);
            }
            if (highlight !== undefined) {
                if (highlight.from !== 'interaction') {
                    setNotes('');
                    setClauseNumber('');
                    setClarificationOverride('');
                    setParagraph('');
                    setSentence('');
                    setLocalDemand(undefined);
                    setSelectedCustomerDemand(undefined);
                    setDemandExist(false);
                }
            }

            if (highlight?.text !== undefined) {
                setSentence(highlight.text);
            }

            let options: IDropdownOption[] = [];

            filteredGlobalDemands?.forEach((x => {
                options.push({ key: x.id!, text: x.text! });
            }))

            setGlobalDemandsOptions(options);

            let searchableOps: IDropdownOption[] = [];

            globalDemands.forEach(item => {
                searchableOps.push({ key: item.id, text: item.text });
            });

            setSearchableGlobalDemandOptions(searchableOps);

            if (document.category !== undefined &&
                (document.category === 'Position' ||
                    document.category === 'Order')) {
                setIsCategorized(true);
            }
            else {
                setIsCategorized(false);
            }

            if (positions !== undefined) {

                const pos = positions[0];

                setCurrentPosition(pos);

                // create empty arrays for all positions.
                let pextras: Record<string, IDemandExtra[]> = {}
                if (positions !== undefined) {
                    for (let i = 0; i < positions.length; i++) {
                        pextras[positions[i].id as string] = [];
                    }
                }

                setPositionExtras(pextras);
            }

            async function getExtras() {
                setIsLoading(true);
                try {
                    // Get values for dropdown
                    const response = await apiService.get('extras')
                    const extraResult: IExtra[] = await response.json();
                    setExtras(extraResult);

                    if (highlight?.from === 'interaction') {
                        setDemandExist(true);
                        const gldresponse = await apiService.get(`projects/${projectId}/demands`);
                        const demandsResponse: ILocalDemand[] = await gldresponse.json();
                        let posEx = positionExtras;
                        posEx = {};

                        const iaResponse = await apiService.get(`projects/${projectId}/interactions`);
                        const ia: IInteraction[] = await iaResponse.json();
                        const targetIa = ia.find(x => x.id === highlight.interactionId);

                        for (let i = 0; i < demandsResponse.length; i++) {

                            let interactionExist = false;
                            demandsResponse[i].interactionIds?.forEach((y) => {
                                if (y.includes(highlight.interactionId!)) {
                                    interactionExist = true;
                                }
                            })

                            if (targetIa !== undefined) {
                                setSentence(targetIa.meta.rephrasing);
                                setParagraph(targetIa.meta.paragraph);
                                setNotes(targetIa.comment)
                                setClauseNumber(targetIa.clauseNumber ?? '');
                                setClarificationOverride(targetIa.clarificationOverride ?? '')
                            }

                            if (interactionExist) {
                                const dem = demandsResponse[i];

                                positions?.forEach((pos) => {
                                    if (pos.id === undefined) return;

                                    dem.extras?.forEach((d) => {
                                        if (d.numUnitsByPosition[pos.id as string] !== undefined) {
                                            if (posEx![pos.id as string] === undefined) {
                                                posEx![pos.id!] = []
                                            }

                                            posEx![pos.id!].push(d);
                                        }
                                    })
                                })

                                setLocalDemand(dem);

                                if (dem.internalComment !== undefined) {
                                    setInternalComment(dem.internalComment)
                                }

                                if (dem.action !== undefined) {
                                    switch (dem.action) {
                                        case 'Accept':
                                            setAction('Accept');
                                            break;
                                        case 'Deviate':
                                            setAction('Deviate');
                                            break;
                                        case 'Information':
                                            setAction('Information');
                                            break;
                                        case 'Clarification':
                                            setAction('Clarification');
                                            break;                                               
                                        default:
                                            setAction('NotApplicable');
                                    }

                                }

                                const gdemand = globalDemands.find(x => x.id === dem.globalDemandId);

                                setSelectedCustomerDemand(gdemand);
                                if (gdemand !== undefined) {
                                    setGlobalDemandId(gdemand.id);
                                }
                                setClarification(dem.comment!);                                

                                // set all extras
                                let demExtras: IExtra[] = [];

                                dem.extras?.forEach(x => {
                                    let targetExtra = extraResult.find(y => y.id === x.id);
                                    if (targetExtra !== undefined) {
                                        demExtras.push(targetExtra);
                                    }
                                });

                                continue;
                            }
                        }

                        setPositionExtras(posEx);
                    }
                    setIsLoading(false);
                } finally {
                    setIsLoading(false);
                }
            }

            async function getExistingDemands() {
                const response = await apiService.get(`projects/${projectId}/demands`);
                const exDemands: ILocalDemand[] = await response.json();
                setExistingDemands(exDemands);
            }

            getExistingDemands();
            getExtras();

        }, [highlight, document.category]);

        const handleChange: any = (e: any) => {
            const target = e.currentTarget;
            switch (target.name) {
                case 'rephraseSentence':
                    setSentence(target.value);
                    break;
                case 'notes':
                    setNotes(target.value);
                    break;
                case 'clauseNumber':
                    setClauseNumber(target.value);
                    break;     
                case 'clarificationOverride':
                    setClarificationOverride(target.value);
                    break;                                
                case 'internalComment':
                    setNotes(target.value);
                    if (localDemand?.internalComment === undefined) {
                        let ld = localDemand;
                        if (ld === undefined) {
                            ld = { projectId: projectId }
                        }
                        if (ld !== undefined && ld.internalComment === undefined) {
                            ld.internalComment = '';
                        }
                        ld!.internalComment = target.value;
                        setLocalDemand(ld);
                    }
                    else {
                        let ld = localDemand;
                        ld.internalComment = target.value;
                        setLocalDemand(ld);
                    }
                    break;
                case 'paragraph':
                    setParagraph(target.value);
                    break;
            }
        }

        const populateExistingDemandData = (existingDemand: ILocalDemand, pos: IPosition) => {
            setShouldAddToInteractions(true);
            setSelectedCustomerDemand(globalDemands.find(x => x.id === existingDemand.globalDemandId));
            setLocalDemand(existingDemand);
            setClarification(existingDemand.comment ?? '');
            setCurrentPosition(pos);
            setGlobalDemandId(existingDemand.globalDemandId!);
            setAction(existingDemand.action!);
            setInternalComment(existingDemand.internalComment ?? '');
        }

        const handleDemandSelection = (e: any) => {
            const pos = currentPosition
            const existingDemand = existingDemands?.find(x => x.globalDemandId === e.key);
            if (existingDemand !== undefined && highlight.from === 'interaction') {
                if (existingDemand === undefined) return;

                populateExistingDemandData(existingDemand, pos!);

                let positionEx: Record<string, IDemandExtra[]> = {}
                if (existingDemand.extras !== undefined) {
                    for (const [key, value] of Object.entries(existingDemand.extras)) {
                        for (const [k, ex] of Object.entries(value.numUnitsByPosition)) {
                            if (positionEx[k] === undefined) {
                                positionEx[k] = []
                            }
                            positionEx[k] = [...positionEx[k], value];
                        }
                    }
                }

                setPositionExtras(Object.assign({}, positionEx));
            }
            else {

                if (existingDemand !== undefined) {
                    populateExistingDemandData(existingDemand, pos!);
                }

                const demand = globalDemands.find((x) => x.id === e.key);
                if (demand !== undefined) {
                    setSelectedCustomerDemand(demand);

                    if (demand.extraIds !== undefined && demand.extraIds.length > 0) {

                        // When a new demand is selected, reset the demands list for all positions on the document.
                        let pexs: Record<string, IDemandExtra[]> = {}

                        positions?.forEach((pos) => {
                            if (pos.id === undefined) return;

                            let positionExtras = pexs[pos.id as string];
                            if (positionExtras === undefined) {
                                positionExtras = []
                            }
                            const phe = pheTypes.find(p => p.id === pos.pheTypeId);

                            demand.extraIds.forEach((x) => {
                                const targetExtra = extras!.find(y => y.id === x);
                                if (targetExtra === undefined) return;

                                if (pos.type === 'Position' && phe !== undefined && extraHelpers.default.isApplicableForPosition(targetExtra, phe)) {
                                    positionExtras.push({ id: targetExtra!.id, isAddedNew: false, isActive: true, numUnitsByPosition: { [pos.id!]: extraHelpers.default.getNumberOf(pos, targetExtra.per as IExtraPer)! } });
                                }
                                else if (pos.type === 'Order' && extraHelpers.default.isApplicableForOrder(targetExtra)) {

                                    positionExtras.push({ id: targetExtra.id, isAddedNew: false, isActive: true, numUnitsByPosition: { [pos.id!]: extraHelpers.default.getNumberOf(pos, targetExtra.per as IExtraPer)! } });
                                }
                            })

                            pexs[pos.id as string] = positionExtras;
                        })

                        setPositionExtras(pexs);
                    }
                    else {
                        let pexs: Record<string, IDemandExtra[]> = {}
                        let posExtraList = pexs;

                        setPositionExtras(posExtraList);
                    }

                    if (existingDemand === undefined && demand.defaultClarification !== undefined) {
                        setClarification(demand.defaultClarification);
                    }
                    if (existingDemand === undefined && demand.defaultInternalComment !== undefined) {
                        setInternalComment(demand.defaultInternalComment);
                    }
                    if (demand.defaultAction !== undefined) {
                        setAction(demand.defaultAction);
                    }


                    setCurrentPosition(pos);
                    setGlobalDemandId(e.key);
                }
            }
        }

        async function handleSubmit() {
            setIsBusy(true);
            try {
                let match: ISentenceInfo | null = null;
                if (highlight.contentId !== undefined && matches[highlight.contentId] !== undefined) {
                    match = {
                        contentId: matches[highlight.contentId].id,
                        text: matches[highlight.contentId].text,
                        suggestedGlobalDemandIds: matches[highlight.contentId].cdTransformerPredictions?.map((d) => d.label) ?? []
                    }
                }

                if (!demandExist && shouldAddToInteractions === false) {
                    highlight.id = '';
                    const interaction: IInteraction = {
                        comment: notes,
                        highlight: highlight,
                        documentId: document.id,
                        clauseNumber: clauseNumber,
                        clarificationOverride: clarificationOverride,
                        meta: {
                            pageNumber: highlight.pageNumber,
                            paragraph: paragraph,
                            rephrasing: sentence,
                            match: match
                        }
                    }

                    // Before submitting the demand, create an interaction record in the database so the demand can be linked to it.
                    const interactionResult = await addInteraction(interaction);

                    let demand = localDemand;
                    if (demand === undefined) {
                        demand = {
                            projectId: projectId,
                            action: action as DemandAction,
                            comment: clarification,
                            internalComment: internalComment,
                            globalDemandId: globalDemandId,
                        }
                    }
                    if (demand?.interactionIds === undefined) {
                        demand!.interactionIds = []
                    }
                    demand!.interactionIds = [interactionResult.id];

                    if (demand === undefined) throw new Error('Demand is undefined');
                    demand.extras = [];
                    for (const [key, value] of Object.entries(positionExtras!)) {
                        demand.extras.push(...value);
                    }

                    await apiService.post(`projects/${projectId}/demands`, demand);

                }
                else {
                    let demand = localDemand!;

                    // if there is no interaction tied to the demand, create one.
                    if (shouldAddToInteractions) {

                        highlight.id = '';
                        const interaction: IInteraction = {
                            comment: notes,
                            highlight: highlight,
                            documentId: document.id,
                            clauseNumber: clauseNumber,
                            clarificationOverride: clarificationOverride,
                            meta: {
                                pageNumber: highlight.pageNumber,
                                paragraph: paragraph,
                                rephrasing: sentence,
                                match: match
                            }
                        }
                        const interactionResult = await addInteraction(interaction);
                        demand.interactionIds?.push(interactionResult.id);
                    }
                    else {
                        // Otherwise, we'll have to find the existing one and update the data on that instance.
                        const fetchInteractionResponse = await apiService.get(`projects/${projectId}/interactions`);
                        const fetchedInteractions: IInteraction[] = await fetchInteractionResponse.json();
                        const targetInteraction = fetchedInteractions.find(x => x.id === highlight.interactionId);

                        if (targetInteraction !== undefined) {
                            targetInteraction.comment = notes;
                            targetInteraction.meta.rephrasing = sentence;
                            targetInteraction.clauseNumber = clauseNumber;
                            targetInteraction.clarificationOverride = clarificationOverride;
                            await apiService.put(`projects/${projectId}/interactions/${targetInteraction.id}`, targetInteraction);
                        }
                    }

                    demand.extras = [];
                    for (const [key, value] of Object.entries(positionExtras!)) {
                        demand.extras.push(...value);
                    }

                    await apiService.put(`projects/${projectId}/demands/${demand?.id}`, demand);
                }
            } finally {
                setIsBusy(false)
                refreshInteractions();
                dismissModal();
            }


        }

        async function addInteraction(interaction: IInteraction) {
            const interactionResponse = await apiService.post(`projects/${projectId}/interactions`, interaction);
            if (document.status == 'Unopened' || document.status == 'Opened') {
                await apiService.put(`projects/${projectId}/documents/${document.id}/status/Doing`);
                document.status = 'Doing'
            }
            const interactionResult = await interactionResponse.json();
            return interactionResult
        }

        const handleCheckbox = (ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined, item?: IDemandExtra, posId?: string) => {
            if (posId === undefined || item === undefined || checked === undefined) return;

            let allPosExtras = positionExtras;
            let targetExtraList = allPosExtras![posId];
            let targetExtra = targetExtraList.find(x => x.id === item.id);


            if (targetExtra === undefined) return;
            if (targetExtraList === undefined) return;
            if (allPosExtras === undefined) return;

            positions?.forEach((pos) => {
                if (allPosExtras === undefined) return;
                let exs = allPosExtras[pos.id as string];
                if (exs === undefined) return;
                let i = exs.findIndex(x => x.id === item.id);
                let targetEx = exs.find(x => x.id === item.id)

                if (targetEx !== undefined) {
                    targetEx.isActive = checked;
                }

                if (targetEx !== undefined) {
                    exs.splice(i, 1, targetEx);
                }

                allPosExtras[pos.id as string] = exs;
            })

            setPositionExtras(allPosExtras);
        }

        const addDemand = () => {
            const demandExtras = positionExtras![currentPosition?.id as string];

            if (localDemand !== undefined) {
                let d = localDemand;
                d.action = action as DemandAction;
                d.comment = clarification;
                d.extras = demandExtras;
                d.globalDemandId = globalDemandId;
                d.internalComment = internalComment;

                setLocalDemand(d);
            }
            else {
                const demand: ILocalDemand = {
                    action: action as DemandAction,
                    comment: clarification,
                    extras: demandExtras,
                    globalDemandId: globalDemandId,
                    internalComment: internalComment,
                    projectId: projectId,
                };

                setLocalDemand(demand);
            }

            handleSubmit();
        }

        const handlePositionChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>, ex?: string) => {
            const value: IPosition | undefined = positions!.find((x) => x.id === option!.key);
            if (value === undefined) return
            setCurrentPosition(Object.assign({}, value));
        }

        const handleActionUpdate = (event: any, item: any) => {
            if (item === undefined) return;
            setAction(item.key)
        }

        const handleShowCustomerDemands = () => {
            toggleSelected();
            setShowCustomerDemands(!showCustomerDemands)
        }

        const handleDismissNewDemand = (demand: IGlobalDemand) => {
            globalDemands = [...globalDemands, ...[demand]]
            handleDemandSelection({ key: demand.id })
        }
        const removeExtra = (id: string) => {
            if (positionExtras === undefined) return;
            if (currentPosition === undefined) return;

            let currentExtras = positionExtras[currentPosition.id as string];
            let currentPositionExtras = positionExtras;

            if (currentExtras === undefined) {
                currentExtras = [];
            }
            const updatedExtras = currentExtras.filter(item => item.id !== id);
            currentPositionExtras[currentPosition.id as string] = updatedExtras;
            setPositionExtras(Object.assign({}, currentPositionExtras));
        }

        const deleteHighlight = async () => {
            await apiService.delete(`projects/${projectId}/interactions/${highlight.interactionId}`)
            refreshInteractions();
            dismissModal()
        }

        return (
            <div style={{ boxShadow: theme.effects.elevation4, height: 'auto', marginLeft: 10, marginTop: 20, maxWidth: '35vw', width: 'auto' }}>
                {
                    !showCustomerDemands && isLoading === false ?
                        // The starting view for creating a new demand from highlighted text in the document view.
                        <DemandLanding
                            removeHighlight={removeHighlight}
                            handleTextfieldChange={handleChange}
                            isLoading={isLoading}
                            isCategorized={isCategorized}
                            demandExist={demandExist}
                            isBusy={isBusy}
                            sentence={sentence}
                            paragraph={paragraph}
                            notes={notes}
                            clauseNumber={clauseNumber}
                            clarificationOverride={clarificationOverride}
                            selectedCustomerDemand={selectedCustomerDemand!}
                            handleShowCustomerDemands={handleShowCustomerDemands}
                            dismissModal={dismissModal}
                            deleteHighlight={deleteHighlight}
                            highlight={highlight}
                            handleSubmit={handleSubmit}
                        />
                        :
                        // The second step for when adding a new demand. Renders extras list and required fields to fill out.
                        <CustomerDemandForm
                            handleActionUpdate={handleActionUpdate}
                            handlePositionChange={handlePositionChange}
                            handleCheckbox={handleCheckbox}
                            removeExtra={removeExtra}
                            setClarification={setClarification}
                            addDemand={addDemand}
                            setInternalComment={setInternalComment}
                            setPositionExtras={setPositionExtras}
                            dismissModal={dismissModal}
                            handleDemandSelection={handleDemandSelection}
                            handleDismissNewDemand={handleDismissNewDemand}
                            setShowCustomerDemands={setShowCustomerDemands}
                            action={action}
                            currentPosition={currentPosition!}
                            positionExtras={positionExtras}
                            extras={extras!}
                            internalComment={internalComment}
                            clarification={clarification}
                            pheTypes={pheTypes}
                            globalDemandsOptions={globalDemandsOptions!}
                            selectedCustomerDemand={selectedCustomerDemand!}
                            positions={positions}
                            searchableGlobalDemandOptions={searchableGlobalDemandOptions!}
                            demandExist={demandExist}
                        />
                }
            </div >
        )
    }