import { IStackTokens, List, Stack, getTheme, IStackItemStyles, IconButton, TooltipHost } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { IPosition } from '../../models/IPosition';

export const PositionsList: React.FC<{ positions: IPosition[], refreshList: any, removePosition: any }> = ({ positions, refreshList, removePosition }) => {

    const theme = getTheme();

    const [positionList, setPositionList] = useState<IPosition[]>();

    useEffect(() => {

        setPositionList(positions);

    }, [positions])

    // Styles definition
    const stackItemStyles: IStackItemStyles = {
        root: {
            alignItems: 'center',
            display: 'flex',
            height: 40,
            marginTop: 5
        },
    };

    // Tokens definition
    const stackTokens: IStackTokens = {
        childrenGap: 5,
        padding: 10,
    };


    const handleDecrement = (item?: IPosition) => {

        if (item !== undefined && item.numberOf !== undefined && item.numberOf >= 0) {
            item.numberOf! -= 1;
        }

        refreshList(item);
    }

    const handleIncrement = (item?: IPosition) => {

        if (item !== undefined) {
            item.numberOf! += 1;
        }

        refreshList(item);
    }

    const handleDeletePosition = (item?: IPosition) => {
        removePosition(item);
    }

    const onRenderCell = (item: IPosition | undefined, index: number | undefined): JSX.Element => {
        return (
            <div data-is-focusable={true}>
                {/* <Image className={classNames.itemImage} src={item.thumbnail} width={50} height={50} imageFit={ImageFit.cover} /> */}

                <div className='position-list-card' style={{ boxShadow: theme.effects.elevation8, marginTop: 10 }}>

                    <Stack horizontal horizontalAlign={'space-between'} tokens={stackTokens}>
                        <Stack.Item disableShrink styles={stackItemStyles}>
                            {item?.name}
                        </Stack.Item>
                        <Stack.Item disableShrink styles={stackItemStyles}>
                            {item?.type}
                        </Stack.Item>
                        <Stack.Item disableShrink styles={stackItemStyles}>

                        </Stack.Item>
                        <Stack.Item disableShrink styles={stackItemStyles}>
                            <IconButton style={{ marginRight: 10 }} iconProps={{ iconName: 'Remove' }} aria-label="Remove" onClick={() => handleDecrement(item)} />
                            {item?.numberOf}
                            <IconButton style={{ marginLeft: 10 }} iconProps={{ iconName: 'Add' }} aria-label="Add" onClick={() => handleIncrement(item)} />
                            <TooltipHost content={<p>Positions that haven't been referenced in a local demand can be deleted.</p>}>
                                <IconButton iconProps={{ iconName: 'Delete' }} aria-label="Delete" onClick={() => handleDeletePosition(item)} />
                            </TooltipHost>
                        </Stack.Item>
                    </Stack>

                </div>
            </div>
        );
    };

    return (
        <div>
            <List style={{ marginTop: 40 }} items={positionList} onRenderCell={onRenderCell} />
        </div>
    )
}