import { IMsalContext, useMsal } from '@azure/msal-react';
import { DetailsList, SelectionMode, IDropdownOption, getTheme, Stack, Label, TextField, SearchBox, ActionButton, DefaultButton, PrimaryButton, Spinner, SpinnerSize, Link } from '@fluentui/react';
import { FC, useEffect, useMemo, useState } from 'react';
import { IDocument } from '../../models/IDocument';
import { IPosition } from '../../models/IPosition';
import { ApiService } from '../../Services/ApiService';
import './Demands.scss';
import { ILocalDemand } from '../../models/ILocalDemand';
import { IInteraction, ISentenceInfo } from '../../models/IInteraction';
import { IGlobalDemand } from '../../models/IGlobalDemand';
import { IContent } from '../../models/IContent';
import { System } from '../../typings';
import { hasPermission, Actions } from '../../Services/Permissions';
import { NewGlobalDemandModal } from '../shared/NewGlobalDemandModal';

export const TrainingPane: FC<{
    highlight: System.Highlight,
    dismissModal: () => void,
    globalDemands: IGlobalDemand[],
    projectId: string,
    document: IDocument,
    matches: Record<string, IContent>,
    refreshInteractions: any,
    filteredGlobalDemands?: IGlobalDemand[],
    onceSelectedAlwaysTrue?: boolean,
    handleDismissNewDemand: () => void
}> = ({
    highlight,
    dismissModal,
    globalDemands,
    projectId,
    document,
    matches,
    refreshInteractions,
    filteredGlobalDemands,
    onceSelectedAlwaysTrue,
    handleDismissNewDemand
}) => {

        const ctx: IMsalContext = useMsal()
        const apiService = useMemo(() => new ApiService(ctx), [ctx]);

        const [isLoading, setIsLoading] = useState(true);
        const [isBusy, setIsBusy] = useState(false);
        const [demandExist, setDemandExist] = useState(false);
        const [showCustomerDemands, setShowCustomerDemands] = useState(onceSelectedAlwaysTrue);
        const [showNewDemandModal, setShowNewDemandModal] = useState(false);

        const [paragraph, setParagraph] = useState('');
        const [sentence, setSentence] = useState('');
        const [notes, setNotes] = useState('');

        const [globalDemandsOptions, setGlobalDemandsOptions] = useState<IDropdownOption[]>();
        const [searchableGlobalDemandOptions, setSearchableGlobalDemandOptions] = useState<IDropdownOption[]>();
        const [selectedCustomerDemand, setSelectedCustomerDemand] = useState<IGlobalDemand>();
        const [localDemand, setLocalDemand] = useState<ILocalDemand>();
        const [globalDemandId, setGlobalDemandId] = useState('');
        const [existingDemands, setExistingDemands] = useState<ILocalDemand[]>();
        const [shouldAddToInteractions, setShouldAddToInteractions] = useState(false);

        const [searchTerm, setSearchTerm] = useState('');
        const [filteredOptions, setFilteredOptions] = useState<IDropdownOption[]>();

        const theme = getTheme();

        useEffect(() => {
            if (selectedCustomerDemand !== undefined) {
                setSelectedCustomerDemand(undefined);
                setShowCustomerDemands(false);
            }
            if (highlight !== undefined) {
                if (highlight.from !== 'interaction') {
                    setNotes('');
                    setParagraph('');
                    setSentence('');
                    setLocalDemand(undefined);
                    setSelectedCustomerDemand(undefined);
                    setDemandExist(false);
                }
            }

            if (highlight?.text !== undefined) {
                setSentence(highlight.text);
            }

            let options: IDropdownOption[] = [];

            filteredGlobalDemands?.forEach((x => {
                options.push({ key: x.id!, text: x.text! });
            }))

            setGlobalDemandsOptions(options);

            let searchableOps: IDropdownOption[] = [];

            globalDemands.forEach(item => {
                searchableOps.push({ key: item.id, text: item.text });
            });

            setSearchableGlobalDemandOptions(searchableOps);

            async function getExisting() {
                setIsLoading(true);
                try {
                    if (highlight?.from === 'interaction') {
                        setDemandExist(true);
                        const gldresponse = await apiService.get(`projects/${projectId}/demands`);
                        const demandsResponse: ILocalDemand[] = await gldresponse.json();

                        const iaResponse = await apiService.get(`projects/${projectId}/interactions`);
                        const ia: IInteraction[] = await iaResponse.json();
                        const targetIa = ia.find(x => x.id === highlight.id);

                        for (let i = 0; i < demandsResponse.length; i++) {

                            let interactionExist = false;
                            demandsResponse[i].interactionIds?.forEach((y) => {
                                if (y.includes(highlight.id)) {
                                    interactionExist = true;
                                }
                            })

                            if (targetIa !== undefined) {
                                setSentence(targetIa.meta.rephrasing);
                                setParagraph(targetIa.meta.paragraph);
                                setNotes(targetIa.comment)
                            }

                            if (interactionExist) {
                                const dem = demandsResponse[i];

                                setLocalDemand(dem);

                                const gdemand = globalDemands.find(x => x.id === dem.globalDemandId);

                                setSelectedCustomerDemand(gdemand);
                                if (gdemand !== undefined) {
                                    setGlobalDemandId(gdemand.id);
                                }
                                continue;
                            }
                        }
                    }
                    setIsLoading(false);
                } finally {
                    setIsLoading(false);
                }
            }


            getExistingDemands();
            getExisting();
        }, [highlight, document.category]);

        async function getExistingDemands() {
            const response = await apiService.get(`projects/${projectId}/demands`);
            const exDemands: ILocalDemand[] = await response.json();
            setExistingDemands(exDemands);
        }

        const populateExistingDemandData = (existingDemand: ILocalDemand) => {
            setShouldAddToInteractions(true);
            setSelectedCustomerDemand(globalDemands.find(x => x.id === existingDemand.globalDemandId));
            setLocalDemand(existingDemand);
            setGlobalDemandId(existingDemand.globalDemandId!);
        }

        const handleDemandSelection = (e: any) => {
            const existingDemand = existingDemands?.find(x => x.globalDemandId === e.key);
            if (existingDemand !== undefined) {
                populateExistingDemandData(existingDemand);
            }

            const demand = globalDemands.find((x) => x.id === e.key);
            if (demand !== undefined) {
                setSelectedCustomerDemand(demand);
                setGlobalDemandId(e.key);
            }
            setSearchTerm('');
        }

        async function handleSubmit() {
            if (!globalDemandId) {
                throw new Error("No demand chosen");
            }
            setIsBusy(true);
            try {
                let match: ISentenceInfo | null = null;
                if (highlight.contentId !== undefined && matches[highlight.contentId] !== undefined) {
                    match = {
                        contentId: matches[highlight.contentId].id,
                        text: matches[highlight.contentId].text,
                        suggestedGlobalDemandIds: matches[highlight.contentId].cdTransformerPredictions?.map((d) => d.label) ?? []
                    }
                }

                if (!demandExist && shouldAddToInteractions === false) {
                    highlight.id = '';
                    const interaction: IInteraction = {
                        comment: notes,
                        highlight: highlight,
                        documentId: document.id,
                        meta: {
                            pageNumber: highlight.pageNumber,
                            paragraph: paragraph,
                            rephrasing: sentence,
                            match: match
                        }
                    }

                    // Before submitting the demand, create an interaction record in the database so the demand can be linked to it.
                    const interactionResult = await addInteraction(interaction);

                    let demand = localDemand;
                    if (demand === undefined) {
                        demand = {
                            projectId: projectId,
                            globalDemandId: globalDemandId
                        }
                    }
                    if (demand?.interactionIds === undefined) {
                        demand!.interactionIds = []
                    }
                    demand!.interactionIds = [interactionResult.id];

                    if (demand === undefined) throw new Error('Demand is undefined');
                    await apiService.post(`projects/${projectId}/demands`, demand);
                }
                else {
                    let demand = localDemand!;

                    // if there is no interaction tied to the demand, create one.
                    if (shouldAddToInteractions) {
                        highlight.id = '';
                        const interaction: IInteraction = {
                            comment: notes,
                            highlight: highlight,
                            documentId: document.id,
                            meta: {
                                pageNumber: highlight.pageNumber,
                                paragraph: paragraph,
                                rephrasing: sentence,
                                match: match
                            }
                        }
                        const interactionResult = await addInteraction(interaction);
                        demand.interactionIds?.push(interactionResult.id);
                    }
                    else {
                        // Otherwise, we'll have to find the existing one and update the data on that instance.
                        const fetchInteractionResponse = await apiService.get(`projects/${projectId}/interactions`);
                        const fetchedInteractions: IInteraction[] = await fetchInteractionResponse.json();
                        const targetInteraction = fetchedInteractions.find(x => x.id === highlight.interactionId);

                        if (targetInteraction !== undefined) {
                            targetInteraction.comment = notes;
                            targetInteraction.meta.rephrasing = sentence;
                            await apiService.put(`projects/${projectId}/interactions/${targetInteraction.id}`, targetInteraction);
                        }
                    }

                    demand.extras = [];
                    await apiService.put(`projects/${projectId}/demands/${demand?.id}`, demand);
                }
            } finally {
                setIsBusy(false)
                refreshInteractions();
                dismissModal();
            }
        }

        async function addInteraction(interaction: IInteraction) {
            const interactionResponse = await apiService.post(`projects/${projectId}/interactions`, interaction);
            if (document.status == 'Unopened' || document.status == 'Opened') {
                await apiService.put(`projects/${projectId}/documents/${document.id}/status/Doing`);
                document.status = 'Doing'
            }
            const interactionResult = await interactionResponse.json();
            return interactionResult
        }

        const deleteHighlight = async () => {
            await apiService.delete(`projects/${projectId}/interactions/${highlight.id}`)
            refreshInteractions();
            dismissModal()
        }

        const handleSearch = (text: string | undefined) => {
            if (text !== undefined) {
                setSearchTerm(text);
                let ops: IDropdownOption[] = [];

                searchableGlobalDemandOptions?.forEach((x) => {
                    if (x.text.toLowerCase().includes(text.toLowerCase())) {
                        ops.push(x);
                    }
                })
                if (ops !== undefined) {
                    setFilteredOptions(ops);
                }
            }
        }

        const dismissNewDemandModal = async (demand: IGlobalDemand) => {
            let currentSearchableDemands = searchableGlobalDemandOptions;
            currentSearchableDemands?.push({ key: demand.id, text: demand.text });
            setGlobalDemandId(demand.id!);
            setSearchableGlobalDemandOptions(currentSearchableDemands);
            setSelectedCustomerDemand(demand);
        }

        return (
            <div style={{ boxShadow: theme.effects.elevation4, height: 'auto', marginLeft: 10, marginTop: 20, maxWidth: '35vw', width: 'auto', padding: 20 }}>
                <Label>NOTE: When adding an entry for training, make sure the selected text is at least three words long, and is at most one sentence long.</Label>
                <Label>Note that the document must be annotated completely, any text that is not marked and linked to a demand will be treated by the model as text that is not relevant at all. Also, if there are multiple sentences / sections of text in the document that describe the same demand, all of these must be marked and linked.</Label>
                <Label>Make sure to mark the document as Done after it has been fully annotated, as only these documents will be used for the training.</Label>
                {
                    !showCustomerDemands && isLoading === false &&
                    <>
                        <Stack>

                            <Stack.Item>

                                <Label style={{ fontSize: 18 }} >
                                    Customer demand
                                </Label>
                                <TextField value={selectedCustomerDemand?.text} disabled />
                                <Stack.Item style={{ marginTop: 10, width: 350 }}>
                                    <Stack horizontal style={{ width: 425 }}>
                                        <SearchBox placeholder='Search for customer demand' onChange={(e, i) => { handleSearch(i) }} />
                                    </Stack>
                                    {
                                        hasPermission(Actions.DemandsCreate) ?
                                            <Link onClick={() => setShowNewDemandModal(true)} style={{ marginLeft: 10 }}>
                                                + Add new
                                            </Link>
                                            :
                                            <></>
                                    }
                                    {
                                        showNewDemandModal === true ?
                                            <NewGlobalDemandModal
                                                dismissModal={() => setShowNewDemandModal(false)}
                                                show={showNewDemandModal}
                                                handleDismissModal={(demand: IGlobalDemand) => dismissNewDemandModal(demand)} />
                                            :
                                            <></>
                                    }
                                </Stack.Item>
                            </Stack.Item>

                            <Stack.Item>
                                {
                                    searchTerm !== '' && (filteredOptions !== undefined && filteredOptions.length > 0) ?
                                        <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
                                            <DetailsList
                                                columns={[{
                                                    key: '1',
                                                    name: 'Demand',
                                                    fieldName: 'text',
                                                    minWidth: 150,
                                                    maxWidth: 300,
                                                    isRowHeader: true,
                                                    isResizable: false,
                                                    isSorted: false,
                                                    isSortedDescending: false,
                                                    data: 'string',
                                                    isPadded: true
                                                }]}
                                                items={filteredOptions}
                                                compact
                                                selectionMode={SelectionMode.none}
                                                onActiveItemChanged={handleDemandSelection}
                                            />
                                        </div>
                                        :
                                        <></>
                                }
                            </Stack.Item>

                        </Stack>
                        <Stack style={{ margin: '30px', float: 'right' }}>
                            <Stack.Item align='end'>
                                <ActionButton disabled={isBusy || highlight?.from !== 'interaction'} iconProps={{ iconName: 'Delete' }} text='Remove highlight' style={{ marginRight: 10 }} onClick={deleteHighlight}>Delete highlight</ActionButton>
                                <DefaultButton disabled={isBusy} iconProps={{ iconName: 'Cancel' }} text='Cancel' style={{ marginRight: 10 }} onClick={() => dismissModal()} />
                                <PrimaryButton disabled={isBusy || isLoading || selectedCustomerDemand === undefined} iconProps={demandExist === true ? { iconName: 'Save' } : { iconName: 'CheckMark' }} text={demandExist === true ? 'Save' : 'Add'} onClick={handleSubmit} />
                                {
                                    isBusy &&
                                    <Spinner label='Saving...' size={SpinnerSize.large} style={{ padding: 50 }} />
                                }
                            </Stack.Item>
                        </Stack>
                    </>
                }
            </div >
        )
    }