import React, { useState } from 'react';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { useId } from '@fluentui/react-hooks'
import { ApiService } from '../../Services/ApiService';
import {
    Modal,
    Label,
    DefaultButton,
    PrimaryButton,
    TextField
} from '@fluentui/react'
import { IGlobalDemand } from '../../models/IGlobalDemand';

export const NewGlobalDemandModal: React.FC<{
    show: boolean
    dismissModal: any,
    handleDismissModal: any,
}> = ({ show, dismissModal, handleDismissModal }) => {
    const ctx: IMsalContext = useMsal()
    const apiService = new ApiService(ctx)
    const [demandName, setDemandName] = useState('');

    const handleDismiss = (e: boolean) => {
        dismissModal(e)
    }

    const handleAddDemand = async (item: any) => {
        let demand: IGlobalDemand = {
            id: '',
            text: demandName,
            defaultAction: 'Accept',
            extraIds: []
        }
        const response = await apiService.post(`globalDemands`, demand)
        demand = await response.json();
        handleDismissModal(demand);
        handleDismiss(true);
    }

    const titleId = useId('title')

    return (
        <Modal
            titleAriaId={titleId}
            isOpen={show}
            onDismiss={() => handleDismiss(false)}
            isBlocking={false}
        >
            <div style={{ padding: 20, width: 500 }}>
                <Label style={{ fontSize: 18 }}>
                    Add new Global Demand
                </Label>
                <Label required htmlFor='demandName'>
                  Customer demand
                </Label>
                <TextField
                  id='demandName'
                  name='demandName'
                  value={demandName}
                  onChange={(e) => setDemandName(e.currentTarget.value)}
                />
                <DefaultButton iconProps={{ iconName: 'Cancel' }} text='Cancel' style={{ float: 'right', margin: 10 }} onClick={() => dismissModal()} />
                <PrimaryButton iconProps={{ iconName: 'Save' }} text='Create' style={{ float: 'right', margin: 10 }} onClick={handleAddDemand} />
            </div>
        </Modal>
    )
}
