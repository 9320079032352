import styled from 'styled-components';

export const DocumentWrapper = styled.div`
  height: 80vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-bottom: 2px solid gray;
`;

export const PreviewWrapper = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 2px solid gray;
  padding: -30px
`;