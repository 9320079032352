import { FC } from 'react';
import { ActionButton, DefaultButton, getTheme, IStackItemStyles, IStackTokens, Label, mergeStyles, PrimaryButton, Shimmer, Spinner, SpinnerSize, Stack, TextField, ThemeProvider } from "@fluentui/react"
import { IGlobalDemand } from '../../models/IGlobalDemand';
import { System } from '../../typings';

export const DemandLanding: FC<{
    handleTextfieldChange: any,
    isLoading: boolean,
    isCategorized: boolean,
    demandExist: boolean,
    isBusy: boolean,
    sentence: string,
    paragraph: string,
    notes: string,
    clauseNumber: string,
    clarificationOverride: string,
    selectedCustomerDemand: IGlobalDemand,
    handleShowCustomerDemands: any,
    dismissModal: any,
    deleteHighlight: any,
    highlight: System.Highlight,
    handleSubmit: () => Promise<void>,
    removeHighlight?: () => void
}> = ({
    handleTextfieldChange,
    isLoading,
    isCategorized,
    demandExist,
    isBusy,
    sentence,
    paragraph,
    notes,
    clauseNumber,
    clarificationOverride,
    selectedCustomerDemand,
    handleShowCustomerDemands,
    dismissModal,
    deleteHighlight,
    highlight,
    handleSubmit,
    removeHighlight
}) => {

        const theme = getTheme();

        const stackItemStyles: IStackItemStyles = {
            root: {
                textAlign: 'Left'
            },
        };
        // Tokens definition
        const stackTokens: IStackTokens = {
            childrenGap: 30,
            padding: 10
        };

        const shimmerWrapperClass = mergeStyles({
            padding: 2,
            width: '20vw',
            selectors: {
                '& > .ms-Shimmer-container': {
                    margin: '10px 0',
                },
            },
        });


        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
                <Stack styles={stackItemStyles} tokens={stackTokens}>
                    {
                        highlight && highlight.from === 'suggestion' &&
                        <Stack.Item>
                            <ActionButton iconProps={{ iconName: 'Delete' }} text='Remove Albot suggestion' style={{ marginRight: 10 }} onClick={removeHighlight && removeHighlight} />
                        </Stack.Item>
                    }
                    <Stack horizontal tokens={stackTokens}>
                        <Stack.Item>
                            <Label style={{ fontSize: 18 }} htmlFor="rephraseSentence">
                                Rephrase sentence
                            </Label>
                            <TextField
                                id="rephraseSentence-input"
                                name="rephraseSentence"
                                onChange={handleTextfieldChange}
                                defaultValue={sentence}
                                value={sentence}
                                multiline
                                style={{ width: '20vw', minWidth: 200, height: 100 }}
                            />
                        </Stack.Item>
                        {/* <Stack.Item>
                            <Label style={{ fontSize: 18, alignContent: 'left' }} htmlFor="paragraph">
                                §
                            </Label>
                            <TextField
                                id="paragraph-input"
                                name="paragraph"
                                onChange={handleTextfieldChange}
                                value={paragraph}
                            />
                        </Stack.Item> */}

                    </Stack>
                    <Stack tokens={stackTokens}>
                        <Stack.Item>
                            <Label style={{ fontSize: 18, alignContent: 'left' }} htmlFor="paragraph">
                                Notes (not exported to Clarification List)
                            </Label>
                            {
                                isLoading === false ?
                                    <>
                                        <TextField
                                            id="notes-input"
                                            name="notes"
                                            onChange={handleTextfieldChange}
                                            value={notes}
                                            multiline
                                            style={{ width: '20vw', minWidth: 200 }}
                                        />
                                    </>
                                    :
                                    <ThemeProvider className={shimmerWrapperClass}>
                                        <Shimmer width='25%' />
                                        <Shimmer width="50%" height='75px' />
                                        <Shimmer width="30%" />
                                    </ThemeProvider>

                            }
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={stackTokens}>
                        <Stack.Item>
                            <Label style={{ fontSize: 18, alignContent: 'left' }} htmlFor="paragraph">
                                Clause
                            </Label>
                            {
                                isLoading === false ?
                                    <>
                                        <TextField
                                            id="clause-input"
                                            name="clauseNumber"
                                            onChange={handleTextfieldChange}
                                            value={clauseNumber}
                                            style={{ width: '20vw', minWidth: 200 }}
                                        />
                                    </>
                                    :
                                    <ThemeProvider className={shimmerWrapperClass}>
                                        <Shimmer width='25%' />
                                        <Shimmer width="50%" height='75px' />
                                        <Shimmer width="30%" />
                                    </ThemeProvider>

                            }
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={stackTokens}>
                        <Stack.Item>
                            <Label style={{ fontSize: 18, alignContent: 'left' }} htmlFor="paragraph">
                                Clarification Override
                            </Label>
                            {
                                isLoading === false ?
                                    <>
                                        <TextField
                                            id="clarification-override-input"
                                            name="clarificationOverride"
                                            onChange={handleTextfieldChange}
                                            value={clarificationOverride}
                                            multiline
                                            style={{ width: '20vw', minWidth: 200 }}
                                        />
                                    </>
                                    :
                                    <ThemeProvider className={shimmerWrapperClass}>
                                        <Shimmer width='25%' />
                                        <Shimmer width="50%" height='75px' />
                                        <Shimmer width="30%" />
                                    </ThemeProvider>

                            }
                        </Stack.Item>
                    </Stack>
                    <Stack tokens={stackTokens}>
                        {
                            isLoading === false ?
                                <Stack.Item>
                                    {
                                        isCategorized === false ?
                                            <p style={{ color: 'red', fontWeight: 600 }}> You need to categorize the document before adding demands to it.</p>
                                            :
                                            <DefaultButton iconProps={{ iconName: demandExist && selectedCustomerDemand ? 'Edit' : 'Add' }} text={demandExist && selectedCustomerDemand !== undefined ? selectedCustomerDemand.text : 'Customer Demand'} onClick={handleShowCustomerDemands} />
                                    }
                                </Stack.Item>
                                :
                                <Spinner label='Loading demands data...' />
                        }
                    </Stack>
                </Stack>
                <Stack style={{ margin: '30px', float: 'right' }}>
                    <Stack.Item align='end'>
                        <ActionButton disabled={isBusy || highlight?.from !== 'interaction'} iconProps={{ iconName: 'Delete' }} text='Remove highlight' style={{ marginRight: 10 }} onClick={deleteHighlight}>Delete highlight</ActionButton>
                        <DefaultButton disabled={isBusy} iconProps={{ iconName: 'Cancel' }} text='Cancel' style={{ marginRight: 10 }} onClick={() => dismissModal()} />
                        <PrimaryButton disabled={isBusy || isLoading} iconProps={demandExist === true ? { iconName: 'Save' } : { iconName: 'CheckMark' }} text={demandExist === true ? 'Save' : 'Add'} onClick={handleSubmit} />
                        {
                            isBusy &&
                            <Spinner label='Saving...' size={SpinnerSize.large} style={{ padding: 50 }} />
                        }
                    </Stack.Item>
                </Stack>
            </div>
        )
    }