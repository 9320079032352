import { DocumentCategory, DocumentStatus, DemandAction, ProjectStatus } from "../models/Enums";

export class StringService {

  public static getDocumentCategory(category?: string): string {
    switch (category as DocumentCategory) {
      case "NotApplicable": return "N/A"
      default: return category ?? "";
    }
  }

  public static getDocumentStatus(status?: string): string {
    switch (status as DocumentStatus) {
      case "Failed": return "Analyzing failed"
      default: return status ?? "";
    }
  }

  public static geProjectStatus(status?: string): string {
    switch (status as ProjectStatus) {
      default: return status ?? "";
    }
  }

  public static getExtraCategory(category?: string): string {
    switch (category) {
      case "DocumentsAndRecords": return "Documents & records"
      case "EngineerHours": return "Engineer hours"
      case "MaterialCertificates": return "Material certificates"
      case "MaterialRequirements": return "Material requirements"
      case "PaintSystem": return "Paint system"
      case "PVCodes": return "PV codes"
      case "SurfaceTreatment": return "Surface treatment"
      default: return category ?? "";
    }
  }

  public static getDemandAction(action?: string): string {
    switch (action as DemandAction) {
      case "NotApplicable": return "N/A"
      case "Deviate" : return "Deviation"
      default: return action ?? "";
    }
  }
}
