import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { AuthConfig } from "./AuthConfig";
import { MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { PrimaryButton, Stack } from '@fluentui/react';
const msalInstance = new PublicClientApplication(AuthConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <App />
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <Stack>
          <Stack.Item grow={4}>
            <h4>You need to be signed in to access this content.</h4>
          </Stack.Item>
          <Stack.Item grow={3}>
            <PrimaryButton
              text="Log in"
              onClick={() => {return }}
            />
          </Stack.Item>
        </Stack>
      </UnauthenticatedTemplate>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// window.onunhandledrejection = function(e) {
//   alert(e.reason);
// }