import { colorHelpers } from '.';

export const spacing = {
    xxsmall: '1px',
    xsmall: '2px',
    small: '4px',
    medium: '8px',
    large: '16px',
    xlarge: '32px',
    xxlarge: '64px',
    xxxlarge: '128px',
};

export const size = {
    bannerHeight: 82,
    smallContent: 600,
    mediumContent: 1000,
    largeContent: 1200,

    mainContentWidth: 1000,
    mainContentBigWidth: 1200,
    secondaryContentWidth: 400,
    qnaBannerHeight: 36,
    qnaContentHeight: 200,
};

export const zindex = {
    searchResults: 900,
    overlay: 1000,
    merging: 1005,
    modal: 1010,
    notifications: 1020,
};

const black = '#000000';
const white = '#FFFFFF';

export const color = {
    white,
    black,

    primary: '#42487F',
    primaryLight: '#6167A0',
    primaryDark: '#272B54',

    menuItemActive: colorHelpers.getOpacity(0.72, '#272B54'),

    text: '#201F1F',
    textLight: '#939393',
    primaryText: '#FFFFFF',
    accentText: '#FFFFFF',

    accentRed: '#EA6767',
    accentRedLight: '#FAA4A4',
    accentRedDark: '#D65353',

    accentBlue: '#9CCFE9',
    accentBlueLight: '#B5DEF3',
    accentBlueDark: '#6CB9E0',

    accentGreen: '#88BC5D',
    accentGreenLight: '#B3DE90',
    accentGreenDark: '#6A9943',

    accentYellow: '#E6AF67',
    accentYellowLight: '#FFD59D',
    accentYellowDark: '#CE9954',

    accentGray: '#F1F4F7',
    accentGrayLight: '#FBFBFB',
    accentGrayDark: '#D7DCE1',

    backgroundPrimary: '#FFFFFF',
    backgroundSecondary: '#E6E6F0',

    highlightInteraction: 'rgba(136, 188, 93, 0.6)',
    highlightSelection: 'rgba(156, 207, 233, 0.6)',
    highlightSuggestion: 'rgba(243, 224, 41, 0.6)',

    boxShadow: colorHelpers.getOpacity(0.5, black),
    border: '#979797',
};

export const border = {
    radiusSmall: '2px',
    radiusCircle: '50%',
    discreet: `${spacing.xxsmall} solid ${color.accentGrayDark}`,
    distinct: `${spacing.xsmall} solid ${color.backgroundSecondary}`,
};

export const shadow = {
    card: `${spacing.xxsmall} ${spacing.xsmall} ${spacing.small} ${color.boxShadow}`,
    qnaInset: `inset 0 0 ${spacing.large} ${spacing.medium} ${color.boxShadow}`,
};

export const images = {
    notificationError: '/images/notification-error.png',
    notificationSuccess: '/images/notification-success.png',
    logo: '/images/logo.png',
};
