import React, { useEffect, useState } from 'react';
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  IIconProps,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
} from '@fluentui/react'
import {
  IconButton,
  IButtonStyles,
  DefaultButton,
} from '@fluentui/react/lib/Button'
import { IMsalContext, useMsal } from '@azure/msal-react'
import { ApiService } from '../../Services/ApiService'
import { StringService } from '../../Services/StringService'
import { IPosition } from '../../models/IPosition'
import { IDocument, DocumentCategoryDto } from '../../models/IDocument'
import { DocumentCategory } from '../../models/Enums';

export const SelectCategoryModal: React.FC<{
  show: boolean
  dismissModal: any
  document: IDocument
}> = ({ show, dismissModal, document }) => {
  const ctx: IMsalContext = useMsal()
  const apiService = new ApiService(ctx)
  const [positions, setPositions] = useState<IPosition[]>();
  const [showPositions, setShowPositions] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedPosition, setSelectedPosition] = useState<string>();
  const [positionOptions, setPositionOptions] = useState<IDropdownOption[]>();
  const [categoryOptions, setCategoryOptions] = useState<IDropdownOption[]>([]);
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  useEffect(() => {
    setSelectedCategory(document?.category);
    if (document?.category === 'Position') {
      setShowPositions(true);
    }
    setSelectedPosition(document?.positionId);
  }, [document]);

  useEffect(() => {
    async function getPositions() {
      const response = await apiService.get(`projects/${document.projectId}/positions`)
      setPositions(await response.json());
    }
    getPositions();
  }, []);

  useEffect(() => {
    setCategoryValues();
    setPositionValues();
  }, [document, positions]);

  async function setPositionValues() {
    const options: IDropdownOption[] = [];
    if (!positions?.some(x => x.id === document.positionId)) {
      options.push({ key: '', text: 'No selection', isSelected: document.positionId === undefined });
    }
    if (positions) {
      for (let position of positions!) {
        if (position.type.toLowerCase() === 'position') {
          options.push({ key: position.id as string, text: position.name as string });
        }
      }
    }

    setPositionOptions(options);
  }

  async function setCategoryValues() {
    const options: IDropdownOption[] = [];
    addOption('Position');
    addOption('Order');
    addOption('NotApplicable');
    addOption('Uncategorized');
    function addOption(s: string) {
      options.push({ key: s, text: StringService.getDocumentCategory(s) });
    }

    setCategoryOptions(options);
  }

  function updateUI(input: string = '', positionInput: string = '') {
    if (input === 'Position') {
      setShowPositions(true);
    } else {
      setShowPositions(false);
      setSelectedPosition(undefined);
    }
    console.log(positionInput);
    console.log((input === 'Position' && (positionInput === '' || positionInput === undefined)));

    setDisableSaveButton(input === 'Position' && (positionInput === '' || positionInput === undefined));
  }

  const onCategoryChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>, index?: number | undefined) => {
    const value = option!.key as string;
    setSelectedCategory(value);
    updateUI(value);
  }

  const onPositionChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>, index?: number | undefined) => {
    setSelectedPosition(option!.key as string);
    updateUI('Position', option!.key as string);
  }

  const handleDismiss = (e: boolean) => {
    dismissModal(e)
  }

  async function save(e: any) {
    let req: DocumentCategoryDto = {
      category: selectedCategory as DocumentCategory,
      positionId: selectedPosition,
    }
    const response = await apiService.put(      
      `projects/${document.projectId}/documents/${document.id}/category`,
      req
    )

    if (selectedCategory?.toLowerCase() === 'order') {
      const orderExistResponse = await apiService.get(`projects/${document.projectId}/positions`);

      const orders: IPosition[] = await orderExistResponse.json();
      const exist: IPosition | undefined = orders.find(x => x.type.toLowerCase() === 'order');
      if (exist === undefined) {
        console.log('Order not found')
        const order: IPosition = {
          type: 'Order',
          name: 'Order',
          projectId: document.projectId
        }
        console.log('creating order for project');
        await apiService.post(`projects/${document.projectId}/positions`, order);
      }
    }

    handleDismiss(true);
  }

  return (
    <div>
      <Modal
        isOpen={show}
        onDismiss={() => handleDismiss(false)}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        {
          <>
            <div className={contentStyles.header}>
              <span>Categorize document</span>
              <IconButton
                styles={iconButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={() => handleDismiss(false)}
              />
            </div>
            <div className={contentStyles.body}>
              <Dropdown
                label="Category"
                selectedKey={selectedCategory}
                options={categoryOptions}
                onChange={onCategoryChange}
                required
              />
            </div>
            {
              showPositions ?
                <div className={contentStyles.body}>
                  <Dropdown
                    label="Position"
                    selectedKey={selectedPosition}
                    options={positionOptions!}
                    onChange={onPositionChange}
                    required
                  />
                </div>
                :
                <></>
            }
            <div>
              <PrimaryButton
                style={{ float: 'right', margin: 10 }}
                text="Save"
                iconProps={{ iconName: 'Save' }}
                onClick={save}
                disabled={disableSaveButton}
              />
              <DefaultButton
                style={{ float: 'right', margin: 10 }}
                text="Cancel"
                onClick={() => handleDismiss(false)}
              />
            </div>
          </>
        }
      </Modal>
    </div>
  )
}

const cancelIcon: IIconProps = { iconName: 'Cancel' }

const theme = getTheme()
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
    minWidth: '400px',
  },
})
const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
}
