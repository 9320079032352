import { Checkbox, ChoiceGroup, CommandBarButton, Dropdown, FontIcon, IChoiceGroupOption, IconButton, IDropdownOption, ITooltipHostStyles, Label, mergeStyles, PrimaryButton, Stack, TextField, TooltipHost, useTheme } from '@fluentui/react';
import { FC, useState } from 'react';
import { IExtra } from '../../models/IExtra';
import { IDemandExtra } from '../../models/ILocalDemand';
import { IPheType } from '../../models/IPheType';
import { IPosition } from '../../models/IPosition';
import { Actions, hasPermission } from '../../Services/Permissions';
import { ExtrasModal } from '../shared/ExtrasModal';
import * as extraHelpers from '../../helpers/extra';
import { IExtraPer } from '../../models/Enums';
import { getBusinessUnit } from '../../Services/Global';

export const DemandForm: FC<{
    handleActionUpdate: any,
    handlePositionChange: any,
    handleCheckbox: any,
    removeExtra: any,
    action: string,
    availablePositions: IDropdownOption<any>[],
    currentPosition: IPosition,
    positionExtras: Record<string, IDemandExtra[]> | undefined,
    extras: IExtra[],
    internalComment: string,
    clarification: string,
    setClarification: any,
    addDemand: any,
    setInternalComment: any,
    demandExist: boolean,
    setPositionExtras: any,
    pheTypes: IPheType[],
}>
    =
    ({
        handleActionUpdate,
        handlePositionChange,
        handleCheckbox,
        removeExtra,
        action,
        availablePositions,
        currentPosition,
        positionExtras,
        extras,
        internalComment,
        clarification,
        setClarification,
        addDemand,
        setInternalComment,
        demandExist,
        setPositionExtras,
        pheTypes
    }) => {

        let theme = useTheme();

        const [showExtrasModal, setShowExtrasModal] = useState(false);

        const actionOptions: IDropdownOption[] = [
            { key: 'Accept', text: 'Accept' },
            { key: 'Information', text: 'Information' },
            { key: 'Clarification', text: 'Clarification' },
            { key: 'Deviate', text: 'Deviation' },
            { key: 'NotApplicable', text: 'N/A' }
        ];

        const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
        const iconClass = mergeStyles({
            fontSize: 20,
            height: 10,
            width: 10,
            margin: '0 5px',
            color: theme.palette.themePrimary
        });

        const renderExtras = () => {
            if (positionExtras === undefined) {
                return <></>
            }
            else {
                if (currentPosition === undefined || positionExtras[currentPosition.id!] === undefined) return <></>;
                return (
                    positionExtras[currentPosition.id as string].map((x, key) => {
                        const targetExtra = extras?.find(y => y.id === x.id);
                        return (
                            <TooltipHost key={`${x.id}-${key}-${currentPosition!.id}`} content={targetExtra?.description} id={x.id} styles={hostStyles}>
                                <div className='extra-check' style={{ boxShadow: theme.effects.elevation4 }}>
                                    <Stack horizontal>
                                        <Stack.Item>
                                            <FontIcon style={{ marginTop: 5, marginRight: 5 }} iconName='InfoSolid' className={iconClass} />
                                        </Stack.Item>
                                        <Stack.Item style={{ paddingLeft: 10 }}>
                                            <Checkbox defaultChecked={x.isActive === true} styles={{ root: { padding: 5 } }} label={targetExtra?.zBlock} onChange={(e, c) => handleCheckbox(e, c, x, currentPosition.id)} />
                                        </Stack.Item>
                                        {
                                            hasPermission(Actions.DemandsDeleteExtra) &&
                                            <Stack.Item >
                                                <IconButton className='list-button' iconProps={{ iconName: 'Delete' }} onClick={() => removeExtra(x.id)} onFocus={(ev) => ev.stopPropagation()} />
                                            </Stack.Item>
                                        }
                                    </Stack>
                                </div>

                            </TooltipHost>
                        )
                    })
                )
            }
        }

        const handleDismissExtras = (incomingExtra: IDemandExtra) => {
            if (positionExtras === undefined) return;
            if (currentPosition === undefined) return;

            let currentExtras = positionExtras[currentPosition.id as string];
            let currentPositionExtras = positionExtras;

            // Newly selected extras should always show as selected. therefore isActive: true
            if (currentExtras === undefined) {
                currentExtras = [];
            }

            const targetedExtra = extras?.find(x => x.id === incomingExtra.id);
            currentExtras.push({ id: incomingExtra.id, isAddedNew: true, isActive: true, numUnitsByPosition: { [currentPosition!.id! as string]: extraHelpers.default.getNumberOf(currentPosition, targetedExtra!.per as IExtraPer)! } });
            currentPositionExtras[currentPosition.id as string] = currentExtras;
            setPositionExtras(currentPositionExtras);
        }

        return (
            <div>
                <Label style={{ fontSize: 18 }}>
                    Action
                </Label>
                <Dropdown label="" onChange={handleActionUpdate} options={actionOptions} selectedKey={action} />
                <Label style={{ fontSize: 18 }}>
                    Position
                </Label>
                <Dropdown options={availablePositions} onChange={(e, x) => handlePositionChange(e, x, currentPosition.id)} selectedKey={currentPosition.id} />
                <Label style={{ fontSize: 18 }}>
                    Clarification
                </Label>
                <TextField multiline value={clarification} name='notes' onChange={(e) => setClarification(e.currentTarget.value)} />
                <Label style={{ fontSize: 18 }}>
                    Internal comment to SC
                </Label>
                <TextField multiline name='internalComment' value={internalComment} onChange={(e) => setInternalComment(e.currentTarget.value)} />
                <Label style={{ fontSize: 18 }}>
                    Extras
                </Label>
                <Stack style={{ width: '20vw' }}>
                    {
                        <>{renderExtras()}</>
                    }
                </Stack>
                <div style={{ marginTop: 10 }}>
                    {hasPermission(Actions.DemandsAddExtra) && <CommandBarButton style={{ height: 30 }} iconProps={{ iconName: 'Add' }} text='Add extra' onClick={() => setShowExtrasModal(true)} />}
                    <PrimaryButton text={demandExist === true ? 'Update' : 'Add'} style={{ float: 'right' }} onClick={addDemand} />
                </div>
                {
                    showExtrasModal === true ?
                        <ExtrasModal
                            dismissModal={() => setShowExtrasModal(false)}
                            isEditing={false}
                            show={showExtrasModal}
                            handleDismissExtras={handleDismissExtras}
                            existingExtras={positionExtras![currentPosition!.id as string]}
                            allExtras={extras || []}
                            currentPheType={pheTypes.find(item => item.id === currentPosition?.pheTypeId)!}
                            currentPosition={currentPosition!}
                        />
                        :
                        <></>
                }
            </div>
        )
    }