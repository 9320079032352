import { IMsalContext } from '@azure/msal-react'
import { AuthenticationService } from './AuthenticationService'
import * as FileSaver from 'file-saver'
import { getBusinessUnit } from './Global'

export class ApiService {
  private context: IMsalContext
  private apiUrl: string

  constructor(ctx: IMsalContext) {
    this.context = ctx
    this.apiUrl =
      process.env.NODE_ENV === 'production'
        ? `${process.env.REACT_APP_API_URL}`
        : `https://localhost:7283`
  }

  public getServerBaseUrl(): string {
    return this.apiUrl;
  }

  private async getRequestOptions(method: string, body: any, isFile: boolean): Promise<any> {
    const authService = new AuthenticationService(this.context)
    const accessToken = await authService.getAccessToken()

    var ret: any = {
      method: method,
      body: body,
      headers: {
        'Authorization': 'Bearer ' + accessToken
      }
    }
    if (!isFile) {
      ret.headers['Content-Type'] = 'application/json'
    }
    return ret
  }

  private async executeCall(url: string, method: string, throwOnError: boolean, body: any = null, isFile: boolean = false) {
    const response = await fetch(url, await this.getRequestOptions(method, body, isFile))
    if (!response.ok && throwOnError) {
      let errorMsg = ''
      try {
        errorMsg = (await response.json())["title"] as string;
      } catch { }
      let msg = `An error occurred. Status code: ${response.status}`
      if (errorMsg) {
        msg += `, message: ${errorMsg}`
      }
      throw new Error(msg)
    }
    return response
  }

  public async get(path: string, throwOnError: boolean = true) {
    const url: string = `${this.apiUrl}/api/BusinessUnits/${getBusinessUnit()}/${path}`
    return await this.executeCall(url, 'GET', throwOnError)
  }

  public async delete(path: string, throwOnError: boolean = true) {
    const url: string = `${this.apiUrl}/api/BusinessUnits/${getBusinessUnit()}/${path}`
    return await this.executeCall(url, 'DELETE', throwOnError)
  }

  public async post(
    apiPath: string,
    input: any | FormData,
    throwOnError: boolean = true
  ) {
    const url: string = `${this.apiUrl}/api/BusinessUnits/${getBusinessUnit()}/${apiPath}`
    // depending on what body we'll have, either treat as a file or general payload.
    if (input instanceof FormData) {
      return await this.executeCall(url, 'POST', throwOnError, input, true);
    } else {
      return await this.executeCall(url, 'POST', throwOnError, JSON.stringify(input));
    }
  }

  public async put(
    apiPath: string,
    input: any | FormData = null,
    throwOnError: boolean = true
  ) {
    const url: string = `${this.apiUrl}/api/BusinessUnits/${getBusinessUnit()}/${apiPath}`
    // depending on what body we'll have, either treat as a file or general payload.
    if (input) {
      return await this.executeCall(url, 'PUT', throwOnError, JSON.stringify(input));
    } else {
      return await this.executeCall(url, 'PUT', throwOnError);
    }
  }

  public async downloadFile(path: string) {
    let response = await this.get(path)
    if (!response.ok) {
      throw new Error('Could not download file');
    }
    let fileName = decodeURIComponent(response.headers.get('content-disposition')?.split("filename*=UTF-8''")[1] as string);
    let content = await response.blob()
    FileSaver.saveAs(content, fileName);
  }

  public getUrl(apiPath: string) {
    return `${this.apiUrl}/api/BusinessUnits/${getBusinessUnit()}/${apiPath}`
  }
}
