export enum Actions {
    ProjectDelete,
    SummaryExport,
    SummaryData,
    ClarificationView,
    DocumentTextSelection,
    DocumentSetDone,
    DocumentDelete,
    DemandsCreate,
    DemandsDeleteExtra,
    DemandsAddExtra,
    AdminView,
    Training
}

export enum Roles {
    GpheBidEngineer = "BidEngineer",
    GpheAdmin = "Admin",
    GpheBudgetEstimator = "BudgetEstimator",
    WheBidEngineer = "WheBidEngineer",
    WheAdmin = "WheAdmin",
    WheBudgetEstimator = "WheBudgetEstimator"
}

export const AdminRoles = [Roles.GpheAdmin, Roles.WheAdmin]
export const BidEngineerRoles = [Roles.GpheBidEngineer, Roles.WheBidEngineer]
export const BudgetEstimatorRoles = [Roles.GpheBudgetEstimator, Roles.WheBudgetEstimator]

export const GpheRoles = [Roles.GpheAdmin, Roles.GpheBidEngineer, Roles.GpheBudgetEstimator]
export const WheRoles = [Roles.WheAdmin, Roles.WheBidEngineer, Roles.WheBudgetEstimator]