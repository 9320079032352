import { Clarifications } from "../pages/clarifications/Clarifications";
import { DocumentsList } from "../pages/document/DocumentsList";
import { DocumentView } from "../pages/document/DocumentView";
import { Overview } from "../pages/overview/Overview";
import { Summary } from "../pages/summary/Summary";
import { Admin } from "../pages/admin/Admin";
import { RoutePathDefinition } from "./RoutePathDefinition";

export const routes: RoutePathDefinition[] = [
    { title: 'Projects', path: '*', element: <Overview /> },
    { title: 'Projects', path: '/', element: <Overview /> },
    { title: 'Projects', path: '/overview', element: <Overview /> },
    {
        title: 'Project', path: '/projects/:id', children: [
            { title: 'Document list', path: 'documents', element: <DocumentsList /> },
            { title: 'Document', path: 'documents/:docId', element: <DocumentView /> },
            { title: 'Clarifications', path: 'clarifications', element: <Clarifications /> },
            { title: 'Summary', path: 'summary', element: <Summary /> }            
        ]
    },
    { title: 'Admin', path: '/admin', element: <Admin /> }
];

