import React, { useEffect, useState } from 'react';
import {
    FontSizes,
    Modal,
    Spinner,
    Stack,

} from '@fluentui/react'
import { IMsalContext, useMsal } from '@azure/msal-react';
import { AuthenticationService } from '../../Services/AuthenticationService';
import { ApiService } from '../../Services/ApiService';
import { IDocument } from '../../models/IDocument';
import Pdfjs, { PdfDocument } from 'pdfjs-dist/webpack';
import ConnectedPdfPreview from '../../pages/document/PdfPreview';
import { System } from '../../typings';

export const PdfRenderingModal: React.FC<{
    show: boolean
    dismissModal: any,
    projectId: string,
    documentId: string
    pageNumber: number
    highlight: System.Highlight[]
}> = ({ show, dismissModal, projectId, documentId, pageNumber, highlight }) => {

    const ctx: IMsalContext = useMsal()
    const authService = new AuthenticationService(ctx)
    const apiService = new ApiService(ctx)

    const [isLoading, setIsLoading] = useState(true);

    const [document, setDocument] = useState<IDocument>();
    const [pdfData, setPdfData] = useState<PdfDocument>();

    const handleDismiss = (e: boolean) => {
        dismissModal(e)
    }

    useEffect(() => {
        if (pageNumber === undefined) return;
        async function getPdfDocumentAsync() {
            setIsLoading(true);
            const accessToken = await authService.getAccessToken();
            const url = apiService.getUrl(`Projects/${projectId}/documents/${documentId}/pdf`);
            const pdf = await Pdfjs.getDocument({ url: url, httpHeaders: { Authorization: `Bearer ${accessToken}` }, withCredentials: true });

            setPdfData(pdf);
            const doc = await apiService.get(`Projects/${projectId}/documents/${documentId}`);
            const fetchedDoc: IDocument = await doc.json();

            setDocument(fetchedDoc);
            setIsLoading(false);
        }

        getPdfDocumentAsync();
    }, [pageNumber]);


    return (
        <div style={{ overflow: 'hidden' }}>
            <Stack>
                <Modal
                    isOpen={show}
                    onDismiss={() => handleDismiss(false)}
                    isBlocking={false}
                    styles={{
                        scrollableContent: {
                            overflow: 'scroll'
                        }
                    }}
                >
                    <div>
                        <p style={{ fontSize: FontSizes.size20, padding: 20 }}>
                            {document?.name}
                        </p>
                    </div>
                    {
                        pdfData !== undefined && isLoading === false && pageNumber !== undefined && highlight !== undefined ?
                            <div>
                                <ConnectedPdfPreview
                                    document={pdfData}
                                    highlights={highlight}
                                    interactionId={''}
                                    pageNumber={pageNumber}
                                />
                            </div>
                            :
                            <Spinner />
                    }
                </Modal>
            </Stack>
        </div>
    )
}