import { IMsalContext, useMsal } from '@azure/msal-react';
import { DetailsList, DetailsListLayoutMode, IColumn, IconButton, PrimaryButton, SearchBox, SelectionMode, Spinner, SpinnerSize, Stack, useTheme } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IProject } from '../../models/IProject';
import { ApiService } from '../../Services/ApiService';
import { getBusinessUnit, setBusinessUnit, getTrainingMode, setProjectId, setProjectName } from '../../Services/Global';
import { ProjectModal } from '../../components/shared/ProjectModal';
import './overview.scss';
import { StringService } from '../../Services/StringService';
import { Pagination } from '@uifabric/experiments';
import { Actions, hasPermission, getBusinessUnitAccess } from '../../Services/Permissions';
import { BusinessUnitSelectionView } from '../../components/shared/BusinessUnitSelectionView';

export const Overview: React.FC<{}> = () => {

    let theme = useTheme();
    let businessUnit = getBusinessUnit();

    setProjectId('')
    const columnSpec: IColumn[] = [
        {
            key: '1',
            name: 'ID',
            fieldName: 'code',
            minWidth: 50,
            maxWidth: 75,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        },
        {
            key: '2',
            name: 'Name',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        },
        {
            key: '3',
            name: 'Customer',
            fieldName: 'customer',
            minWidth: 100,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        },
        {
            key: '4',
            name: 'Created',
            fieldName: 'created',
            minWidth: 100,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: true,
            isSortedDescending: true,
            data: 'string',
            isPadded: true,
            onRender: (item: IProject) => {
                return renderDate(item.created)
            },
        },
        {
            key: '5',
            name: 'Created By',
            fieldName: 'createdBy',
            minWidth: 100,
            maxWidth: 200,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
        },
        {
            key: '6',
            name: 'Updated',
            fieldName: 'updated',
            minWidth: 100,
            maxWidth: 150,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: IProject) => {
                return renderDate(item.updated)
            },
        },
        {
            key: '7',
            name: 'Status',
            fieldName: 'status',
            minWidth: 75,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: IProject) => {
                return StringService.geProjectStatus(item.status)
            },
        },
        {
            key: '8',
            name: 'Training',
            minWidth: 30,
            maxWidth: 30,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: IProject) => {
                return item.includesTrainingDocuments ? "✓" : ""
            }
        },
        {
            key: '9',
            name: '',
            isRowHeader: false,
            minWidth: 20,
            maxWidth: 20,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            isPadded: true,
            onRender: renderEditButton
        },
        {
            key: '10',
            name: '',
            isRowHeader: false,
            minWidth: 20,
            maxWidth: 20,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            isPadded: true,
            onRender: renderDeleteButton
        }
    ]

    const [selectedProject, setSelectedProject] = useState<IProject>();
    const [showModal, setShowModal] = useState(false);

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [searchText, setSearchText] = useState<string>('')
    const [data, setData] = useState<IProject[]>()
    const [orderBy, setOrderBy] = useState<string>('created')
    const [page, setPage] = useState<number>(1)
    const [pageCount, setPageCount] = useState<number>(0)
    const [isOrderByDescending, setIsOrderByDescending] = useState<boolean>(true)
    const [columns, setColumns] = useState<IColumn[]>(columnSpec)

    let navigate = useNavigate();

    const ctx: IMsalContext = useMsal()
    const apiService = new ApiService(ctx)


    const showNewProjectModal = () => {
        setShowModal(!showModal);
    }

    const onDismissModal = (e: boolean) => {
        setShowModal(false);
        refreshProjects();
    }

    const onDismissEditProjectModal = (e: boolean) => {
        setSelectedProject(undefined);
        refreshProjects();
    }

    function refreshProjects() {
        loadProjects();
    }

    function renderDate(date: any) {
        if (date) {
            return <p>{new Date(date).toLocaleString()}</p>
        } else {
            return ''
        }
    }

    function renderDeleteButton(item: any, index?: number, column?: IColumn) {
        return <IconButton className='list-button' iconProps={{ iconName: 'Delete' }} onClick={() => deleteProject(item)} onFocus={(ev) => ev.stopPropagation()} />
    }

    const handleEditProject = (e: any) => {
        if (e !== undefined) {
            setSelectedProject(e);
        }
    }

    function renderEditButton(item: any, index?: number, column?: IColumn) {
        return <IconButton className='list-button' iconProps={{ iconName: 'Edit' }} onClick={(e) => handleEditProject(item)} onFocus={(ev) => ev.stopPropagation()} />
    }

    const handleSearch = async (e: any) => {
        if (e === undefined) {
            e = ""
        }
        setSearchText(e);
        await queryProjects(e, orderBy, isOrderByDescending, 0);
    }

    async function onColumnClick(ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined, column?: IColumn | undefined) {

        if (column === undefined) return;

        let isSortedDescending = column.isSortedDescending;
        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        column.isSorted = !column.isSorted
        column.isSortedDescending = !column.isSortedDescending
        setOrderBy(column.fieldName!);
        setIsOrderByDescending(isSortedDescending!);
        await queryProjects(searchText, column.fieldName!, isSortedDescending!, 0);

        let currentColumns = columns;
        currentColumns.forEach(c => {
            if (c.key === column.key) {
                c.isSorted = c.key === column.key;
                if (c.isSorted) {
                    c.isSortedDescending = isSortedDescending;
                }
            } else {
                c.isSorted = false;
                c.isSortedDescending = false;
            }
        });

        setColumns(columns!.map(col => {
            col.isSorted = col.key === column.key;
            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }
            return col;
        }));
    };

    useEffect(() => {
        if (businessUnit === 'None') {
            let access = getBusinessUnitAccess()
            if (access.length === 1) {
                setBusinessUnit(access[0])
                businessUnit = access[0]
                window.location.reload();
            }
        }
        if (businessUnit !== 'None') {
            refreshProjects()
            setColumns(hasPermission(Actions.ProjectDelete) ? columnSpec : columnSpec.slice(0, 8))
        }
    }, [])

    async function queryProjects(searchString: string, sortyBy: string, isSortByDescending: boolean, pageNumber: number) {
        setSearchText(searchString)
        setOrderBy(sortyBy)
        setIsOrderByDescending(isSortByDescending)
        setPage(pageNumber)
        const pageSize = 100
        const response = await apiService.get(`projects?trainingProjects=${getTrainingMode()}&searchString=${searchString}&orderBy=${sortyBy}&isOrderByDescending=${isSortByDescending}&pageSize=${pageSize}&page=${pageNumber + 1}`)
        const data = await response.json()
        setPageCount(Math.ceil(data.totalCount / pageSize))
        setData(data.values)
    }

    async function onPageChange(pageNumber: number) {
        await queryProjects(searchText, orderBy, isOrderByDescending, pageNumber)
    }

    async function loadProjects() {
        setIsLoading(true)
        async function getProjectData() {
            await queryProjects('', 'created', true, 0)
            setIsLoading(false)
        }

        getProjectData().catch((error) => {
            setIsLoading(false)
            setErrorMessage(error.toString())
        })
    }

    async function deleteProject(e: IProject) {
        if (window.confirm('Are you sure you want to delete this project along with all attached documents?')) {
            const ret = await apiService.delete(`projects/${e.id!}`);
            if (!ret.ok) {
                alert('Failed to delete project')
            }
            await loadProjects()
        }
    }

    const handleSelection = (e: IProject) => {
        if (e !== undefined) {
            if (e.id === undefined) return;
            setProjectId(e.id);
            setProjectName(e.name ?? "");
            navigate(`../projects/${e.id}/documents`, { state: e });
        }
    }

    return (
        <>
            {
                businessUnit !== 'None' ?

                    <div>
                        <div>
                            <Stack>
                                <Stack.Item>
                                    <Stack horizontal horizontalAlign='end'>
                                        <Stack.Item align='end'>
                                            <PrimaryButton style={{ float: 'right', marginRight: 50 }} text='Create Project' onClick={() => showNewProjectModal()} />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </div>
                        <ProjectModal show={showModal} dismissModal={onDismissModal} isEditing={false} />
                        {
                            selectedProject !== undefined ?
                                <ProjectModal show={selectedProject !== undefined} dismissModal={onDismissEditProjectModal} project={selectedProject} isEditing={true} />
                                :
                                <></>
                        }

                        {
                            !isLoading ? (
                                !errorMessage && data !== undefined ? (

                                    <div>
                                        <Stack>
                                            <Stack.Item align='center' style={{ width: '500px' }}>
                                                <SearchBox defaultValue={searchText} placeholder='Search' onSearch={(e) => { handleSearch(e) }} onClear={(e) => handleSearch('')} />
                                            </Stack.Item>
                                            <Stack.Item align='center' className='pagination-container'>
                                                <Pagination
                                                    styles={{ root: { margin: 5 } }}
                                                    selectedPageIndex={page}
                                                    pageCount={pageCount}
                                                    onPageChange={onPageChange}
                                                    firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
                                                    previousPageIconProps={{ iconName: 'ChevronLeft' }}
                                                    nextPageIconProps={{ iconName: 'ChevronRight' }}
                                                    lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
                                                    theme={theme}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <DetailsList
                                                    items={data}
                                                    columns={columns}
                                                    selectionMode={SelectionMode.none}
                                                    onActiveItemChanged={handleSelection}
                                                    onColumnHeaderClick={onColumnClick}
                                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                                    className='details-list-800 details-list-center'
                                                    onShouldVirtualize={() => false}
                                                />
                                            </Stack.Item>
                                        </Stack>
                                    </div>

                                ) : (
                                    <div>
                                        <p>{errorMessage}</p>
                                    </div>
                                )
                            ) : (
                                <Spinner
                                    className="loading-spinner"
                                    size={SpinnerSize.large}
                                    label={'Loading projects...'}
                                />
                            )}

                    </div>
                    :
                    <BusinessUnitSelectionView />
            }
        </>
    )
}


