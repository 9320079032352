import { IMsalContext, useMsal } from '@azure/msal-react';
import { Dialog, DialogFooter, DefaultButton, getTheme, PrimaryButton, Selection, DetailsList, FontIcon, TooltipHost, GroupedList, IColumn, IGroup, IGroupHeaderProps, IRawStyle, mergeStyleSets, SelectionMode, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from '@fluentui/react-hooks';
import { ApiService } from '../../Services/ApiService'
import './summary.scss'
import { ISummaryItem, ISummaryItemDetails } from '../../models/ISummaryItem'
import { StringService } from '../../Services/StringService';
import { IDocument } from '../../models/IDocument';
import { getBusinessUnit, setProjectId } from '../../Services/Global';
import { Actions, hasPermission } from '../../Services/Permissions';
import { Label } from 'office-ui-fabric-react';

export const Summary: React.FC<{}> = () => {
    const ctx: IMsalContext = useMsal()
    const apiService = new ApiService(ctx)
    const [groups, setGroups] = useState<IGroup[]>();
    const [data, setData] = useState<ISummaryItem[]>([])
    const [documents, setDocuments] = useState<IDocument[]>([])
    const [docIds, setDocIds] = useState<string[]>([])
    const [sortBy, setSortBy] = useState<string>()
    const [isLoading, setIsLoading] = useState(true);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const params = useParams();
    const projectId = params.id === undefined ? '' : params.id;
    if (projectId) {
        setProjectId(projectId);
    }

    useEffect(() => {
        async function getData() {
            setIsLoading(true);
            try {
                const response = await apiService.get(`Projects/${projectId}/summary`)
                const summaryItems: ISummaryItem[] = await response.json();
                const docResponse = await apiService.get(`Projects/${projectId}/documents`)
                setDocuments(await docResponse.json());
                sortData(summaryItems, 'demand')
            } finally {
                setIsLoading(false);
            }
        }
        getData();
    }, [])

    function sortData(summaryItems: ISummaryItem[], column: string): void {
        if (column !== sortBy) {
            let grps: IGroup[] = [];
            let start: number = 0
            summaryItems.forEach((s) => {
                if (column === "extra") {
                    s.details = s.details.sort((a, b) => (a.extra?.zBlock ?? '').localeCompare((b.extra?.zBlock) ?? ''))
                } else {
                    s.details = s.details.sort((a, b) => a.demandName.localeCompare(b.demandName))
                }
                const targetGroup = groups?.find(x => x.key === s.positionId);

                grps.push({
                    count: 1,
                    key: s.positionId,
                    name: s.positionName,
                    startIndex: start++,
                    isCollapsed: targetGroup !== undefined ? targetGroup.isCollapsed : true,
                    data: {
                        pheType: s.pheType,
                        numberOfUnits: s.numberOfUnits,
                        type: s.positionType,
                        details: s.details
                    }
                })
            })
            setSortBy(column)
            setGroups(grps);
            setData(summaryItems);
        }
    }

    const theme = getTheme();
    const headerAndFooterStyles: IRawStyle = {
        minWidth: 300,
        minHeight: 40,
        lineHeight: 40,
        paddingLeft: 16,
    };

    const classNames = mergeStyleSets({
        header: [headerAndFooterStyles, theme.fonts.large],
        footer: [headerAndFooterStyles, theme.fonts.mediumPlus],
        row: [headerAndFooterStyles, theme.fonts.medium],
        name: {
            display: 'inline-block',
            overflow: 'hidden',
            height: 24,
            cursor: 'default',
            padding: 8,
            boxSizing: 'border-box',
            verticalAlign: 'top',
            background: 'none',
            backgroundColor: 'transparent',
            border: 'none',
            paddingLeft: 32
        },
    });

    const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element => {
        if (props) {
            const toggleCollapse = (): void => {
                props.onToggleCollapse!(props.group!);
            };
            return (
                <>
                    <div className={`${classNames.header} summary-header`} style={{ boxShadow: theme.effects.elevation16, height: 50 }} onClick={toggleCollapse}>
                        <Stack horizontal>
                            <Stack.Item className={classNames.header} align='start'>
                                {props.group?.name}
                            </Stack.Item>
                            <Stack.Item grow className={classNames.header}>
                                {props.group?.data?.type !== 'Order' ? `${props.group?.data.pheType.type}` : ''}
                            </Stack.Item>
                            <Stack.Item style={{ paddingRight: 20 }} className={classNames.header}>
                                {props.group?.data?.type !== 'Order' ? `${props.group?.data.pheType.designation ?? ''}` : ''}
                            </Stack.Item>
                            <Stack.Item style={{ paddingRight: 20 }} className={classNames.header}>
                                {props.group?.data?.type !== 'Order' ? `${props.group?.data.numberOfUnits} units` : ''}
                            </Stack.Item>
                            {getBusinessUnit() === 'GPHE' ?
                                <Stack.Item style={{ paddingRight: 20 }} className={classNames.header}>
                                    {props.group?.data?.details.filter((p: ISummaryItemDetails) => p.extra && p.action !== 'NotApplicable').reduce((sum: number, p: ISummaryItemDetails) => sum + p.numberOf * p.extra.pplInCas, 0)} €
                                </Stack.Item>
                                : <></>}
                        </Stack>
                    </div >
                </>
            );
        }

        return <p></p>
    };

    function getColumns() {
        if (getBusinessUnit() === 'GPHE') {
            return [...columns,
            {
                key: '7',
                name: 'PPL Price',
                minWidth: 50,
                maxWidth: 100,
                isRowHeader: true,
                isResizable: true,
                isSorted: false,
                isSortedDescending: false,
                data: 'string',
                isPadded: true,
                onRender: (item: ISummaryItemDetails) => {
                    return item.extra ? `${item?.extra?.pplInCas * item.numberOf} €` : ""
                }
            }]

        } else {
            return columns;
        }
    }

    const columns: IColumn[] = [
        {
            key: '1',
            name: 'Demand',
            fieldName: 'demandName',
            minWidth: 150,
            maxWidth: 300,
            isRowHeader: true,
            isResizable: true,
            isSorted: sortBy === 'demand',
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onColumnClick: (e, c) => {
                sortData(data, 'demand')
            },
            onRender: (item: ISummaryItemDetails) => {
                return item.comment || item.internalComment
                    ?
                    <div>
                        <span>{item.demandName}</span>
                        &nbsp;
                        <TooltipHost
                            content={`Clarification: ${item.comment} Internal:${item.internalComment ?? ''}`}>
                            <FontIcon iconName='Comment' />
                        </TooltipHost>
                    </div>
                    : item.demandName
            }
        },
        {
            key: '2',
            name: 'Extra',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: sortBy === 'extra',
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onColumnClick: (e, c) => {
                sortData(data, 'extra')
            },
            onRender: (item: ISummaryItemDetails) => {
                return (
                    <div>
                        <TooltipHost
                            content={`${item?.extra?.category && item?.extra?.category + ', '}${item?.extra?.per && item?.extra?.per + ', '}${item?.extra?.description && item?.extra?.description}`}>
                            <FontIcon iconName='Info' />
                        </TooltipHost>
                        &nbsp;
                        <span>{item?.extra?.zBlock}</span>
                    </div>)
            }
        },
        {
            key: '3',
            name: 'Description',
            minWidth: 150,
            maxWidth: 500,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: ISummaryItemDetails) => {
                return item?.extra?.description
            }
        },
        {
            key: '4',
            name: 'Action',
            minWidth: 75,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: ISummaryItemDetails) => {
                return StringService.getDemandAction(item?.action)
            }
        },
        {
            key: '5',
            name: 'Per',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: ISummaryItemDetails) => {
                return item?.extra?.per
            }
        },
        {
            key: '6',
            name: 'Nbr of',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true,
            onRender: (item: ISummaryItemDetails) => {
                return item.extra ? <TextField id={`${item.positionId}|${item.demandId}|${item.extra.id}`} type='number' defaultValue={item.numberOf.toString()} onChange={onNumChange} /> : ''
            }
        }
    ]

    async function onNumChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        if (newValue) {
            var ids = event.currentTarget.id.split('|')
            var value = parseInt(newValue)
            var detail = data
                .filter((p) => p.positionId === ids[0])
                .flatMap((p) => p.details)
                .filter((q) => q.demandId === ids[1] && q.extra.id === ids[2])[0]
            if (detail.numberOf !== value) {
                if (value < 0) {
                    value = 0
                }
                detail.numberOf = value
                const response = await apiService.put(`projects/${projectId}/demands/${detail.demandId}/extras/${detail.extra.id}/positions/${detail.positionId}/unitCount/${value}`)
            }
            setData([...data, ...[]]) // To re-draw
        }
    }

    async function downloadContractReview(selectedOnly: boolean = false) {
        var queryParam = '';
        if (selectedOnly){
            queryParam = docIds.map(p => `documentId=${p}`).join('&');
        }
        if (!hideDialog) { 
            setDocIds([]);
            toggleHideDialog();
        }
        await apiService.downloadFile(`Projects/${projectId}/contractReview?${queryParam}`)
    }

    async function downloadBudgetaryEstimate() {
        var response = await apiService.get(`Projects/${projectId}/documents`)
        const docs: IDocument[] = await response.json();
        if (docs.some(x => x.status === 'Analyzing' || x.status === 'Failed')) {
            if (!window.confirm('All documents have not been processed yet. Export anyway?')) {
                return;
            }
        }
        if (docs.some(x => x.category === 'Uncategorized')) {
            if (!window.confirm('Some documents are uncategorized, these will not be included. Export anyway?')) {
                return;
            }
        }
        await apiService.downloadFile(`Projects/${projectId}/budgetaryEstimate`)
    }

    const renderRow = (nestingDepth?: number | undefined, item?: ISummaryItem, index?: number | undefined, group?: IGroup) => {
        return (
            <>
                <div className='row'>
                    <DetailsList
                        items={item!.details}
                        selectionMode={SelectionMode.none}
                        columns={getColumns()} />
                </div >
            </>
        );
    }

    const docColumns: IColumn[] = [
        {
            key: '1',
            name: 'Document Name',
            fieldName: 'name',
            minWidth: 120,
            isRowHeader: true,
            isResizable: false,
            isSorted: false,
            isSortedDescending: false,
            data: 'string',
            isPadded: true
        }
    ];

    const selection = new Selection({
        onSelectionChanged: () => {
            setDocIds((selection.getSelection() as IDocument[]).map(p => p.id));
        } 
    });

    return (
        <>
            <Stack horizontal style={{ float: 'right', padding: '20px' }}>
                <PrimaryButton text='Albot Budgetary Estimate' onClick={(e) => downloadBudgetaryEstimate()} />
                &nbsp;
                &nbsp;
                {hasPermission(Actions.SummaryExport) &&
                    <PrimaryButton text='Export' onClick={(e) => downloadContractReview()} />
                }
                &nbsp;
                &nbsp;
                {hasPermission(Actions.SummaryExport) &&
                    <PrimaryButton text='Export Selected Documents' onClick={toggleHideDialog} />
                }
                <Dialog
                            hidden={hideDialog}
                            onDismiss={toggleHideDialog}
                            minWidth={600}
                        >
                            <h2><Label>Documents</Label></h2>
                            <DetailsList
                                    items={documents}
                                    columns={docColumns}
                                    selectionMode={SelectionMode.multiple}
                                    selection={selection}
                                    selectionPreservedOnEmptyClick={true}/>
                            <DialogFooter>
                                <PrimaryButton onClick={() => downloadContractReview(true)} text="Export" disabled={docIds.length == 0} />
                                <DefaultButton onClick={toggleHideDialog} text="Cancel" />
                            </DialogFooter>
                        </Dialog>
            </Stack>
            {hasPermission(Actions.SummaryData) &&
                <div style={{ padding: 80 }}>
                    {
                        data !== undefined && isLoading === false ?
                            <>
                                <Stack horizontal>
                                    <Stack.Item>
                                        <GroupedList
                                            items={data}
                                            groups={groups}
                                            groupProps={{
                                                showEmptyGroups: true,
                                                onRenderHeader: onRenderHeader,
                                                isAllGroupsCollapsed: true
                                            }}
                                            selectionMode={SelectionMode.none}
                                            onRenderCell={renderRow}
                                            onShouldVirtualize={(p) => false}
                                        />
                                    </Stack.Item>
                                </Stack>

                            </>
                            :
                            <Spinner label='Loading ...' size={SpinnerSize.large} />
                    }
                </div>
            }
        </>
    )
}