import { DefaultButton, DetailsList, Dropdown, IColumn, IconButton, IDropdownOption, IStackItemStyles, IStackTokens, Label, Link, SearchBox, SelectionMode, Stack, TextField } from "@fluentui/react";
import { FC, useState } from "react";
import { IExtra } from "../../models/IExtra";
import { IGlobalDemand } from "../../models/IGlobalDemand";
import { IDemandExtra } from "../../models/ILocalDemand";
import { IPheType } from "../../models/IPheType";
import { IPosition } from "../../models/IPosition";
import { getBusinessUnit } from "../../Services/Global";
import { hasPermission, Actions } from "../../Services/Permissions";
import { NewGlobalDemandModal } from "../shared/NewGlobalDemandModal";
import { DemandForm } from "./DemandForm";

export const CustomerDemandForm: FC<{
    handleActionUpdate: any,
    handlePositionChange: any,
    handleCheckbox: any,
    removeExtra: any,
    action: string,
    currentPosition: IPosition,
    positionExtras: Record<string, IDemandExtra[]> | undefined,
    extras: IExtra[],
    internalComment: string,
    clarification: string,
    setClarification: any,
    addDemand: any,
    setInternalComment: any,
    demandExist: boolean,
    setPositionExtras: any,
    pheTypes: IPheType[],
    globalDemandsOptions: IDropdownOption[],
    selectedCustomerDemand: IGlobalDemand,
    setShowCustomerDemands: any,
    dismissModal: any,
    handleDemandSelection: any,
    handleDismissNewDemand: any,
    positions: IPosition[] | undefined,
    searchableGlobalDemandOptions: IDropdownOption[]
}> = ({
    handleActionUpdate,
    handlePositionChange,
    handleCheckbox,
    removeExtra,
    action,
    currentPosition,
    positionExtras,
    extras,
    internalComment,
    clarification,
    setClarification,
    addDemand,
    setInternalComment,
    demandExist,
    setPositionExtras,
    pheTypes,
    globalDemandsOptions,
    selectedCustomerDemand,
    setShowCustomerDemands,
    dismissModal,
    handleDemandSelection,
    handleDismissNewDemand,
    positions,
    searchableGlobalDemandOptions,
}) => {

        const [showNewDemandModal, setShowNewDemandModal] = useState(false);
        const [searchTerm, setSearchTerm] = useState('');
        const [filteredOptions, setFilteredOptions] = useState<IDropdownOption[]>();

        // Tokens definition
        const stackTokens: IStackTokens = {
            childrenGap: 30,
            padding: 10
        };

        const demandsStackItemStyles: IStackItemStyles = {
            root: {
                textAlign: 'left',
                alignItems: 'Left',
                display: 'flex',
            }
        };

        const availablePositions = () => {

            if (positions !== undefined) {
                let ops: IDropdownOption[] = [];

                positions.forEach((x) => {
                    const phe = pheTypes.find(phet => phet.id === x.pheTypeId)
                    if (getBusinessUnit() == 'GPHE') {
                        ops.push({ key: x.id!, text: `${x.type !== 'Order' ? `${phe?.type} / ${phe?.designation} - ${x.name}` : `${x.name}`}` });
                    } else {
                        ops.push({ key: x.id!, text: `${x.type !== 'Order' ? `${phe?.type} - ${x.name}` : `${x.name}`}` });
                    }
                });

                return ops;
            }

            return []
        }

        const handleSearch = (text: string | undefined) => {
            if (text !== undefined) {
                setSearchTerm(text);
                let ops: IDropdownOption[] = [];

                searchableGlobalDemandOptions?.forEach((x) => {
                    if (x.text.toLowerCase().includes(text.toLowerCase())) {
                        ops.push(x);
                    }
                })

                if (ops !== undefined) {
                    setFilteredOptions(ops);
                }
            }
        }

        const searchColumns: IColumn[] = [
            {
                key: '1',
                name: 'Demand',
                fieldName: 'text',
                minWidth: 150,
                maxWidth: 300,
                isRowHeader: true,
                isResizable: false,
                isSorted: false,
                isSortedDescending: false,
                data: 'string',
                isPadded: true
            }
        ]

        return (
            <div>
                <Stack tokens={stackTokens} horizontal>
                    <Stack.Item grow>
                        <DefaultButton iconProps={{ iconName: 'Back' }} text='Back' onClick={() => setShowCustomerDemands(false)} />
                    </Stack.Item>
                    <Stack.Item >
                        <IconButton iconProps={{ iconName: 'Cancel' }} style={{ flex: 'inherit', float: 'left' }} onClick={() => dismissModal()} />
                    </Stack.Item>
                </Stack>
                <Stack styles={demandsStackItemStyles} tokens={stackTokens} horizontal>
                    <Stack>

                        <Stack.Item>

                            <Label style={{ fontSize: 18 }} >
                                Customer demand
                            </Label>
                            <TextField value={selectedCustomerDemand?.text} disabled />
                            <Dropdown
                                label="Albot suggestions"
                                disabled={globalDemandsOptions === undefined || globalDemandsOptions.length === 0 ? true : false}
                                placeholder={globalDemandsOptions === undefined || globalDemandsOptions.length === 0 ? ' No suggestions available' : '- Click for Albot suggestions -'}
                                options={globalDemandsOptions || []}
                                onChange={(e, item) => handleDemandSelection(item)}
                            />
                            <Stack.Item style={{ marginTop: 10, width: 350 }}>
                                <Stack horizontal style={{ width: 425 }}>
                                    <SearchBox placeholder='Search for customer demand' onChange={(e, i) => { handleSearch(i) }} />
                                    {
                                        hasPermission(Actions.DemandsCreate) ?
                                            <Link onClick={() => setShowNewDemandModal(true)} style={{ marginLeft: 10 }}>
                                                + Add new
                                            </Link>
                                            :
                                            <></>
                                    }
                                    {
                                        showNewDemandModal === true ?
                                            <NewGlobalDemandModal
                                                dismissModal={() => setShowNewDemandModal(false)}
                                                show={showNewDemandModal}
                                                handleDismissModal={handleDismissNewDemand} />
                                            :
                                            <></>
                                    }
                                </Stack>
                            </Stack.Item>
                            {
                                selectedCustomerDemand &&
                                // The form to fill out when adding a new demand
                                <DemandForm
                                    handleActionUpdate={handleActionUpdate}
                                    handlePositionChange={handlePositionChange}
                                    handleCheckbox={handleCheckbox}
                                    removeExtra={removeExtra}
                                    availablePositions={availablePositions()}
                                    setClarification={setClarification}
                                    addDemand={addDemand}
                                    setInternalComment={setInternalComment}
                                    setPositionExtras={setPositionExtras}
                                    action={action}
                                    extras={extras!}
                                    positionExtras={positionExtras}
                                    currentPosition={currentPosition!}
                                    internalComment={internalComment}
                                    clarification={clarification}
                                    demandExist={demandExist}
                                    pheTypes={pheTypes}
                                />
                            }
                        </Stack.Item>
                    </Stack>
                    <Stack.Item>
                        {
                            searchTerm !== '' && (filteredOptions !== undefined && filteredOptions.length > 0) ?
                                <div style={{ maxHeight: '80vh', overflow: 'scroll' }}>
                                    <DetailsList
                                        columns={searchColumns}
                                        items={filteredOptions}
                                        compact
                                        selectionMode={SelectionMode.none}
                                        onActiveItemChanged={handleDemandSelection}
                                    />
                                </div>
                                :
                                <></>
                        }
                    </Stack.Item>
                </Stack>
            </div>
        )
    }