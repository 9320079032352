import {
  Dropdown,
  FontWeights,
  IButtonStyles,
  IDropdownOption,
  IIconProps,
  ISpinButtonStyleProps,
  ISpinButtonStyles,
  IStyleFunction,
  Label,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SpinButton,
  TextField,
  useTheme,
} from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import { IPheType } from '../../models/IPheType'
import { IPosition } from '../../models/IPosition'
import { ApiService } from '../../Services/ApiService'
import { PositionsList } from '../../pages/positions/PositionsList'
import './newpositions.scss'
import { getBusinessUnit } from '../../Services/Global'

export const NewPositions: React.FC<{
  positions: IPosition[] | undefined, handlePositionDismiss: any, apiService: ApiService, pheTypes: IPheType[]
}> = ({ positions, handlePositionDismiss, apiService, pheTypes }) => {

  const [positionName, setPositionName] = useState({ value: '', message: '' });
  const [pheTypeOptions, setPheTypeOptions] = useState<IDropdownOption[]>();
  const [positionAmount, setPositionAmount] = useState(0);
  const [selectedPheType, setSelectedPheType] = useState<IPheType>();
  const [currentPositions, setCurrentPositions] = useState<IPosition[]>([]);
  const [isError, setIsError] = useState({ isActive: false, message: '' });
  const [positionsToDelete, setPositionsToDelete] = useState<IPosition[]>([]);

  const theme = useTheme();

  useEffect(() => {
    const options: IDropdownOption[] = [];
    pheTypes.map((item: IPheType) => {
      if (getBusinessUnit() == 'GPHE') {
        options.push({ key: item.id, text: item.type, title: `Designation: ${item.designation}` });
      } else {
        options.push({ key: item.id, text: item.type });
      }
      return;
    })

    setPheTypeOptions(options);

  }, [positions, apiService, pheTypes])

  useEffect(() => {

    if (positions !== undefined) {
      setCurrentPositions(positions);
    }

  }, [positions])

  const handleChange = (e: any) => {
    let errorMessage = ''
    let newValue = e.currentTarget.value
    switch (e.currentTarget.name) {
      case 'positionName':
        errorMessage = newValue === '' ? 'Position name is required.' : ''
        setPositionName({ value: newValue, message: errorMessage })
        break
      default:
        break
    }
  }

  // ============> Styles
  const saveIcon: IIconProps = { iconName: 'CheckMark' }

  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
      minWidth: '400px',
    },
  })
  const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    }
  }

  const styles: IStyleFunction<ISpinButtonStyleProps, ISpinButtonStyles> = (props: ISpinButtonStyleProps) => {
    const { isFocused, theme } = props;
    return {
      spinButtonWrapper: [
        { width: 75, marginTop: 15 },
        // Example of conditional styling based on component state
        isFocused && {
          outline: '1px solid ' + theme.palette.accent,
        },
      ],
    };
  };

  // <============ Styles

  const addPosition = (e: any) => {

    const error: boolean = positionName.value === '' || positionAmount === 0;
    if (error) {
      setIsError({ isActive: true, message: 'Please fill out all required fields.' });
      return;
    }

    setIsError({ isActive: false, message: '' });

    let newPosition: IPosition = {
      name: positionName.value,
      numberOf: positionAmount,
      pheTypeId: selectedPheType?.id,
      type: 'Position',
      isReadOnly: false
    }

    if (validatePosition(currentPositions, newPosition) === false) {
      setIsError({ isActive: true, message: 'That item already exists in the list.' });
      return;
    }

    let currentArray = currentPositions.concat(newPosition);
    currentArray = [...currentPositions, newPosition];

    setCurrentPositions(currentArray);

    // reset form
    setPositionName({ value: '', message: '' });
  }

  const validatePosition = (positionList: IPosition[], incomingChange: IPosition) => {
    let target = positionList.find(x => x.pheTypeId === incomingChange.pheTypeId)
    if (target !== undefined) {
      if (incomingChange.name === target.name) {
        return false;
      }
    }

    return true;
  }

  const onChange = React.useCallback((event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
    if (newValue !== undefined) {
      setPositionAmount(parseInt(newValue));
    }
  }, []);

  const onDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>, index?: number | undefined) => {
    const p: IPheType | undefined = pheTypes?.find(x => x.id === option?.key);
    if (p !== undefined) {
      setSelectedPheType(p);
    }
  }

  const handleListRefresh = (position: IPosition) => {

    let currentArray = currentPositions;
    let index = currentArray.findIndex(x => x.pheTypeId === position.pheTypeId && x.name === position.name);

    let newPosition: IPosition = {
      projectId: position.projectId,
      id: position.id,
      name: position.name,
      numberOf: position.numberOf,
      pheTypeId: position.pheTypeId,
      type: position.type,
      isReadOnly: position.id !== undefined,
    }

    currentPositions.concat(newPosition);
    currentArray[index] = newPosition
    currentArray = [...currentPositions];

    setCurrentPositions(currentArray);
  }

  const handleRemovePosition = (position: IPosition) => {
    let currentArray = currentPositions;
    let index = currentArray.findIndex(x => x.pheTypeId === position.pheTypeId);

    if (positionsToDelete.findIndex(x => x.id === position.id) === -1) {
      setPositionsToDelete([...positionsToDelete, currentArray[index]]);
    }

    currentPositions.splice(index, 1);

    currentArray = [...currentPositions];

    setCurrentPositions(currentArray);
  }


  return (
    <div>
      <div className={contentStyles.header}>
        <span>Positions</span>
        <PrimaryButton
          styles={iconButtonStyles}
          iconProps={saveIcon}
          ariaLabel="Close popup modal"
          onClick={() => handlePositionDismiss(currentPositions, positionsToDelete)}
          text='Done'
        />
      </div>
      <div className="positions-modal">
        <Label required htmlFor="customerName">
          Tag no. / Position name
        </Label>
        <TextField
          id="positionName"
          name="positionName"
          errorMessage={positionName.message}
          onChange={handleChange}
          value={positionName.value}
        />
        <Dropdown
          placeholder="Select an option"
          label="PHE"
          options={pheTypeOptions === undefined ? [] : pheTypeOptions}
          onChange={onDropdownChange}
          required
        />
        <SpinButton
          styles={styles}
          defaultValue="0"
          label='Amount'
          min={0}
          max={100}
          step={1}
          incrementButtonAriaLabel="Increase value by 1"
          decrementButtonAriaLabel="Decrease value by 1"
          onChange={onChange}
        />
        <br />
        <PrimaryButton
          style={{ float: 'right' }}
          text="Add"
          onClick={addPosition}
        />
        <br />
        {
          currentPositions !== undefined ?
            <PositionsList positions={currentPositions} refreshList={handleListRefresh} removePosition={handleRemovePosition} />
            :
            <></>
        }
      </div>
      {
        isError.isActive === true ?
          <MessageBar
            messageBarType={MessageBarType.error}
            dismissButtonAriaLabel="Close"
            dismissIconProps={{ iconName: 'Clear', styles: { root: { color: `${theme.palette.white} !important` } } }}
            styles={{
              root: {
                background: theme.palette.red
              },
              icon: {
                color: theme.palette.white
              }
            }}
          >
            {isError.message}
          </MessageBar>
          :
          <></>
      }
    </div>
  )
}
