import React, { Component } from 'react';
import { System } from '../../../typings';
import { Selection } from './styled';

type Coords = { x: number; y: number };
type State = { start: Coords | null; end: Coords | null };

type Props = {
    onSelection: (startTarget: HTMLElement, boundingRect: System.ViewportRectangle, resetSelection: () => void) => void;
    onDragStart: () => void;
    onDragEnd: () => void;
    shouldStart: (event: MouseEvent) => boolean;
};

class AreaSelection extends Component<Props, State> {
    state: State = {
        start: null,
        end: null,
    };

    wrapperRef = React.createRef<HTMLDivElement>();

    reset = () => {
        const { onDragEnd } = this.props;

        if (this.state.start) {
            onDragEnd();
        }
        this.setState({ start: null, end: null });
    };

    getBoundingRect(start: Coords, end: Coords): System.ViewportRectangle {
        return {
            left: Math.min(end.x, start.x),
            top: Math.min(end.y, start.y),

            width: Math.abs(end.x - start.x),
            height: Math.abs(end.y - start.y),
        };
    }

    componentDidMount() {
        const { onSelection, onDragStart, onDragEnd, shouldStart } = this.props;

        const container = this.wrapperRef.current?.parentElement;
        let containerBoundingRect: ClientRect | undefined;

        const containerCoords = (pageX: number, pageY: number) => {
            if (!containerBoundingRect) {
                containerBoundingRect = container?.getBoundingClientRect();
            }

            return {
                x: pageX - containerBoundingRect!.left + container!.scrollLeft,
                y: pageY - containerBoundingRect!.top + container!.scrollTop,
            };
        };

        container?.addEventListener('mousemove', (event: MouseEvent) => {
            const { start } = this.state;

            if (!start) {
                return;
            }

            this.setState({ end: containerCoords(event.pageX, event.pageY) });
        });

        let onMouseUp: (event: MouseEvent) => void;

        container?.addEventListener('mousedown', (event: MouseEvent) => {
            if (!shouldStart(event)) {
                this.reset();
                return;
            }

            const startTarget = event.target;

            if (!(startTarget instanceof HTMLElement)) {
                return;
            }

            onDragStart();

            this.setState({
                start: containerCoords(event.pageX, event.pageY),
                end: null,
            });

            onMouseUp = (event: MouseEvent): void => {
                const { start } = this.state;

                if (!start) {
                    return;
                }

                const end = containerCoords(event.pageX, event.pageY);

                const boundingRect = this.getBoundingRect(start, end);

                if (
                    !(event.target instanceof HTMLElement) ||
                    !container.contains(event.target) ||
                    !this.shouldRender(boundingRect)
                ) {
                    this.reset();
                    return;
                }

                this.setState({ end }, () => {
                    const { start, end } = this.state;

                    if (!start || !end) {
                        return;
                    }

                    if (event.target instanceof HTMLElement) {
                        onSelection(startTarget, boundingRect, this.reset);

                        onDragEnd();
                    }
                });

                document.body.removeEventListener('mouseup', onMouseUp);
            };

            document.body.addEventListener('mouseup', onMouseUp);
        });
    }

    shouldRender(boundingRect: System.ViewportRectangle) {
        return boundingRect.width >= 1 && boundingRect.height >= 1;
    }

    render() {
        const { start, end } = this.state;

        return (
            <div ref={this.wrapperRef}>{start && end ? <Selection style={this.getBoundingRect(start, end)} /> : null}</div>
        );
    }
}

export default AreaSelection;
