import { IMsalContext, useMsal } from '@azure/msal-react';
import { getTheme, PrimaryButton, GroupedList, Icon, IGroup, IGroupHeaderProps, IRawStyle, mergeStyleSets, SelectionMode, Spinner, SpinnerSize, Stack, TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IContent } from '../../models/IContent';
import { IDocument } from '../../models/IDocument';
import { IClarification, IDocumentClarification } from '../../models/IDocumentClarification';
import { IGlobalDemand } from '../../models/IGlobalDemand';
import { IInteraction } from '../../models/IInteraction';
import { ILocalDemand } from '../../models/ILocalDemand';
import { IPheType } from '../../models/IPheType';
import { IPosition } from '../../models/IPosition';
import { ApiService } from '../../Services/ApiService';
import { DemandsPane } from '../../components/demands/DemandsPane';
import { TrainingPane } from '../../components/demands/TrainingPane';
import { ClarificationDetails } from './ClarificationDetails';
import { getTrainingMode, setProjectId } from '../../Services/Global';
import './Clarifications.scss';
import { Actions, hasPermission } from '../../Services/Permissions';

export type ClarificationItem = {
    documentId: string,
    documentClarification: IDocumentClarification,
    interactions: IInteraction[],

}

export const Clarifications: React.FC<{}> = () => {
    const ctx: IMsalContext = useMsal()
    const apiService = new ApiService(ctx)

    const [clarifications, setClarifications] = useState<IDocumentClarification[]>();
    const [groups, setGroups] = useState<IGroup[]>();

    const [currentClarification, setCurrentClarification] = useState<IClarification>();
    const [currentInteractions, setCurrentInteractions] = useState<IInteraction[]>();
    const [showInteractions, setShowInteractions] = useState(false);
    const [showDemandsPane, setShowDemandsPane] = useState(false);
    const [currentDocumentClarification, setCurrentDocumentClarification] = useState<IDocumentClarification>();
    const [currentDocument, setCurrentDocument] = useState<IDocument>();
    const [globalDemands, setGlobalDemands] = useState<IGlobalDemand[]>();
    const [currentInteraction, setCurrentInteraction] = useState<IInteraction>();
    const [currentMatches, setCurrentMatches] = useState<Record<string, IContent>>();
    const [pheTypes, setPheTypes] = useState<IPheType[]>();
    const [currentPositions, setCurrentPositions] = useState<IPosition[]>()
    const [currentInteractionsByDoc, setCurrentInteractionsByDoc] = useState<Record<string, IInteraction[]>>();
    const [existingLocalDemands, setExistingLocalDemands] = useState<ILocalDemand[]>();
    const [selectedInteraction, setSelectedInteraction] = useState<IInteraction>();

    const [isLoading, setIsLoading] = useState(true);
    const [demandIsLoading, setDemandIsLoading] = useState(false);

    const params = useParams();
    const projectId = params.id === undefined ? '' : params.id;
    if (projectId) {
        setProjectId(projectId);
    }

    async function getAllClarificationData() {
        setIsLoading(true);
        try {
            const clarificationResponse = await apiService.get(`projects/${projectId}/clarifications`);

            const clarifications: IDocumentClarification[] = await clarificationResponse.json();

            let grps: IGroup[] = []
            const interactionResponse = await apiService.get(`projects/${projectId}/interactions`);

            const interactions: IInteraction[] = await interactionResponse.json();
            clarifications.forEach((c) => {
                c.clarifications.forEach((cl) => {
                    cl.insideInteractionIds.forEach((iid) => {
                        if (!c.interactionIds.includes(iid)) {
                            c.interactionIds.push(iid);
                        }
                    })
                })
            });

            clarifications.forEach((c) => {
                c.interactionIds.forEach((id) => {
                    const target = interactions.find(x => x.id === id);
                    if (target !== undefined) {
                        if (c.interactions === undefined) {
                            c.interactions = [];
                        }
                        c.interactions.push(target);
                    }
                })
            });


            clarifications.forEach((cl) => {
                grps.push({
                    count: cl.clarifications.length,
                    key: cl.documentId,
                    name: cl.documentName,
                    startIndex: 0,
                    data: {
                        documentClarification: cl,
                        pheType: cl.pheType
                    }
                })
            });

            const demandsResponse = await apiService.get(`projects/${projectId}/demands`);
            const demands: ILocalDemand[] = await demandsResponse.json();

            setExistingLocalDemands(demands);
            setGroups(grps);
            setClarifications(clarifications);
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        getAllClarificationData();
    }, [])

    const theme = getTheme();
    const headerAndFooterStyles: IRawStyle = {
        minHeight: 40,
        lineHeight: 40,
        paddingLeft: 16
    };
    const truncate: IRawStyle = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block',
        maxWidth: '20vw',
    };
    const classNames = mergeStyleSets({
        header: [headerAndFooterStyles, theme.fonts.large],
        headerTruncate: [headerAndFooterStyles, theme.fonts.large, truncate],
        trunc: truncate,
        footer: [headerAndFooterStyles, theme.fonts.mediumPlus],
        footerTruncate: [headerAndFooterStyles, theme.fonts.mediumPlus, truncate],
        row: [headerAndFooterStyles, theme.fonts.medium],
        name: {
            display: 'inline-block',
            overflow: 'hidden',
            height: 24,
            cursor: 'default',
            padding: 8,
            boxSizing: 'border-box',
            verticalAlign: 'top',
            background: 'none',
            backgroundColor: 'transparent',
            border: 'none',
            paddingLeft: 32,
        },
    });

    function handleShowClarificationsPane(x: IClarification, d?: IDocumentClarification, selectedInt?: IInteraction): void {
        let targetedClarifications = d?.clarifications.filter(y => y.demandName === x.demandName);
        clarifications?.forEach(cl => {
            cl.clarifications.forEach(clar => {
                if (clar.demandName === x.demandName) {
                    targetedClarifications?.push(clar);
                }
            })
        })
        let relevantInteractionIds: string[] = []

        targetedClarifications?.forEach(c => {
            c.insideInteractionIds.forEach((s) => {
                relevantInteractionIds.push(s);
            })
        })

        let relevantInteractions: Record<string, IInteraction[]> = {}

        clarifications?.forEach((cl) => {
            cl?.interactions?.forEach((di) => {
                if (relevantInteractionIds.includes(di.id!)) {
                    if (relevantInteractions[cl.documentName as string] === undefined) {
                        relevantInteractions[cl.documentName as string] = [];
                    }

                    if (!relevantInteractions[cl.documentName as string].includes(di)) {
                        relevantInteractions[cl.documentName as string].push(di)
                    }
                }
            });
        })

        let ints: IInteraction[] = [];
        for (const [key, value] of Object.entries(relevantInteractions)) {
            ints = [...ints, ...value];
        }

        setCurrentInteractions(ints);
        setSelectedInteraction(selectedInt)
        setShowDemandsPane(false);
        setShowInteractions(true);
        setCurrentClarification(x);
        setCurrentInteractionsByDoc(relevantInteractions);
        setCurrentDocumentClarification(d);
    }

    async function downloadTrainingData() {
        await apiService.downloadFile(`Projects/${projectId}/trainingData`)
    }

    const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element => {
        if (props) {
            const toggleCollapse = (): void => {
                props.onToggleCollapse!(props.group!);
            };

            return (
                <div>
                    <div className={`${classNames.header} clarification-header`} style={{ boxShadow: theme.effects.elevation16, height: 50, marginTop: 0 }} onClick={toggleCollapse} >
                        <Stack horizontal styles={{ root: { maxHeight: 50 } }}>
                            <Stack.Item grow={2} className={classNames.headerTruncate} align='start' style={{ maxWidth: 250 }}>
                                {props.group?.name}
                            </Stack.Item>
                            <Stack.Item grow={2} className={classNames.header}>
                                {props.group?.data.documentClarification.documentCategory}
                            </Stack.Item>
                            <Stack.Item grow className={classNames.header} style={{ minWidth: 250, flex: 'inherit', textAlign: 'right', paddingRight: 10 }}>
                                {props.group?.data.pheType !== null ? `${props.group?.data.pheType.type}` : ''}
                            </Stack.Item>
                        </Stack>
                        {
                            props.group?.isCollapsed !== true ?
                                <Stack horizontal>
                                    <Stack.Item grow={2} className={classNames.footer} align='start' style={{ width: 250, maxWidth: 250 }}>
                                        Adjusted Marking(s)
                                    </Stack.Item>
                                    <Stack.Item grow={2} className={classNames.footerTruncate}>
                                        Customer demand
                                    </Stack.Item>
                                    <Stack.Item grow={1} style={{ paddingRight: 20, textAlign: 'right', }} className={classNames.footer}>
                                        Comments
                                    </Stack.Item>
                                </Stack>
                                :
                                <></>
                        }
                    </div >
                    <br />
                    <br />
                    {
                        !props.group?.isCollapsed &&
                        props.group?.data.documentClarification.interactions.map((interaction: IInteraction, index: number) => {
                            const interactionDemand = props.group?.data.documentClarification as IDocumentClarification;
                            const currentDemand = interactionDemand.clarifications.find(x => x.insideInteractionIds.findIndex(y => y === interaction.id) !== -1)!;

                            return (
                                <>
                                    <Stack horizontal key={`${currentDemand?.demandId}-${interaction.id}-${index}`} className='clarification-row' onClick={() => handleShowClarificationsPane(currentDemand, interactionDemand, interaction)}>
                                        <Stack.Item key={`${currentDemand?.demandId}-${interaction.id}-${index}`} className={classNames.row} align='start'>
                                            <TextField
                                                multiline
                                                style={{ maxHeight: 50, width: 250, maxWidth: 250 }}
                                                value={interaction.meta.rephrasing}
                                                readOnly
                                                resizable={false}
                                            />
                                        </Stack.Item>
                                        <Stack.Item grow={2} className={classNames.row}>
                                            {currentDemand?.demandName}
                                        </Stack.Item>
                                        <Stack.Item grow={1} style={{ paddingRight: 20, float: 'right', flex: 'inherit' }} className={classNames.row}>
                                            {interaction?.comment !== '' ? <Icon iconName='Message' /> : <></>}
                                        </Stack.Item>
                                    </Stack>
                                    <hr className='clarification-row-separator' />
                                </>
                            )
                        })
                    }
                </div>
            );
        }

        return <p></p>
    };

    const renderRow = (nestingDepth?: number | undefined, item?: IDocumentClarification, index?: number | undefined, group?: IGroup) => {
        return (
            <>
            </>
        )
    }

    async function getGlobalDemandsAsync() {
        const globalDemandResponse = await apiService.get(`globaldemands`);
        const gDemands: IGlobalDemand[] = await globalDemandResponse.json();
        setGlobalDemands(gDemands);
    }

    const handleShowDemandsPane = async (demandsId: string, intAction: IInteraction) => {
        setDemandIsLoading(true);
        setShowDemandsPane(true);
        setShowInteractions(false);
        try {
            intAction.highlight.from = 'interaction';
            intAction.highlight.interactionId = intAction.id!;
            setCurrentInteraction(intAction);
            const documentResponse = await apiService.get(`projects/${projectId}/documents/${intAction.documentId}`);
            const document: IDocument = await documentResponse.json();
            setCurrentDocument(document);

            await getGlobalDemandsAsync();

            const matchesResponse = await apiService.get(`Projects/${projectId}/documents/${document.id}/json`);
            const matchesResult = await matchesResponse.json();

            const matches: Record<string, IContent> = matchesResult.contentDomain.byId;
            setCurrentMatches(matches);

            const pheTypesResponse = await apiService.get(`phetypes`);

            if (pheTypes === undefined) {
                const pheTypesResult: IPheType[] = await pheTypesResponse.json();
                setPheTypes(pheTypesResult);
            }

            const positionsResponse = await apiService.get(`projects/${projectId}/positions`);
            const positionsResult: IPosition[] = await positionsResponse.json();

            if (currentDocumentClarification?.documentCategory === 'Position') {
                const singlePosition: IPosition = positionsResult.find(x => x.id === document?.positionId)!
                setCurrentPositions([singlePosition]);
            }
            else {
                setCurrentPositions(positionsResult);
            }
        } finally {
            setDemandIsLoading(false);
        }
    }

    return (
        <div>
            {
                !hasPermission(Actions.ClarificationView) ?
                    <> Unauthorized access</>
                    : clarifications !== undefined && isLoading === false ?
                        <>
                            <Stack horizontal style={{ float: 'right', padding: '20px' }}>
                                {getTrainingMode() && hasPermission(Actions.Training) &&
                                    <PrimaryButton text='Export' onClick={(e) => downloadTrainingData()} />
                                }
                            </Stack>
                            <Stack horizontal >
                                <Stack.Item grow={1} style={{ maxWidth: '55vw' }}>
                                    <Stack horizontal>
                                        <Stack.Item grow={2} className={classNames.headerTruncate} style={{ marginLeft: 40, maxWidth: 210 }}>
                                            Document name
                                        </Stack.Item>
                                        <Stack.Item grow={2} style={{ marginLeft: 30 }} className={classNames.headerTruncate}>
                                            Document type
                                        </Stack.Item>
                                        <Stack.Item grow={1} style={{ minWidth: 250, flex: 'inherit', textAlign: 'right', paddingRight: 45 }} className={classNames.header}>
                                            Position
                                        </Stack.Item>
                                    </Stack>
                                    <GroupedList
                                        styles={{ root: { maxHeight: '85vh', height: 'auto', overflow: 'scroll', padding: 20 } }}
                                        items={clarifications}
                                        groups={groups}
                                        groupProps={{
                                            showEmptyGroups: true,
                                            onRenderHeader: onRenderHeader
                                        }}
                                        selectionMode={SelectionMode.none}
                                        onRenderCell={renderRow}
                                        className='hide-rows'
                                    />
                                </Stack.Item>
                                <Stack.Item grow={1} style={{ width: '45vw' }}>
                                    {
                                        showInteractions === true ?

                                            <ClarificationDetails
                                                clarification={currentClarification}
                                                interactions={currentInteractions}
                                                currentDocument={currentDocumentClarification}
                                                hide={() => setShowInteractions(false)}
                                                showDemandsPane={handleShowDemandsPane}
                                                interactionsByDoc={currentInteractionsByDoc}
                                                selectedInteraction={selectedInteraction!} />

                                            :
                                            <></>
                                    }
                                    {
                                        !getTrainingMode() && showDemandsPane === true && currentDocument !== undefined && globalDemands !== undefined && currentMatches !== undefined && currentPositions !== undefined && demandIsLoading === false ?

                                            <DemandsPane
                                                document={currentDocument!}
                                                dismissModal={() => setShowDemandsPane(false)}
                                                globalDemands={globalDemands!}
                                                highlight={currentInteraction!.highlight}
                                                matches={currentMatches!}
                                                pheTypes={pheTypes!}
                                                positions={currentPositions}
                                                projectId={projectId}
                                                refreshInteractions={() => { getAllClarificationData() }}
                                                onceSelectedAlwaysTrue={false}
                                                toggleSelected={() => { }}
                                            />

                                            :
                                            demandIsLoading && !getTrainingMode() ?
                                                <Stack.Item grow={1}>
                                                    <Spinner style={{ marginLeft: 50 }} label='Loading..' />
                                                </Stack.Item>
                                                :
                                                <></>
                                    }
                                    {
                                        getTrainingMode() && showDemandsPane === true && currentDocument !== undefined && globalDemands !== undefined && currentMatches !== undefined && demandIsLoading === false ?

                                            <TrainingPane
                                                document={currentDocument!}
                                                dismissModal={() => setShowDemandsPane(false)}
                                                globalDemands={globalDemands!}
                                                highlight={currentInteraction!.highlight}
                                                matches={currentMatches!}
                                                projectId={projectId}
                                                refreshInteractions={() => { getAllClarificationData() }}
                                                onceSelectedAlwaysTrue={false}
                                                handleDismissNewDemand={() => getGlobalDemandsAsync()}
                                            />

                                            :
                                            demandIsLoading && getTrainingMode() ?
                                                <Stack.Item grow={1}>
                                                    <Spinner style={{ marginLeft: 50 }} label='Loading..' />
                                                </Stack.Item>
                                                :
                                                <></>
                                    }
                                </Stack.Item>
                            </Stack>

                        </>
                        :
                        <Spinner style={{ padding: 20 }} label='Loading clarifications...' size={SpinnerSize.large} />
            }
        </div >
    )
}