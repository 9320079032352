import React from 'react';
import ReactDOM from 'react-dom';
import { PDFSinglePageViewer } from 'pdfjs-dist/web/pdf_viewer';
import { PreviewWrapper } from './documenthelpers/DocumentWrapper';
import { highlightHelpers } from './documenthelpers/helpers';
import TextHighlight from './documenthelpers/TextHighlight';
import AreaHighlight from './documenthelpers/AreaHighlight';
import { Domain } from '../../typings';

import './pdf_viewer.css';

type OwnProps = Domain.PdfPreviewModal['props'];
type Props = OwnProps;

class ConnectedPdfPreview extends React.Component<Props> {
    viewer: PDFSinglePageViewer | null = null;
    wrapperRef?: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount() {
        const wrapper = this.wrapperRef!.current;
        this.viewer = new PDFSinglePageViewer({
            container: wrapper!,
            textLayerMode: 1,
        });

        this.viewer.setDocument(this.props.document);

        wrapper!.addEventListener('pagesinit', () => {
            this.viewer!.currentPageNumber = this.props.pageNumber;
        });
        wrapper!.addEventListener('textlayerrendered', () => this.renderHighlights());
    }

    render() {
        return (
            <div>
                <PreviewWrapper ref={this.wrapperRef} >
                    <div className="pdfViewer" />
                </PreviewWrapper>
            </div>
        );
    }

    renderHighlights() {
        const { highlights, pageNumber, interactionId } = this.props;
        const highlightLayer = highlightHelpers.findOrCreateHighlightLayer(this.viewer!, pageNumber);
        if (highlightLayer) {
            ReactDOM.render(
                <div>
                    {highlights.map((highlight: any) => {
                        const { id, type, from, position, pageNumber } = highlight;

                        const highlightProps = {
                            from: id === interactionId ? 'selection' : from,
                            position: highlightHelpers.scaledPositionToViewport(this.viewer!, position, this.props.pageNumber),
                            key: id,
                            onClick: () => { },
                        };

                        return type === 'text' ? <TextHighlight {...highlightProps} /> : <AreaHighlight {...highlightProps} />;
                    })}
                </div>,
                highlightLayer,
            );
        }
    }
}

export default ConnectedPdfPreview;