import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { RoutePathDefinition } from '../../Routing/RoutePathDefinition';
import { ApiService } from '../../Services/ApiService';
import { getBusinessUnit, getDocumentName, getProjectName } from '../../Services/Global';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { mapDefinitionToActivePath } from '../../Routing/mapDefinitionToActivePath';

export interface BreadcrumbsProps {
    routes: RoutePathDefinition[];
}

export const AppBreadcrumbs: React.FC<BreadcrumbsProps> = ({ routes }) => {
    const ctx: IMsalContext = useMsal()
    const [breadcrumbItem, setBreadcrumbItem] = useState<IBreadcrumbItem[]>([])

    const businessUnit = getBusinessUnit();
    const businessUnitSelected = businessUnit !== 'None'

    const apiService = new ApiService(ctx)

    const location = useLocation();

    useEffect(() => {
        if(businessUnit !== 'None'){
            refreshData();
        }
    }, [location])

    function refreshData() {
        loadData();
    }

    async function loadData() {

        const activeRoutePaths = mapDefinitionToActivePath(routes, location.pathname);

        const items: IBreadcrumbItem[] = [];
        await activeRoutePaths.reduce(async (prev, active, index) => {
            // wait for prev operation to get completed for Sequentially process activeRoutePaths of asynchronous tasks.
            await prev;

            if (index === 0 && active.title?.toLowerCase() !== "projects" && active.title?.toLowerCase() !== "admin") {
                items.push({ text: "Projects", key: index + "_", href: "/" });
            }
            let path = active.match.pathname;
            let title = active.title;
            let key = index + "";

            if (active.title?.toLowerCase() !== "document list") {

                if (active.title?.toLowerCase() === "project") {
                    const projectId = active.match.params.id ?? "";
                    let projName = await projectName(projectId);
                    path = path + "/documents";
                    title = projName;
                } else if (active.title?.toLowerCase() === "document") {
                    const documentId = active.match.params.docId ?? "";
                    const projId = active.match.params.id ?? "";
                    let docName = await documentName(projId, documentId);
                    title = active.title + " : " + docName;
                }

                let isCurrentItem = false;
                if (index === activeRoutePaths.length - 1) {
                    path = "";
                    isCurrentItem = true;
                }

                items.push({ text: title, key: key, href: path, isCurrentItem: isCurrentItem });
            }

        }, Promise.resolve());

        setBreadcrumbItem(items);
    }

    async function projectName(projectId: string) {
        let projectName = getProjectName();
        if (!!projectName) {
            return projectName;
        }
        const response = await apiService.get(`projects/${projectId}`)
        const data = await response.json()
        return data.name;

    }
    async function documentName(projectId: string, documentId: string) {
        let documentName = getDocumentName();
        if (!!documentName) {
            return documentName;
        }
        const response = await apiService.get(`Projects/${projectId}/documents/${documentId}`);
        const data = await response.json()
        return data.name;
    }

    return (
        <>
            {
                businessUnitSelected &&
                <Breadcrumb items={breadcrumbItem} />
            }
        </>
    );
}


