import { IMsalContext } from "@azure/msal-react";
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from "../AuthConfig";
import { setRoles } from "./Global";
import jwt_decode from "jwt-decode";

export class AuthenticationService {

    private context: IMsalContext;

    constructor(ctx: IMsalContext) {
        this.context = ctx;
    }

    public getAccessToken(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            const { instance, accounts } = this.context;
            const account = accounts[0]
            const scopes = loginRequest.scopes
            let response;
            try {
                response = await instance.acquireTokenSilent({
                    account: account!,
                    scopes: scopes
                });

                if (response.accessToken) {
                    var token: any = jwt_decode(response.accessToken);
                    setRoles(token.roles)
                    resolve(response.accessToken);
                } else {
                    reject(Error('Failed to acquire an access token'));
                }
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        scopes: scopes
                    });
                }
                else {
                    reject(error)
                }
            }
        });
    }

    public loginRedirect() {
        const { instance } = this.context;
        const request = {
            scopes: loginRequest.scopes
        };
        instance.loginRedirect(request).catch((error) => {
            console.log(error)
        });
    }
}