import { Component } from 'react';
import { Domain, System } from '../../../typings';
import { TextHighlightPart } from './styled';

type Props = {
    position: System.ViewportPosition;
    onClick?: () => void;
    onContextMenu?: () => void;
    from: Domain.HighlightSource;
};

const defaultState = { hovered: false };

type State = typeof defaultState;

class TextHighlight extends Component<Props, State> {
    state: State = defaultState;

    // TODO: This has to be solved some other way portals/ReactDOM?
    setHovered = () => false && this.setState({ hovered: true });
    clearHovered = () => false && this.setState({ hovered: false });

    render() {
        const { position, onClick, onContextMenu, from } = this.props;
        const { rects } = position;

        return (
            <div onClick={onClick} onMouseEnter={this.setHovered} onContextMenu={onContextMenu} onMouseLeave={this.clearHovered}>
                {rects.map((rect, index) => (
                    <TextHighlightPart from={from} key={index} style={rect} />
                ))}
            </div>
        );
    }
}

export default TextHighlight;
