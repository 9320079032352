import React, { useEffect, useState } from 'react';
import './App.scss';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { MessageBar, MessageBarType, createTheme, loadTheme } from '@fluentui/react';
import { AuthenticationService } from './Services/AuthenticationService';
import { initializeIcons } from '@fluentui/font-icons-mdl2';

import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import { RoutesRenderer } from './Routing/RoutesRenderer';
import { routes } from './Routing/Routes';
import { AppBreadcrumbs } from './components/breadcrumbs/AppBreadcrumbs';


const AppContext = React.createContext(null);

function App() {
  const myTheme = createTheme({
    palette: {
      themePrimary: '#007fc8',
      themeLighterAlt: '#f3f9fd',
      themeLighter: '#cfe8f6',
      themeLight: '#a7d4ee',
      themeTertiary: '#59addd',
      themeSecondary: '#198bce',
      themeDarkAlt: '#0071b3',
      themeDark: '#006097',
      themeDarker: '#00476f',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#f3f2f1',
      neutralLight: '#e3e3e3',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      red: '#E84745',
      redDark: '#b33534',
      black: '#000000',
      white: '#ffffff',
      green: '#27AE60',
      greenDark: '#1d8549',
      orangeLight: '#DB9276',
      yellow: '#FFCA42',
      blueMid: '#40B4E5',
      yellowLight: '#FDCC61',
      blue: '#11387F',
    },
  });
  loadTheme(myTheme);

  const ctx: IMsalContext = useMsal();
  const [errorData, setErrorData] = React.useState<string>('');
  const [signedIn, setSignedIn] = useState(false);

  initializeIcons();
  const authService: AuthenticationService = new AuthenticationService(ctx);

  window.onunhandledrejection = function (e) {
    console.log(e.reason);
    setErrorData("Oops something went wrong: " + e.reason);
    // setTimeout(
    //   () => {
    //     dismissErrorHandler()
    //   }, 7500);
  }
  const dismissErrorHandler = () => {
    setErrorData('');
  }

  useEffect(() => {
    async function signIn() {
      await authService.getAccessToken();
      setSignedIn(true);
    }

    signIn();
  }, []);

  return (
    <div className="App">
      <Router>
        <AppContext.Provider value={null}>
          {
            signedIn &&
            <>
              <Navbar />

              <div className='albot-body'>
                {errorData &&
                  <div style={{ marginTop: 15 }}>
                    <MessageBar
                      messageBarType={MessageBarType.error}
                      dismissButtonAriaLabel="Close"
                      onDismiss={dismissErrorHandler}
                      dismissIconProps={{ iconName: 'Clear', styles: { root: { color: `${myTheme.palette.white} !important` } } }}
                      styles={{
                        root: {
                          background: myTheme.palette.red,
                        },
                        icon: {
                          color: myTheme.palette.white
                        }
                      }}
                    >
                      {errorData}
                    </MessageBar>
                  </div>
                }
                <AppBreadcrumbs routes={routes} />
                <RoutesRenderer routes={routes} />

              </div>
            </>
          }

        </AppContext.Provider>
      </Router>
    </div>
  );
}

export default App;
