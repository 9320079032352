import * as R from 'ramda';
import TextHighlight from './documenthelpers/TextHighlight';
import { PDFViewer } from 'pdfjs-dist/web/pdf_viewer';
import { PdfDocument } from 'pdfjs-dist/webpack';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import AreaHighlight from './documenthelpers/AreaHighlight';
import AreaSelection from './documenthelpers/AreaSelection';
import { HighlightWrapper } from './documenthelpers/styled';
import { textHelpers, highlightHelpers } from './documenthelpers/helpers';
import { Actions, hasPermission } from '../../Services/Permissions';

//////////////// Order is important! ///////////////////////////
import 'pdfjs-dist/web/pdf_viewer.css'; // 1
import './pdf_viewer.css'; // 2
import { IContent } from '../../models/IContent';
import { System } from '../../typings';
////////////////////////////////////////////////////////////////

type Props = {
    pdfDocument: PdfDocument;
    currentPage: number;
    startHighlight: (userSelection: System.Highlight) => void;
    updatePosition: (page: number) => void;
    currentSelection?: System.Highlight;
    highlightsByPage?: Record<number, System.Highlight[]>;
    selectHighlight: (highlight: System.Highlight) => void;
    isRephrasingActitve: boolean;
    albotSuggestionsByPage: Record<number, IContent[]>;
};

class PdfHighlighter extends Component<Props> {
    viewer: PDFViewer = null!;
    wrapperRef?: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount() {
        const { pdfDocument, updatePosition } = this.props;
        const wrapperNode = this.wrapperRef?.current;

        this.viewer = new PDFViewer({
            container: wrapperNode!,
            textLayerMode: 1,
        });

        this.viewer.setDocument(pdfDocument);

        //window.PdfViewer = this;

        wrapperNode!.addEventListener('pagesinit', this.onDocumentReady);
        // TODO: Could be improved by only rendering highlights on the loaded textlayer
        wrapperNode!.addEventListener('textlayerrendered', () => this.renderHighlights());
        wrapperNode!.addEventListener('pagechange', (e: any) => updatePosition(e.pageNumber));
    }

    shouldComponentUpdate(nextProps: Props) {
        if (
            this.props.highlightsByPage !== nextProps.highlightsByPage ||
            this.props.currentSelection !== nextProps.currentSelection
        ) {
            this.renderHighlights(nextProps);
        }

        if (this.props.currentPage !== nextProps.currentPage && this.viewer.currentPageNumber !== nextProps.currentPage) {
            this.scrollToPage(nextProps.currentPage);
        }

        if (this.props.isRephrasingActitve !== nextProps.isRephrasingActitve) {
            this.disableTextSelection(nextProps.isRephrasingActitve);
        }

        return false;
    }

    onDocumentReady = () => {
        this.viewer.currentScaleValue = 'auto';
        this.viewer.scrollPageIntoView({ pageNumber: this.props.currentPage || 1 });
    };

    scrollToPage = (pageNumber: number) => this.viewer && this.viewer.scrollPageIntoView({ pageNumber });

    disableTextSelection(disable: boolean) {
        this.viewer.viewer.classList.toggle('PdfHighlighter--disable-selection', disable);
    }

    handleTextSelection = () => {
        if (!hasPermission(Actions.DocumentTextSelection)) {
            return
        }

        const selection: Selection = window.getSelection()!;
        const range = selection.rangeCount && selection.getRangeAt(0);
        const textHighlight = highlightHelpers.getHighlightFromRange(this.viewer, range as Range);

        if (textHighlight) {
            this.props.startHighlight({ ...textHighlight, from: 'selection' });
        }

        selection.removeAllRanges();
    };

    handleAreaSelection = (
        startTarget: HTMLElement,
        boundingRect: System.ViewportRectangle,
        resetSelection: () => void,
    ) => {

        const areaHighlight = highlightHelpers.getHighlightFromAreaSelection(this.viewer, startTarget, boundingRect);

        if (areaHighlight) {
            this.props.startHighlight({ ...areaHighlight, from: 'selection' });
        }

        resetSelection();
    };

    getHighlight(id: string, query: string, text: string, divs: HTMLDivElement[], divTexts: string[], originalText?: string): System.Highlight | null {
        const startIndex = text.indexOf(query);

        if (startIndex === -1) {
            // TODO: Consider reporting to firestore/stackdriver
            return null;
        }

        const endIndex = startIndex + query.length - 1;

        let currentIndex = 0;
        let startNode: Node | null = null;
        let startOffset: number;
        let endNode: Node;
        let endOffset: number;

        for (let i = 0; i < divTexts.length; i++) {
            const divTextLength = divTexts[i].length;
            if (!startNode && currentIndex + divTextLength > startIndex) {
                startNode = divs[i].childNodes[0];
                const nodeText = startNode.textContent!;

                if (!nodeText.includes('.')) {
                    startOffset = startIndex - currentIndex;
                }
                else {
                    startOffset = nodeText.lastIndexOf('.') + 1;
                }

                while (nodeText[startOffset] === ' ') {
                    startOffset++;
                }

                if (query.length <= divTextLength) {
                    endNode = divs[i].childNodes[0];
                    endOffset = endNode.textContent!.length;
                    break;
                }
            }
            if (currentIndex + divTextLength >= endIndex) {
                endNode = divs[i].childNodes[0];

                if (endNode.textContent!.includes('.')) {

                    endOffset = endNode.textContent!.indexOf('.') + 1;
                    break;
                }


                // old code -> always taking the full next row to highlight. That will not work all the time, therefore the if-clause is added above
                endOffset = endNode.textContent!.length;
                break;
            }
            currentIndex += divTextLength;
        }
        //TODO: If everything on a page is marked for some reason
        if (!startNode || !endNode!) return null;


        const range = document.createRange();

        range.setStart(startNode, startOffset!);
        range.setEnd(endNode, endOffset!);

        const highlight = highlightHelpers.getHighlightFromRange(this.viewer, range, originalText);

        return (
            highlight! && {
                ...highlight!,
                id,
                from: 'suggestion',
            }
        );
    }

    getSuggestionHighlights(suggestionsByPage: Record<number, IContent[]>, pageNumber: number) {
        const textLayer = this.viewer.getPageView(pageNumber - 1).textLayer;
        const divs = Array.from(textLayer.textLayerDiv.childNodes).filter(
            (child) => child instanceof HTMLDivElement && child.classList.length === 0,
        ) as HTMLDivElement[];

        if (!divs.length) {
            return [];
        }

        const divTexts = divs.map((div) => textHelpers.removeWhitespace(div.textContent!));
        const allText = Object.values(divTexts).reduce((acc, str) => acc + str, '');

        if (suggestionsByPage !== undefined) {
            if (suggestionsByPage[pageNumber] !== undefined) {
                const suggsByPage = suggestionsByPage[pageNumber]
                    .map(({ id, text: searchText }) => {
                        return this.getHighlight(id, textHelpers.removeWhitespace(searchText), allText, divs, divTexts, searchText)
                    }
                    )
                    .filter((highlight) => highlight);

                return suggsByPage;
            }
        }

        return undefined;
    }

    renderHighlights(props: Props = this.props) {
        const { highlightsByPage, currentSelection, pdfDocument, albotSuggestionsByPage } = props;
        for (let page = 1; page <= pdfDocument.numPages; page++) {
            const highlightLayer = highlightHelpers.findOrCreateHighlightLayer(this.viewer, page);
            if (highlightLayer) {
                const selectionHighlight: (System.Highlight | undefined)[] = R.whereEq(
                    { from: 'selection', pageNumber: page },
                    currentSelection || ({} as any),
                )
                    ? [currentSelection]
                    : [];
                const suggestionHighlights = this.getSuggestionHighlights(albotSuggestionsByPage, page);
                let interactionHighlights: any[] = [];
                if (highlightsByPage !== undefined) {

                    interactionHighlights = highlightsByPage[page];
                }

                ReactDOM.render(
                    <div>
                        {[...selectionHighlight || [], ...interactionHighlights || [], ...suggestionHighlights || []].map((highlight) => {
                            highlight = highlight as System.Highlight;
                            const { id, type, from, position, pageNumber } = highlight;

                            const highlightProps = {
                                position: highlightHelpers.scaledPositionToViewport(this.viewer, position, pageNumber),
                                from: id === R.prop('id', props.currentSelection!) ? 'selection' : from,
                                key: id,
                                onClick: () => { this.props.selectHighlight(highlight) }
                            };

                            return type === 'text' ? <TextHighlight {...highlightProps} /> : <AreaHighlight {...highlightProps} />;
                        })}
                    </div>,
                    highlightLayer,
                );
            }
        }
    }

    render() {
        return (

            <HighlightWrapper
                ref={this.wrapperRef}
                onContextMenu={(e) => e.preventDefault()}
                onMouseUp={this.handleTextSelection}
            >
                <div className="pdfViewer" />
                <AreaSelection
                    onDragStart={() => this.disableTextSelection(true)}
                    onDragEnd={() => this.disableTextSelection(false)}
                    shouldStart={(event) =>
                        !this.props.currentSelection &&
                        event.button === 2 &&
                        event.target instanceof HTMLElement &&
                        !!event.target.closest('.page')
                    }
                    onSelection={this.handleAreaSelection}
                />
            </HighlightWrapper>
        );
    }
}

export default PdfHighlighter;
