import * as R from 'ramda';
import { IExtraPer } from '../models/Enums';
import { IExtra } from '../models/IExtra';
import { IPheType } from '../models/IPheType';
import { IPosition } from '../models/IPosition';
import { getBusinessUnit } from '../Services/Global';

const unitDependent: IExtraPer[] = ['pcs', 'unit'];
const alwaysOne: IExtraPer[] = ['pos', 'order', 'item'];

const userDependent: string[] = [
    'added in blocks of 102',
    'added in blocks of 55 EUR',
    'added in blocks of 92 eur',
    'boss',
    'conn',
    'lug',
    'copy',
    'document',
    'manual',
    'Paint spec',
];

const orderTypes: string[] = [
    'added in blocks of 102',
    'added in blocks of 55 EUR',
    'added in blocks of 92 eur',
    'copy',
    'document',
    'manual',
    'order',
    'Paint spec',
];

const positionTypes: IExtraPer[] = ['boss', 'conn', 'lug', 'item', 'pcs', 'pos', 'unit'];

const isOrderExtra = (extra: IExtra) => orderTypes.includes(extra.per);

function getNumberOf(position: IPosition, per: IExtraPer) {
    if (R.includes(per, alwaysOne)) {
        return 1;
    }

    if (R.includes(per, userDependent)) {
        return 0;
    }

    if (R.includes(per, unitDependent)) {
        return position.type === 'Order' ? 1 : position.numberOf;
    }

    return position.numberOf;
}

const isApplicableForOrder = (extra: IExtra) => isOrderExtra(extra);

const isApplicableForPosition = (extra: IExtra, pheType: IPheType) => {
    if (getBusinessUnit() === 'GPHE') {
        return !isOrderExtra(extra) && (R.isEmpty(extra.designation) || extra.designation.includes(pheType.designation));
    } else {
        return !isOrderExtra(extra) && (R.isEmpty(extra.heTypes) || extra.heTypes.includes(pheType.type));
    }
}

export default {
    positionTypes,
    orderTypes,
    unitDependent,
    getNumberOf,
    isApplicableForOrder,
    isApplicableForPosition,
};
