
import { mergeStyles, Checkbox, IStackTokens, Stack, useTheme, TooltipHost, ICheckboxStyles } from '@fluentui/react';
import Logo from '../../assets/png/logo.png';
import { useMsal } from '@azure/msal-react'
import { NavLink } from 'react-router-dom';
import './navbar.scss';
import { getBusinessUnit, getProjectId, getTrainingMode, setBusinessUnit, setTrainingMode } from '../../Services/Global';
import React, { useEffect, useState } from 'react';
import { Actions, hasPermission, getBusinessUnitAccess } from '../../Services/Permissions';
import { Dropdown } from 'office-ui-fabric-react';
import { useId } from '@fluentui/react-hooks';
import { useNavigate } from 'react-router-dom';
import { BusinessUnit } from '../../models/Enums';


export const Navbar: React.FC<{}> = () => {
    const { instance } = useMsal();
    const [businessUnit, setCurrentBusinessUnit] = useState<BusinessUnit>('None')
    const [didLoad, setDidLoad] = useState(false);

    let theme = useTheme();
    let navigation = useNavigate();

    const layerStyles = mergeStyles([
        {
            backgroundColor: theme.palette.blue
        }
    ]);

    const stackTokens: IStackTokens = { childrenGap: 5 };

    const handleLogout = () => {
        instance.logoutPopup().catch((e: any) => {
            console.error(e);
        });
    }

    const handleBusinessUnitUpdate = (event: any, item: any) => {
        setBusinessUnit(item.key);
        navigation('/');
        window.location.reload();
    }

    const handleTrainingModeUpdate = (event: any, item: boolean | undefined) => {
        setTrainingMode(item ?? false);
        navigation('/');
        window.location.reload();
    }

    useEffect(() => {
        const currentBusinessUnit = getBusinessUnit();
        if (currentBusinessUnit !== 'None') {
            setCurrentBusinessUnit(currentBusinessUnit)
        }

        setDidLoad(true);
    }, [])

    const tooltipId = useId('tooltip');

    return (
        <div className="albot-navbar">
            {/* <Layer> */}
            <div className={layerStyles}>
                <Stack tokens={stackTokens}>
                    <Stack horizontal>
                        <Stack horizontal grow disableShrink>
                            <Stack.Item style={{ width: 100 }}>
                                <img style={{ display: 'block', position: 'absolute', height: 35, paddingLeft: 50, marginTop: 10 }} src={Logo} alt='Albot-logo' />
                            </Stack.Item>

                            {
                                process.env.REACT_APP_DEPLOYED_ENV?.includes('Prod') === false ?
                                    <p style={{ color: 'white', fontSize: '0.8em' }}>({process.env.REACT_APP_DEPLOYED_ENV})</p>
                                    :
                                    <></>
                            }

                            {
                                businessUnit !== 'None' ?
                                    <Stack.Item style={{ marginLeft: 50 }} align="center">
                                        <NavLink className={({ isActive }) => (isActive ? 'active-link albot-navLink' : 'inactive-link albot-navLink')} to={'/Overview'}>
                                            <span>Overview</span>
                                        </NavLink>
                                    </Stack.Item>
                                    :
                                    <></>
                            }
                            {!!getProjectId() ?
                                <React.Fragment>
                                    <Stack.Item align="center">
                                        <NavLink className={({ isActive }) => (isActive ? 'active-link albot-navLink' : 'inactive-link albot-navLink')} to={`/projects/${getProjectId()}/documents`} replace={true} >
                                            <span>Project</span>
                                        </NavLink>
                                    </Stack.Item>
                                    {hasPermission(Actions.ClarificationView) &&
                                        <Stack.Item align="center">
                                            <NavLink className={({ isActive }) => (isActive ? 'active-link albot-navLink' : 'inactive-link albot-navLink')} to={`/projects/${getProjectId()}/clarifications`} replace={true} >
                                                <span>Clarifications</span>
                                            </NavLink>
                                        </Stack.Item>
                                    }
                                    {!getTrainingMode() &&
                                        <Stack.Item align="center">
                                            <NavLink className={({ isActive }) => (isActive ? 'active-link albot-navLink' : 'inactive-link albot-navLink')} to={`/projects/${getProjectId()}/summary`} replace={true} >
                                                <span>Summary</span>
                                            </NavLink>
                                        </Stack.Item>
                                    }
                                </React.Fragment>
                                : null}
                            {
                                (hasPermission(Actions.AdminView) && businessUnit !== 'None') &&
                                <Stack.Item align="center">
                                    <NavLink className={({ isActive }) => (isActive ? 'active-link albot-navLink' : 'inactive-link albot-navLink')} to={`/Admin`} replace={true} >
                                        <span>Admin</span>
                                    </NavLink>
                                </Stack.Item>
                            }

                        </Stack>

                        <Stack grow disableShrink horizontal horizontalAlign='end' style={{ marginRight: 50 }}>
                            {hasPermission(Actions.Training) &&
                                <Stack.Item align='center' style={{ color: '#FFFFFF', fontSize: '1em', fontWeight: '400', marginRight: 20 }}>
                                    <Checkbox styles={{
                                        text: {
                                            color: `${theme.palette.white} !important`
                                        },
                                        checkbox: {
                                            borderColor: `${theme.palette.white} !important`,
                                        },
                                        checkmark: {
                                            color: `${theme.palette.white} !important`
                                        }
                                    }}
                                        defaultChecked={getTrainingMode()}
                                        label='Training'
                                        onChange={(e, c) => handleTrainingModeUpdate(e, c)} />
                                </Stack.Item>
                            }
                            {
                                businessUnit !== 'None' && getBusinessUnitAccess().length > 1 &&
                                <Stack.Item style={{ color: '#FFFFFF', fontSize: '1em', fontWeight: '400', marginRight: 20 }}>
                                    <TooltipHost content='Select your business unit'>
                                        {
                                            didLoad &&
                                            <Dropdown aria-describedby={tooltipId} onChange={handleBusinessUnitUpdate}
                                                options={[{ key: 'GPHE', text: 'GPHE' }, { key: 'WHE', text: 'WHE' }]}
                                                style={{ width: 120, marginTop: 15 }}
                                                placeholder={'Business Unit'}
                                                defaultSelectedKey={businessUnit as string}
                                            />
                                        }
                                    </TooltipHost>
                                </Stack.Item>
                            }
                            {
                                businessUnit !== 'None' && getBusinessUnitAccess().length === 1 &&
                                <Stack.Item align='center' style={{ color: '#FFFFFF', fontSize: '1em', fontWeight: '400', marginRight: 20 }}>
                                    <span>{businessUnit}</span>
                                </Stack.Item>
                            }
                            <Stack.Item align='center' className='albot-navLink'>
                                <span onClick={handleLogout} style={{ float: 'right', color: '#FFFFFF', fontSize: '16px', fontWeight: '600' }}>Log out</span>
                            </Stack.Item>
                        </Stack>

                    </Stack>
                </Stack>

            </div>
            {/* </Layer > */}
        </div >
    );
}

export default Navbar;