import * as R from 'ramda';
import React from 'react';
import { PageInput, ToolbarWrapper } from './helpers/ToolbarWrapper';

type Props = {
    currentPage: number;
    totalPages: number;
    updateDocumentPosition: (page: number) => void;
};

class PdfToolbar extends React.Component<Props> {
    handlePageInput = (e: React.ChangeEvent<HTMLInputElement>) => this.setPage(parseInt(e.currentTarget.value, 10));

    setPage = (page: number) => {
        const { totalPages: numPages, updateDocumentPosition, currentPage: documentPosition } = this.props;
        const pageInRange = R.clamp(1, numPages, page);

        if (pageInRange !== documentPosition) {
            updateDocumentPosition(pageInRange);
        }
    };

    render() {
        const { currentPage, totalPages } = this.props;
        return (
            <ToolbarWrapper>
                <PageInput>
                    <input pattern="\d*" onChange={this.handlePageInput} value={currentPage || ''} />
                    <div>{totalPages || '-'}</div>
                </PageInput>
            </ToolbarWrapper>
        );
    }
}
export default PdfToolbar;
