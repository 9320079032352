import styled from 'styled-components';
import { color, size, spacing } from './styles';

export const ToolbarWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: ${size.qnaBannerHeight}px;
  padding: ${spacing.medium};
  color: ${color.white};
`;

export const PageInput = styled.div`
  border: 2px solid ${color.accentGrayDark};
  border-radius: 2px;
  display: flex;
  & > * {
    font-size: 14px;
    font-weight: 400;
    padding: ${spacing.small};
    outline: none;
    text-align: center;
    width: ${spacing.xlarge};
    border: none;
    box-sizing: border-box;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${color.accentGrayDark};
    color: ${color.text};
  }
`;
